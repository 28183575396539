import { sendPostRequest } from '../../utils/network';
import PageError from '../../utils/pageErrorHandler';


// Function to ping server for Stream Explorer
export default async function fetchSuiteDataByTime(customerId, startTime, endTime) {
    // Early check for necessary parameters
    if (!customerId || !startTime || !endTime) {
        throw new PageError('All parameters (customerId, startTime, endTime) must be provided.', { statusText: 'Missing Parameters' });
    }

    let resultData;
    try {
        resultData = await sendPostRequest('/fetchSuiteDataByTime', {
        customerId,
        startTime,
        endTime,
        });
    } catch (error) {
        // Error handling specific to the network request or server issues
        if (error.response && error.response.status === 404) {
        throw new PageError('No suites found within the specified time range.', { statusText: 'Suites Not Found' });
        } else {
        throw new Error("Sorry, something went wrong. Please contact support if you need help.");
        }
    }
    const suiteData = resultData?.data;

    if (suiteData && suiteData.length) {
        return suiteData;
    } else {
        // Handle case where data is an empty array
        throw new PageError('No suites found within the specified time range.', { statusText: 'Suites Not Found' });
    }
}
