import { BugCard } from './cardTypes/BugCard';
import { InvestigationCard } from './cardTypes/InvestigationCard';
import { MessageCard } from './cardTypes/MessageCard';
import { GenericTaskCard } from './cardTypes/GenericTaskCard';
import { PromotionTaskCard } from './cardTypes/PromotionTaskCard';
import ToggleButton from './ToggleButton';
// import { useEffect, useState } from 'react';

/**
 * Renders an investigation column with various types of cards.
 * @param {object} props - The component props.
 * @param {string} props.title - The title of the column.
 * @param {import('../../types').QAWTask[]} props.tasks - The triage cards.
 * @param {Function} props.toggleClaimed - The function to toggle claimed status.
 * @param {{id: string, email: string}} props.user - The user object.
 * @param {Function} props.suiteAction - The suite action function.
 * @param {Function} props.setShowNotesModal - The function to set show notes modal.
 * @param {Function} props.setNotesModalData - The function to set notes modal data.
 * @param {Function} [props.setShowNewTaskModal] - The function to set show new task modal.
 * @param {Function} [props.setNewTaskModalData] - The function to set new task modal data.
 * @param {boolean} [props.showUserTasksOnly] - Whether to show only the user's tasks.
 * @param {Function} [props.setShowUserTasksOnly] - The function to set show user tasks only.
 * @param {boolean} props.showMessageTasks - Whether to show Message tasks.
 * @param {Function} props.setShowMessageTasks - The function to set show Message tasks.
 * @param {boolean} props.showGenericTasks - Whether to show generic tasks.
 * @param {Function} props.setShowGenericTasks - The function to set show generic tasks.
 * @param {boolean} props.showTriageTasks - Whether to show triage tasks.
 * @param {Function} props.setShowTriageTasks - The function to set show triage tasks.
 * @param {Function} props.setEditingNotes - The function to set editing notes.
 * @param {boolean} props.hasUserClaimedMaxTasks - Whether the user has claimed the max number of tasks allowed.
 * @param {boolean} props.showPromotionTasks - Whether to show Promotion tasks.
 * @param {Function} props.setShowPromotionTasks - The function to set show Promotion tasks.
 * @param {boolean} props.primaryTeamOnly - Whether to show Primary team tasks only
 * @param {Function} props.setPrimaryTeamOnly - The function to set Primary team tasks only
 * @returns {import('react').JSX.Element} The rendered component.
 */
export function InvestigationColumn({
  title,
  tasks,
  toggleClaimed,
  user,
  suiteAction,
  setShowNotesModal,
  setNotesModalData,
  setShowNewTaskModal,
  setNewTaskModalData,
  setEditingNotes,
  showUserTasksOnly,
  showMessageTasks,
  setShowMessageTasks,
  setShowUserTasksOnly,
  showGenericTasks,
  setShowGenericTasks,
  showTriageTasks,
  setShowTriageTasks,
  hasUserClaimedMaxTasks,
  showPromotionTasks,
  setShowPromotionTasks,
  primaryTeamOnly,
  setPrimaryTeamOnly,
}) {
  // const [visibleTasks , setVisibleTasks] = useState(tasks);

  /**
   * Returns the appropriate card component based on the task type and subtype.
   * @param {import('../../types').QAWTask} task - The task object.
   * @returns {import('react').JSX.Element} - The card component.
   */
  function getCardComponent(task) {
    if (task.type === 'generic') {
      if (task.subType === 'message') {
        return (
          <MessageCard
            task={task}
            toggleClaimed={toggleClaimed}
            user={user}
            suiteAction={suiteAction}
            setShowNewTaskModal={setShowNewTaskModal}
            setNewTaskModalData={setNewTaskModalData}
          />
        );
      } else if (task.subType === 'bugVerification') {
        return <BugCard task={task} toggleClaimed={toggleClaimed} user={user} suiteAction={suiteAction} />;
      } else {
        return <GenericTaskCard task={task} toggleClaimed={toggleClaimed} user={user} suiteAction={suiteAction} />;
      }
    } else if (task.type === 'triage') {
      return (
        <InvestigationCard
          task={task}
          toggleClaimed={toggleClaimed}
          userEmail={user.email}
          suiteAction={suiteAction}
          setShowNotesModal={setShowNotesModal}
          setNotesModalData={setNotesModalData}
          setEditingNotes={setEditingNotes}
          hasUserClaimedMaxTasks={hasUserClaimedMaxTasks}
        />
      );
    } else if (task.type === 'promotion') {
      return <PromotionTaskCard task={task} toggleClaimed={toggleClaimed} user={user} suiteAction={suiteAction} />;
    } else {
      return <GenericTaskCard task={task} toggleClaimed={toggleClaimed} user={user} suiteAction={suiteAction} />;
    }
  }



  return (
    <section aria-labelledby="section-1-title">
      <div className="flex justify-between items-center gap-x-4 bg-gray-50 p-6 rounded-t-lg shadow">
      <div className="text-sm font-medium leading-6 text-gray-900">{title}</div>
      {title === 'In Progress' && (
        <ToggleButton labelText="Show My Tasks Only" enabled={showUserTasksOnly} setEnabled={setShowUserTasksOnly} />
      )}
      {title === 'To Do' && (
        <div className="flex flex-wrap justify-start items-start space-x-4"> {/* Adjusted for responsive wrapping and spacing */}
          <ToggleButton labelText="Triage" enabled={showTriageTasks} setEnabled={setShowTriageTasks} />
          <ToggleButton labelText="Promotions" enabled={showPromotionTasks} setEnabled={setShowPromotionTasks} />
          <ToggleButton labelText="Messages" enabled={showMessageTasks} setEnabled={setShowMessageTasks} />
          <ToggleButton labelText="Generic" enabled={showGenericTasks} setEnabled={setShowGenericTasks} />
          <ToggleButton labelText="Main Team" enabled={primaryTeamOnly} setEnabled={setPrimaryTeamOnly} />
        </div>
      )}
      </div>
      <div className="rounded-b-lg bg-white shadow pb-6">
        {tasks.length === 0 && (
          <div className="px-6 pt-4">
            <p className="text-gray-500">No active items in this column.</p>
          </div>
        )}

        {tasks.map((task) => (
          <div key={task.id} className="px-2 pt-2">
            {getCardComponent(task)}
          </div>
        ))}
      </div>
    </section>
  );
}
