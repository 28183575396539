import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../utils/network';
import { EnvelopeIcon, MagnifyingGlassCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Toast from './Toast';
import { useEffect, useState } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function TeamSelector({
  enabledTeams,
  setEnabledTeams,
  refetchQuery = () => {},
  teamTriageSummaries = null,
  activeShift = false,
  setToast = null,
}) {
  const [warnedAboutShift, setWarnedAboutShift] = useState(false);

  useEffect(() => {
    // if there are teams active in local storage but not in the state, update the state
    const localActiveTeams = JSON.parse(localStorage.getItem('teams')).filter((team) => team.active);

    if (!enabledTeams.length && localActiveTeams.length) setEnabledTeams(localActiveTeams);
  }, [enabledTeams, setEnabledTeams]);

  const {
    data: teams,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['qaTeams'],
    queryFn: async () => (await sendGetRequest(`/get-teams`)).data,
    placeholderData: [],
    select: (data) => {
      const teamsString = localStorage.getItem('teams');
      const currTeams = teamsString ? JSON.parse(teamsString) : [];

      // save teams
      if (!data.length) return;

      const formattedTeams = data?.map((team) => {
        const currTeam = currTeams.find((t) => t.qawId === team.lead.qawId);

        return {
          id: team.id,
          name: team.lead.name,
          qawId: team.lead.qawId,
          email: team.lead.email,
          teamName: team.name,
          packId: team.pack.lead.teamId,
          imageUrl: team.imageUrl,
          active: currTeam ? currTeam.active : true,
          selectedInSettings: currTeam ? currTeam.selectedInSettings : false,
        };
      });
      if (formattedTeams) {
        localStorage.setItem('teams', JSON.stringify(formattedTeams));
      }
      return formattedTeams;
    },
  });

  if (isPending) return <div>Loading...</div>;
  if (isError) return <span>Error: {error.message}</span>;

  function toggleTeam(name) {
    let newLeadsList = [...teams];
    let teamSelected = newLeadsList.find((x) => x.name === name);
    if (teamSelected) {
      teamSelected.active = !teamSelected.active;
      localStorage.setItem('teams', JSON.stringify(newLeadsList));

      setEnabledTeams(teams.filter((team) => team.active));
      refetchQuery();

      if (activeShift && !warnedAboutShift) {
        setToast(
          <Toast
            title={'FYI 👀'}
            message={'If you want shift alerts for your new team selection please end and restart your shift.'}
            key={new Date().toISOString()}
            isSuccess={true}
          />,
        );
        setWarnedAboutShift(true);
      }
    }
  }

  return (
    <div className={classNames(!teamTriageSummaries && 'p-2 -mt-4')}>
      <div className={classNames('overflow-hidden rounded-lg bg-gray-50 py-2', !teamTriageSummaries && 'px-4 shadow')}>
        <div className="flex justify-start w-full flex-wrap">
          {teams.map((team) => {
            return (
              <div key={team.teamName} className="mt-1 font-semibold tracking-tight text-gray-900 pt-2 mr-2">
                <button
                  className={classNames('rounded-full p-2', team.active ? 'bg-green-600/40 text-green-400' : 'bg-gray-600/40 text-gray-400')}
                  type="button"
                  onClick={() => toggleTeam(team.name)}
                >
                  <img
                    className={classNames('h-20 w-20 rounded-full', team.active ? '' : 'grayscale')}
                    src={team.imageUrl}
                    alt={`${team.teamName} Team - ${team.name}`}
                    title={`${team.teamName} Team - ${team.name}`}
                  />
                </button>
                {teamTriageSummaries && (
                  <div className="flex justify-center text-sm -ml-1 mt-1">
                    <div className="flex mr-1">
                      <XMarkIcon className="w-5 h-5 text-red-500" />
                      {teamTriageSummaries[team.email] ? teamTriageSummaries[team.email].failureCount : 0}
                    </div>
                    <div className="flex mr-1">
                      <MagnifyingGlassCircleIcon className="h-5 w-5" />{' '}
                      {teamTriageSummaries[team.email] ? teamTriageSummaries[team.email].suiteCount : 0}
                    </div>
                    <div className="flex">
                      <EnvelopeIcon className="h-5 w-5" /> {teamTriageSummaries[team.email] ? teamTriageSummaries[team.email].messages || 0 : 0}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
