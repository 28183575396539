import { Card, List } from '@mui/material';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { plural } from '../../../InvestigationBoard/helpers';
import { CardItem } from './subComponents';
dayjs.extend(LocalizedFormat);

export function DataCard({ milestone, requiredVelocity }) {
  const totalTestsCompleted = milestone.subMilestones.at(-1).actualTests;
  const remainingTests = milestone.targetTestCount - totalTestsCompleted;
  const activatedStr = `${totalTestsCompleted} Activated`;
  const remainingStr = remainingTests > 0 ? `, ${remainingTests} Remaining` : ` ✅`;
  return (
    <Card variant="elevation" sx={{ mb: '2em', mt: '1em' }}>
      <List>
        <CardItem name="Name" value={milestone.name} />
        <CardItem name="Start Date" value={dayjs(milestone.startDate).format('LL')} />
        <CardItem name="External Due Date" value={dayjs(milestone.externalDueDate).format('LL')} />
        <CardItem name="Target Active Test Count" value={milestone.targetTestCount} />
        <CardItem name="Current Progress" value={activatedStr + remainingStr} />
        <CardItem name="Planned Velocity" value={`${milestone.testVelocity} Test${plural(milestone.plannedVelocity)} per week`} />
        <CardItem name="Required Velocity" value={`${Math.max(requiredVelocity, 0)} Test${plural(requiredVelocity)} per week`} />
      </List>
    </Card>
  );
}
