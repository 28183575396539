import CardFormBase from './CardFormBase';

/**
 *
 * @param {object} props
 * @param {import('../../MetricsExplorer/types').QAEFromLocalStorage[]} props.qaes
 * @returns {import('react').JSX.Element}
 */
export default function QAECardForm({ qaes }) {
  return <CardFormBase items={qaes} label="qaes" inputLabel="QAEs" switchLabel="Include Individual QAEs" itemName="qaes" subSelectName="QAEs" />;
}
