import { useState, useEffect } from 'react';
import { Select, MenuItem, Avatar, FormControl, InputLabel, Typography, Grid, Card, CardHeader, CardContent, Switch, FormControlLabel } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import WolfLoader from '../WolfLoader/WolfLoader';
import { fetchMetricsForCustomers, getLeadsManagersCsmsCustomers } from './BookOfBusinessQueries';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import { stringToColor, hslStringToRgbString } from '../../utils/colorUtils';
import { BarChartLegendBookOfBusiness } from './BookOfBusinessSubComponents/BarChartLegendBookOfBusiness';
import { NavigationCardBookOfBusiness } from './BookOfBusinessSubComponents/NavigationCardBookOfBusiness';


export default function BookOfBusiness() {
  const [selectedUser, setSelectedUser] = useState('');
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [groupByTrigger, setGroupByTrigger] = useState(false); // Add state for toggle switch
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleCustomers, setVisibleCustomers] = useState(new Set()); // State for selected customers in legend

  const getFourWeekRange = () => {
    const today = dayjs();
    const startDate = today.subtract(4, 'week').format('YYYY-MM-DD');
    const endDate = today.format('YYYY-MM-DD');
    return { startDate, endDate };
  };

  const { startDate, endDate } = getFourWeekRange();

  const filterCustomersByUser = (user) => {
    if (!user || !customers.length) return [];
    return customers.filter((customer) => {
      const { csm, qaLead, qaManager } = customer;
      return csm?.id === user.qawId || qaLead?.id === user.qawId || qaManager?.id === user.qawId;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLeadsManagersCsmsCustomers();
        const sortedUsers = data.users.sort((a, b) => a.name.localeCompare(b.name));
        setUsers(sortedUsers);
        setCustomers(Object.values(data.customers));

        const localStorageUserId = localStorage.getItem('userId');
        const matchedUser = sortedUsers.find((user) => user.qawId === localStorageUserId);
        setSelectedUser(matchedUser ? matchedUser : sortedUsers[0]);

        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      const filteredCustomers = filterCustomersByUser(selectedUser);
      setSelectedCustomers(filteredCustomers);
      setVisibleCustomers(new Set(filteredCustomers.map((c) => c.name))); // Show all customers initially
    }
  }, [selectedUser, customers]);

  const handleUserChange = (event) => {
    const userId = event.target.value;
    const selectedUser = users.find((user) => user.id === userId);
    setSelectedUser(selectedUser);
  };

  const handleToggleChange = (event) => {
    setGroupByTrigger(event.target.checked);
  };

  // Handle legend click (toggle customer visibility)
  const handleLegendClick = (customerName) => {
    const updatedVisibleCustomers = new Set(visibleCustomers);
    if (updatedVisibleCustomers.has(customerName)) {
      updatedVisibleCustomers.delete(customerName);
    } else {
      updatedVisibleCustomers.add(customerName);
    }
    setVisibleCustomers(updatedVisibleCustomers);
  };

  // Handle select all/deselect all in the legend
  const handleToggleAll = (selectAll) => {
    if (selectAll) {
      setVisibleCustomers(new Set(selectedCustomers.map((c) => c.name)));
    } else {
      setVisibleCustomers(new Set());
    }
  };

  // Use Query to fetch investigation data for selected customers
  const {
    data: metricsData,
    refetch, // We will use refetch to manually refetch data on switch toggle
    isPending,
    isError: suiteDataError,
    error: suiteDataErrorMsg,
  } = useQuery({
    queryKey: ['investigationDataBySelectClients', selectedCustomers.map((customer) => customer.id), startDate, endDate, groupByTrigger],
    queryFn: () => fetchMetricsForCustomers(selectedCustomers.map((customer) => customer.id), startDate, endDate, groupByTrigger), // Pass the groupByTrigger flag
    enabled: selectedCustomers.length > 0,
  });

  // Trigger refetch when the toggle switch is changed
  useEffect(() => {
    if (selectedCustomers.length > 0) {
      refetch(); // Refetch data when groupByTrigger is toggled
    }
  }, [groupByTrigger, selectedCustomers, refetch]);

  if (isLoading || isPending) return <WolfLoader />;
  if (error || suiteDataError) return <Typography color="error">Error: {error || suiteDataErrorMsg.message}</Typography>;

  // Filter metrics data by visible customers
  const filteredMetricsData = metricsData.filter((m) => visibleCustomers.has(m.customerName));

  // Calculate budgetPercent from selected customers
  const calculateBudgetPercent = (customerName) => {
    const customer = selectedCustomers.find((c) => c.name === customerName);
    return (customer?.metrics?.tests?.active) / (customer?.metrics?.tests?.expected) * 100 || 0;
  };

  // Bar chart creation logic with color customization based on client name
  const createBarChart = (metric, yAxisTitle, useBudgetPercent = false) => {
    const sortedMetrics = [...filteredMetricsData].sort((a, b) => {
      if (useBudgetPercent) {
        return calculateBudgetPercent(a.customerTrigger) - calculateBudgetPercent(b.customerTrigger);
      }
      return a[metric] - b[metric];
    });

    return (
      <Plot
        data={[
          {
            x: sortedMetrics.map((m) => m.customerTrigger), // Show customerTrigger name (e.g., "CustomerName - TriggerName")
            y: sortedMetrics.map((m) => (useBudgetPercent ? calculateBudgetPercent(m.customerTrigger) : m[metric])),
            type: 'bar',
            marker: {
              color: sortedMetrics.map((m) => hslStringToRgbString(stringToColor(m.customerName))), // Use customer name to generate color
            },
            name: '', // Remove "Trace 0"
            hovertemplate: `%{x}<br>%{y:.2f}`, // Custom hover template to remove parentheses and "Trace 0"
          },
        ]}
        layout={{
          title: `${yAxisTitle} by ${groupByTrigger ? 'Customer and Trigger' : 'Customer'}`, // Dynamic title
          xaxis: { title: '', automargin: true, tickangle: -45 }, // Rotate labels for better readability
          yaxis: { title: yAxisTitle, fixedrange: true }, // Prevent zooming
          autosize: true,
          margin: { t: 30, b: 120, l: 40, r: 40 }, // Add more margin to the bottom for longer names
        }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }} // Ensure charts fill the card
        config={{ displayModeBar: false }} // Hide the mode bar to keep the UI clean
      />
    );
  };

  // List of visible charts
  const visibleCharts = ['Run Time (Minutes)', 'Total Time (Minutes)', 'Triage Time (Minutes)', 'Flake Percent'];
  if (!groupByTrigger) visibleCharts.push('Budget Percent');

  return (
    <Grid container spacing={3} sx={{ padding: 3 }}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="user-select-label">Select User</InputLabel>
          <Select labelId="user-select-label" value={selectedUser.
          // @ts-ignore
          id || ''} onChange={handleUserChange} displayEmpty>
            {users?.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                <Avatar src={user.avatar48} alt={user.name} sx={{ marginRight: 1 }} />
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch checked={groupByTrigger} onChange={handleToggleChange} />}
          label="Group by Customer and Trigger"
        />
      </Grid>

      <Grid item xs={3}>
        {/* Navigation Card */}
        <NavigationCardBookOfBusiness visibleCharts={visibleCharts} />
      </Grid>

      <Grid item xs={9}>
        {/* Legend Component */}
        <BarChartLegendBookOfBusiness customerNames={selectedCustomers.map((c) => c.name)} selectedCustomers={visibleCustomers} onLegendClick={handleLegendClick} onToggleAll={handleToggleAll} />
      </Grid>

      {visibleCharts.map((title, index) => (
        <Grid item xs={12} key={title} id={title.replace(/\s+/g, '-').toLowerCase()}>
          <Card raised sx={{ display: 'flex', flexDirection: 'column', minHeight: 500 }}>
            <CardHeader title={`${title} by ${groupByTrigger ? 'Customer and Trigger' : 'Customer'}`} sx={{ bgcolor: '#2f2fc1', color: 'common.white' }} /> {/* Dynamic title */}
            <CardContent sx={{ flexGrow: 1 }}>
              {index === 0 && createBarChart('medianRunTime', 'Median Run Time (Minutes)')}
              {index === 0 && createBarChart('meanRunTime', 'Mean Run Time (Minutes)')}
              {index === 1 && createBarChart('medianTotalTime', 'Median Total Time (Minutes)')}
              {index === 1 && createBarChart('meanTotalTime', 'Mean Total Time (Minutes)')}
              {index === 2 && createBarChart('medianTriageTime', 'Median Triage Time (Minutes)')}
              {index === 2 && createBarChart('meanTriageTime', 'Mean Triage Time (Minutes)')}
              {index === 3 && createBarChart('medianFlakePercent', 'Median Flake Percent')}
              {index === 3 && createBarChart('meanFlakePercent', 'Mean Flake Percent')}
              {index === 4 && createBarChart('budgetPercent', 'Budget Percent', true)}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
