import { useState } from 'react';
import { TextField, MenuItem, FormControl, Select, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { sendGetRequest, sendPostRequest } from '../../utils/network';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import WolfLoader from '../WolfLoader/WolfLoader';


export default function CustomerToTeams() {
    const [searchTerm, setSearchTerm] = useState('');
    const queryClient = useQueryClient();

    const fetchCustomers = async () => {
        try {
            const resultData = await sendGetRequest('/client-teams/get-clients');
            let data = resultData?.data;
            const sortedCustomers = data.sort((a, b) => a.officialName.localeCompare(b.officialName));
            return sortedCustomers;
        } catch (error) {
            console.error('Failed to fetch customers', error);
        }
    };

    const fetchTeams = async () => {
        try {
            const resultData = await sendGetRequest('/client-teams/get-teams'); // Make sure this endpoint fetches all active teams
            let teams = resultData?.data;
            return teams;
        } catch (error) {
            console.error('Failed to fetch teams', error);
        }
    };

    const { data: customers, isLoading: isLoadingCustomers, isError: isErrorCustomers, error: errorCustomers } = useQuery({
        queryKey: ['customers'],
        queryFn: fetchCustomers,
    });

    const { data: teams, isLoading: isLoadingTeams, isError: isErrorTeams } = useQuery({
        queryKey: ['teams'],
        queryFn: fetchTeams,
    });

    if (isLoadingCustomers || isLoadingTeams) return <WolfLoader />;
    if (isErrorCustomers || isErrorTeams) return <span>Error: {errorCustomers?.message || 'Failed to load data'}</span>;

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
    };

    const handleUpdateTeam = async (customerId, teamType, teamId) => {
        try {
            const teamUpdate = { customerId, [teamType]: teamId };
            await sendPostRequest(`/client-teams/update-${teamType}`, teamUpdate);

            // Update the query data in React Query's cache
            queryClient.setQueryData(['customers'], (oldData) => {
                // @ts-ignore
                return oldData.map((customer) => 
                    customer.id === customerId ? { ...customer, [teamType]: teamId } : customer,
                );
            });
        } catch (error) {
            console.error('Failed to update team', error);
        }
    };

    const filteredCustomers = customers?.filter((customer) => 
        customer.officialName.toLowerCase().includes(searchTerm),
    );

    return (
        <div className="container mx-auto px-4">
            <TextField
                fullWidth
                label="Search Customers"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                className="bg-white mb-4"
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Primary Team</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>First After Hours Team</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Second After Hours Team</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredCustomers?.map((customer) => (
                            <TableRow key={customer.id}>
                                <TableCell component="th" scope="row">{customer.officialName}</TableCell>
                                <TableCell>
                                    <div className="flex items-center">
                                        {customer.qaTeam && (
                                            <>
                                                <img src={customer.qaTeam.imageUrl} alt={customer.qaTeam.name} style={{ width: 35, height: 35, marginRight: 10 }} />
                                                <span className="text-lg">{customer.qaTeam.name}</span>
                                            </>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <TeamDropdown teams={teams} currentTeamId={customer.firstAfterHoursQaTeamId}
                                        handleChange={(teamId) => handleUpdateTeam(customer.id, 'firstAfterHoursQaTeamId', teamId)} />
                                </TableCell>
                                <TableCell>
                                    <TeamDropdown teams={teams} currentTeamId={customer.secondAfterHoursQaTeamId}
                                        handleChange={(teamId) => handleUpdateTeam(customer.id, 'secondAfterHoursQaTeamId', teamId)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function TeamDropdown({ teams, currentTeamId, handleChange }) {
    const [searchTerm, setSearchTerm] = useState('');
    
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredTeams = teams?.filter((team) => 
        team.name.toLowerCase().includes(searchTerm),
    ).sort((a, b) => a.name.localeCompare(b.name));

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel id="team-select-label">Team</InputLabel>
            <Select
                labelId="team-select-label"
                value={currentTeamId || ''}
                onChange={(e) => handleChange(e.target.value === '' ? null : e.target.value)}
                label="Team"
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 400,
                            width: 300,
                        },
                    },
                }}
            >
                <Box px={2} py={1}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Search Teams"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Box>
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {filteredTeams?.map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                        <img src={team.imageUrl} alt={team.name} style={{ width: 24, height: 24, marginRight: 10 }} />
                        {team.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
