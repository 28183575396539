import { TeamImpactView, TeamMagicView, TeamMessagesView, TeamCreationView, TeamInvestigationView, TeamTimeTrackingView } from './views';

/**
 *
 * @param {object} props
 * @param {{type: string, current: boolean}} props.activeTab
 * @param {import('./types').TeamMetricsFilter} props.filters
 * @param {import('react').Dispatch<import('react').SetStateAction<{team: import('./types').TeamMetricsFilter}>>} props.setFilters
 * @returns {import('react').JSX.Element}
 */
export default function MetricsExplorerBoard({ activeTab, filters, setFilters }) {
  const viewMap = {
    'Team Velocity & Impact': <TeamImpactView filters={filters} />,
    'Team Investigation': <TeamInvestigationView filters={filters} setFilters={setFilters} />,
    'Team Creation & Outlining': <TeamCreationView filters={filters} setFilters={setFilters} />,
    'Team Messages': <TeamMessagesView />,
    'Team Time Tracking': <TeamTimeTrackingView filters={filters} />,
    'Team Magic Moments': <TeamMagicView />,
  };

  return (
    <div className="min-h-full py-4">
      <div>{viewMap[activeTab.type]}</div>
    </div>
  );
}
