import { useState } from 'react';

import { TrackedTableModal } from './TrackedTableModal';

import { classNames } from '../../InvestigationBoard/helpers';

export function DayRow({ date, dailyTotals, dailyBreakdown, isLastPart }) {
  const [isHovered, setIsHovered] = useState(false);
  const [showTrackedDetails, setShowTrackedDetails] = useState(false);

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  function handleShowTrackedDetails() {
    setShowTrackedDetails((prev) => !prev);
  }

  return (
    <>
      <tr
        className={classNames(isHovered ? 'bg-white' : '', 'cursor-pointer')}
        onClick={handleShowTrackedDetails}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <td
          className={classNames(
            !isLastPart ? 'border-b border-gray-200' : '',
            'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
          )}
        >
          {date}
        </td>
        <td
          className={classNames(
            !isLastPart ? 'border-b border-gray-200' : '',
            'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell',
          )}
        >
          {dailyTotals.tracked}
        </td>
        <td
          className={classNames(
            !isLastPart ? 'border-b border-gray-200' : '',
            'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell',
          )}
        >
          {dailyTotals.ooo}
        </td>
        <td className={classNames(!isLastPart ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
          {dailyTotals.triage}
        </td>
        <td className={classNames(!isLastPart ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
          {dailyTotals.other}
        </td>
        <td className={classNames(!isLastPart ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
          {dailyTotals.overtime}
        </td>
        <td
          className={classNames(
            !isLastPart ? 'border-b border-gray-200' : '',
            dailyTotals.untracked > 0.5 ? 'bg-red-100' : '',
            dailyTotals.untracked > 0 && dailyTotals.untracked <= 0.5 ? 'bg-yellow-100' : '',
            'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
          )}
        >
          {dailyTotals.untracked}
        </td>
        <td className={classNames(!isLastPart ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
          {dailyTotals.total}
        </td>
      </tr>
      {showTrackedDetails && <TrackedTableModal title={date} breakdown={dailyBreakdown} handleShowTrackedDetails={handleShowTrackedDetails} />}
    </>
  );
}
