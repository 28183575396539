import { classNames } from '../../InvestigationBoard/helpers';
import { getDisplayName } from '../helpers';
import {
  computeComparisonMetricsForInvestigationCompTable,
  computeQAEMetricsForInvestigationCompTable,
  computeTeamMetricsForInvestigationCompTable,
  determineColorForInvestigationCompTable,
} from './tableHelpers';

export default function WeeklyInvestigationComparisonTable({ teamData, qaeData, targetQAE, targetTeam }) {
  // comp table categories
  const CATEGORIES = [
    'Failures Investigated',
    'Average Failures Per Week',
    'Test Steps Investigated',
    'Average Test Steps Per Week',
    'Failures Passed on Retry',
    'Average Failures Passed on Retry Per Week',
    'Failures Passed with Fixes',
    'Average Failures Passed with Fixes Per Week',
    'Failures Investigated as Bug',
    'Average Failures Investigated as Bug Per Week',
    'Failures Investigated as Maintenance',
    'Average Failures Investigated as Maintenance Per Week',
    'Suites Investigated',
    'Average Suites Per Week',
    'Median Suites Per Week',
    'Max Suites Per Week',
    'Min Suites Per Week',
    'Suites Assisted',
  ];

  const computedQAEMetrics = computeQAEMetricsForInvestigationCompTable(qaeData);
  const computedTeamMetrics = computeTeamMetricsForInvestigationCompTable(teamData, targetTeam);
  const computedComparisonMetrics = computeComparisonMetricsForInvestigationCompTable(computedQAEMetrics, computedTeamMetrics);

  return (
    <div className="w-full h-full overflow-hidden border">
      <table className="min-w-full min-h-full text-left">
        <thead className="bg-white">
          <tr className="divide-x divide-gray-200 border-b border-b-gray-200">
            <th scope="col" className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 w-1/3">
              <span className="flex gap-x-4 justify-center items-center">
                <img src={targetQAE.avatar48} alt="avatar" className="rounded-full h-10 w-auto" />
                <span>{getDisplayName(targetQAE.name)}</span>
              </span>
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-1/6">
              {targetTeam.name}:
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-1/6">
              {targetQAE.name.split(' ')[0]}:
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-1/6">
              Comparison:
            </th>
          </tr>
        </thead>
        <tbody>
          {CATEGORIES.map((c, cIdx) => (
            <tr key={cIdx} className="divide-x divide-x-gray-200 border-b border-b-gray-200">
              <td className="relative pl-4 py-4 text-sm font-medium text-gray-900 truncate w-1/6">{c}</td>
              <td className="tabular-nums hidden px-3 py-4 text-sm text-gray-500 sm:table-cell w-1/6">{computedTeamMetrics[c]}</td>
              <td
                className={classNames(
                  determineColorForInvestigationCompTable(c, computedComparisonMetrics[c], targetTeam, computedTeamMetrics),
                  'tabular-nums hidden px-3 py-4 text-sm text-gray-500 sm:table-cell w-1/6',
                )}
              >
                {computedQAEMetrics[c]}
              </td>
              <td
                className={classNames(
                  determineColorForInvestigationCompTable(c, computedComparisonMetrics[c], targetTeam, computedTeamMetrics),
                  'tabular-nums hidden px-3 py-4 text-sm text-gray-500 sm:table-cell w-1/6',
                )}
              >
                {computedComparisonMetrics[c] > 0 ? '+' : ''}
                {computedComparisonMetrics[c]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
