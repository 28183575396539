import { classNames } from '../../InvestigationBoard/helpers';

/**
 *
 * @param {object} props
 * @param {{type: string, current: boolean}[]} props.tabs
 * @param {import('react').Dispatch<import('react').SetStateAction<{type: string, current: boolean}[]>>} props.setTabs
 * @param {{type: string, current: boolean}} props.activeTab
 * @returns {import('react').JSX.Element}
 */
export default function Tabs({ tabs, setTabs, activeTab }) {
  const handleTabChange = (selectedTab) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) => {
        if (tab.type === selectedTab.type) {
          return { ...tab, current: true };
        }
        return { ...tab, current: false };
      }),
    );
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="type"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          value={activeTab.type}
          onChange={(event) => handleTabChange(event.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.type}>{tab.type}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <button
              type="button"
              key={tab.type}
              onClick={() => handleTabChange(tab)}
              className={classNames(
                tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <span>{tab.type}</span>
              <span
                aria-hidden="true"
                className={classNames(tab.current ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5')}
              />
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}
