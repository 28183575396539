import moment from 'moment';
import { transformCrTasks, transformMaintenanceTasks, transformGenericTasks } from './Summary/CustomerTasks/ganttHelpers';
import _ from 'lodash';
import dayjs from 'dayjs';

const POINTS_TO_TEST_RATIO = 0.05;
const MAINT_MULTIPLIER = 0.25;
const OUTLINE_MULTIPLIER = 0.2;
const CREATION_MULTIPLIER = 1;
const TRIAGE_MULTIPLIER = 0.01;

/**
 * Calculates the steps and score for reports.
 * @param {import('../../types').TeamDetails} team - The team object.
 * @returns {object} - The reports object containing the calculated wfs, steps, and score.
 */
function stepsAndScoreForMaint(team) {
  const allChildren = _.flattenDeep(team.maintenance.map((report) => report.childTasks));
  const [openMaint, completedMaint] = _.partition(allChildren, (report) => report.status !== 'done');

  const completedByWeek = _.groupBy(completedMaint, (task) => moment(task.completedAt).startOf('week').unix());

  // we dont't get credit for open tasks that are past due
  const dueThisWeekAndAfter = openMaint.filter((task) => moment(task.dueAt).isSameOrAfter(moment(), 'week'));
  const openByWeek = _.groupBy(dueThisWeekAndAfter, (task) => moment(task.dueAt).startOf('week').unix());

  // merge open and completed by week
  const reportsObject = { ...completedByWeek, ...openByWeek };

  for (let [key, tasksThisWeek] of Object.entries(reportsObject)) {
    let stepsThisWeek = _.sum(tasksThisWeek.map((t) => t.workflow.stepCount)) || 0;

    reportsObject[key] = {
      // @ts-ignore
      wfs: tasksThisWeek,
      steps: stepsThisWeek,
      score: Math.ceil(stepsThisWeek * MAINT_MULTIPLIER),
    };
  }

  return reportsObject;
}

/**
 * Calculates the steps and score for reports.
 * @param {import('../../types').TeamDetails} team - The team object.
 * @returns {object} - The reports object containing the calculated wfs, steps, and score.
 */
function countTestStepsForCRs(team) {
  const allChildren = _.flattenDeep(team.crs.map((cr) => cr.childTasks));
  const [openCRs, completedCRs] = _.partition(allChildren, (cr) => cr.status !== 'done');

  const completedByWeek = _.groupBy(completedCRs, (cr) => moment(cr.completedAt).startOf('week').unix());

  // we dont't get credit for open tasks that are past due
  const dueThisWeekAndAfter = openCRs.filter((cr) => (cr.dueAt ? moment(cr.dueAt).isSameOrAfter(moment(), 'week') : false));
  const openByWeek = _.groupBy(dueThisWeekAndAfter, (cr) => moment(cr.dueAt).startOf('week').unix());

  // merge open and completed by week
  const crsObject = { ...completedByWeek, ...openByWeek };

  for (let [key, testsForWeek] of Object.entries(crsObject)) {
    let [createdWfsThisWeek, outlinedWfsThisWeek] = _.partition(testsForWeek, (task) => task.type === 'testCreation');
    let outlinedStepsThisWeek = outlinedWfsThisWeek.length; // there's no step count, we just get credit for the outline
    let createdStepsThisWeek = _.sum(createdWfsThisWeek.map((t) => t.workflow.stepCount)) || 0;

    crsObject[key] = {
      // @ts-ignore
      wfs: [...createdWfsThisWeek, ...outlinedWfsThisWeek],
      steps: outlinedStepsThisWeek + createdStepsThisWeek,
      score: Math.ceil(createdStepsThisWeek * CREATION_MULTIPLIER + outlinedStepsThisWeek * OUTLINE_MULTIPLIER),
    };
  }

  return crsObject;
}

/**
 * Calculates the completed creation metrics for this week.
 * @param {import('../../types').TeamDetails} team - The team object.
 * @returns {object} - An object containing the workflows scheduled for this week and the total tests scheduled to be created.
 */
function getThisWeeksCompletedCreation(team) {
  // Isolate the workflows completed this week
  const workflowsCompletedThisWeek = team.crs
    .map((cr) =>
      cr.childTasks.filter((t) => t.type === 'testCreation' && t.status === 'done' && dayjs(t.completedAt).isSame(dayjs().startOf('week'), 'week')),
    )
    .flat();

  // Calculate total tests created this week
  const testsCreatedThisWeek = workflowsCompletedThisWeek.reduce((acc, curr) => acc + +curr.workflow.stepCount, 0);

  return { workflowsCompletedThisWeek, testsCreatedThisWeek };
}

/**
 * Calculates the scheduled (incomplete) creation metrics for this week.
 * @param {import('../../types').TeamDetails} team - The team object.
 * @returns {object} - An object containing the workflows scheduled for this week and the total tests scheduled to be created.
 */
function getThisWeeksScheduledCreation(team) {
  // Isolate the incomplete workflows scheduled for this week
  const workflowsScheduledThisWeek = team.crs
    .map((cr) =>
      cr.childTasks.filter(
        (t) => t.type === 'testCreation' && t.status !== 'done' && t.dueAt && dayjs(t.dueAt).isSame(dayjs().startOf('week'), 'week'),
      ),
    )
    .flat();

  // Calculate total tests scheduled to be created this week (but have not been completed)
  const testsScheduledThisWeek = workflowsScheduledThisWeek.reduce((acc, curr) => acc + +curr.workflow.stepCount, 0);

  return { workflowsScheduledThisWeek, testsScheduledThisWeek };
}

/**
 * Calculates the scheduled (incomplete) creation metrics for this week.
 * @param {import('../../types').TeamDetails} team - The team object.
 * @returns {object} - An object containing the workflows scheduled for this week and the total tests scheduled to be created.
 */
function getNextWeeksScheduledCreation(team) {
  // Isolate the incomplete workflows scheduled for this week
  const workflowsScheduledNextWeek = team.crs
    .map((cr) =>
      cr.childTasks.filter(
        (t) => t.type === 'testCreation' && t.status !== 'done' && t.dueAt && dayjs(t.dueAt).isSame(dayjs().add(1, 'week').startOf('week'), 'week'),
      ),
    )
    .flat();

  // Calculate total tests scheduled to be created this week (but have not been completed)
  const testsScheduledNextWeek = workflowsScheduledNextWeek.reduce((acc, curr) => acc + +curr.workflow.stepCount, 0);

  return { workflowsScheduledNextWeek, testsScheduledNextWeek };
}

/**
 * Retrieves the completed outlines and outline test counts for the current week.
 * @param {import('../../types').TeamDetails} team - The team object.
 * @returns {object} - An object containing completed outlines and outline test count for the current week
 */
function getThisWeeksCompletedOutlines(team) {
  const outlinesCompletedThisWeek = team.crs
    .map((cr) =>
      cr.childTasks.filter((t) => t.type === 'outline' && t.status === 'done' && dayjs(t.completedAt).isSame(dayjs().startOf('week'), 'week')),
    )
    .flat();

  const testsOutlinedThisWeek = outlinesCompletedThisWeek.map((t) => +t.workflow.stepCount || 0).reduce((acc, curr) => acc + curr, 0);

  return { outlinesCompletedThisWeek, testsOutlinedThisWeek };
}

/**
 * Retrieves the scheduled outlines and outline test counts for the current week.
 * @param {import('../../types').TeamDetails} team - The team object.
 * @returns {object} - An object containing completed outlines and outline test count for the current week
 */
function getThisWeeksScheduledOutlines(team) {
  const outlinesScheduledThisWeek = team.crs
    .map((cr) =>
      cr.childTasks.filter((t) => t.type === 'outline' && t.status !== 'done' && t.dueAt && dayjs(t.dueAt).isSame(dayjs().startOf('week'), 'week')),
    )
    .flat();

  const testsScheduledForOutlineThisWeek = outlinesScheduledThisWeek.map((t) => +t.workflow.stepCount || 0).reduce((acc, curr) => acc + curr, 0);

  return { outlinesScheduledThisWeek, testsScheduledForOutlineThisWeek };
}

/**
 * Retrieves the completed maintenance tasks and test count for the current week.
 * @param {import('../../types').TeamDetails} team - The team object.
 * @returns {object} - An object with completed maintenance tasks and test count for the current week.
 */
function getThisWeeksCompletedMaintenance(team) {
  const maintenanceCompletedThisWeek = team.maintenance
    .map((maint) => maint.childTasks.filter((t) => t.status === 'done' && dayjs(t.completedAt).isSame(dayjs().startOf('week'), 'week')))
    .flat();

  const testsMaintainedThisWeek = maintenanceCompletedThisWeek.map((m) => +m.workflow.stepCount || 0).reduce((acc, curr) => acc + curr, 0);

  return { maintenanceCompletedThisWeek, testsMaintainedThisWeek };
}

/**
 * Retrieves the scheduled maintenance tasks and test count for the current week.
 * @param {import('../../types').TeamDetails} team - The team object.
 * @returns {object} - An object with scheduled maintenance tasks and test count for the current week.
 */
function getThisWeeksScheduledMaintenance(team) {
  const maintenanceScheduledThisWeek = team.maintenance
    .map((maint) => maint.childTasks.filter((t) => t.status !== 'done' && t.dueAt && dayjs(t.dueAt).isSame(dayjs().startOf('week'), 'week')))
    .flat();

  const testsScheduledForMaintThisWeek = maintenanceScheduledThisWeek.map((m) => +m.workflow.stepCount || 0).reduce((acc, curr) => acc + curr, 0);

  return { maintenanceScheduledThisWeek, testsScheduledForMaintThisWeek };
}

/**
 * Calculates the steps and score for reports.
 * @param {import('../../types').TeamDetails} team - The team object.
 * @returns {object} - The reports object containing the calculated triage, steps, and score.
 */
function countTestStepsForTriage(team) {
  // Not sure if this is accurate to actual triage
  // don't need to sort by status because they'll all be done within the same week they're created
  // filter to
  const triageByWeek = _.groupBy([...team.triage.map((t) => ({ ...t, stepsTriaged: t.stepsTriaged || 0 }))], (triage) => {
    return moment(triage.createdAt).startOf('week').unix();
  });

  for (let [key, triageForWeek] of Object.entries(triageByWeek)) {
    const flatTriage = triageForWeek.map((tri) => tri.stepsTriaged);
    const stepsThisWeek = _.sum(flatTriage);

    triageByWeek[key] = {
      // @ts-ignore
      triage: triageByWeek[key],
      steps: stepsThisWeek,
      score: Math.ceil(stepsThisWeek * TRIAGE_MULTIPLIER),
    };
  }

  return triageByWeek;
}

/**
 * Enriches & reshapes team data.
 * @param {import('../../types').TeamDetails} t - The team data object.
 * @returns {object} - The normalized team data object.
 */
export function enrichTeamData(t) {
  let stepLimit = t.stepLimit;
  let oldPricing = t.calculatedStepLimit !== t.stepLimit;
  if (oldPricing) {
    stepLimit = t.calculatedStepLimit;
  }

  let weeklyPoints = Math.ceil(stepLimit * POINTS_TO_TEST_RATIO);
  let maintenanceScore = stepsAndScoreForMaint(t);
  let crsScore = countTestStepsForCRs(t);
  let triage = countTestStepsForTriage(t);

  let maintenanceGanttTasks = transformMaintenanceTasks(t.maintenance);
  let crGanttTasks = transformCrTasks(t.crs);
  let genericGanttTasks = transformGenericTasks(t.genericTasks);

  // sort gantt tasks by end date
  let ganttTasks = [...genericGanttTasks, ...maintenanceGanttTasks, ...crGanttTasks];

  let incompleteMrs = t.maintenance.filter((x) => x.status !== 'done' && x.status !== 'ignore');
  let avgMrsAge = '-';
  let maxMrsAge = '-';
  if (incompleteMrs.length) {
    let allMrsAge = incompleteMrs.map((x) => moment().diff(moment(x.createdAt), 'days'));
    avgMrsAge = `${_.mean(allMrsAge).toFixed(1)} days`;
    maxMrsAge = `${_.max(allMrsAge).toFixed(1)} days`;
  }
  let incompleteMaintenanceGanttTasks = transformMaintenanceTasks(incompleteMrs);

  let incompleteCrs = t.crs.filter((x) => x.status !== 'done' && x.status !== 'ignore');
  let avgCrsAge = '-';
  let maxCrsAge = '-';
  if (incompleteCrs.length) {
    let allCrsAge = incompleteCrs.map((x) => moment().diff(moment(x.createdAt), 'days'));
    avgCrsAge = `${_.mean(allCrsAge).toFixed(0)} days`;
    maxCrsAge = `${_.max(allCrsAge).toFixed(0)} days`;
  }

  let incompleteCrGanttTasks = transformCrTasks(incompleteCrs);
  let incompleteGanttTasks = [...incompleteMaintenanceGanttTasks, ...incompleteCrGanttTasks];

  const scheduledCrsForThisWeek = incompleteCrs.filter((x) => dayjs(x.dueAt).isSame(dayjs(), 'week'));
  const incompleteUnblockedCrs = incompleteCrs.filter((x) => x.status !== 'blocked');

  const { workflowsCompletedThisWeek, testsCreatedThisWeek } = getThisWeeksCompletedCreation(t);
  const { workflowsScheduledThisWeek, testsScheduledThisWeek } = getThisWeeksScheduledCreation(t);
  const { workflowsScheduledNextWeek, testsScheduledNextWeek } = getNextWeeksScheduledCreation(t);
  const { maintenanceCompletedThisWeek, testsMaintainedThisWeek } = getThisWeeksCompletedMaintenance(t);
  const { maintenanceScheduledThisWeek, testsScheduledForMaintThisWeek } = getThisWeeksScheduledMaintenance(t);
  const { outlinesCompletedThisWeek, testsOutlinedThisWeek } = getThisWeeksCompletedOutlines(t);
  const { outlinedScheduledForThisWeek, testsScheduledForOutlineThisWeek } = getThisWeeksScheduledOutlines(t);

  // Calculate team metrics
  let testMetrics = {
    expected: t.metrics.tests?.expected || 0,
    current: t.metrics.tests?.active || 0,
    delta: t.metrics.tests.active - t.metrics.tests.expected,
    status: t.metrics.tests.active - t.metrics.tests.expected === 0 ? 'At Budget' : t.metrics.tests.isBehind ? 'Behind' : 'On Track',
    overBudget: t.metrics.tests.active > t.calculatedStepLimit,
  };

  let expected_outlines = (t.metrics.outlines?.expectedOutlinesPercentage || 0) * t.calculatedStepLimit;
  let total_outlines = (t.metrics.outlines?.toDo || 0) + (t.metrics.tests?.active || 0);
  let outlineMetrics = {
    expected: expected_outlines,
    current: total_outlines,
    delta: total_outlines - expected_outlines,
    status: t.metrics.outlines.behindOutlines ? 'Behind' : 'On Track',
    overBudget: t.metrics.outlines.utilization > 1,
  };

  return {
    name: t.name,
    id: t.id,
    slug: t.slug,
    ageInWeeks: t.ageInWeeks,
    status: t.teamStatus,
    oldPricing: oldPricing,
    stepLimit: stepLimit,
    weeklyPoints: weeklyPoints,
    rawMaintenance: t.maintenance,
    maintenanceScore: maintenanceScore,
    rawCrs: t.crs,
    crsScore: crsScore,
    triage,
    maintenanceGanttTasks,
    crGanttTasks,
    ganttTasks,
    incompleteGanttTasks,
    scheduledCrsForThisWeek,
    incompleteUnblockedCrs,
    thisWeeksCompletedCreation: {
      workflows: workflowsCompletedThisWeek,
      testCount: testsCreatedThisWeek,
    },
    thisWeeksScheduledCreation: {
      workflows: workflowsScheduledThisWeek,
      testCount: testsScheduledThisWeek,
    },
    nextWeeksScheduledCreation: {
      workflows: workflowsScheduledNextWeek,
      testCount: testsScheduledNextWeek,
    },
    thisWeeksCompletedOutlines: {
      outlines: outlinesCompletedThisWeek,
      testCount: testsOutlinedThisWeek,
    },
    thisWeeksScheduledOutlines: {
      outlines: outlinedScheduledForThisWeek,
      testCount: testsScheduledForOutlineThisWeek,
    },
    thisWeeksCompletedMaint: {
      workflows: maintenanceCompletedThisWeek,
      testCount: testsMaintainedThisWeek,
    },
    thisWeeksScheduledMaint: {
      workflows: maintenanceScheduledThisWeek,
      testCount: testsScheduledForMaintThisWeek,
    },
    metrics: t.metrics,
    flakiness: t.flakiness,
    bugs: t.bugs,
    _metaData: t,
    outlines: outlineMetrics,
    tests: testMetrics,
    pilotConverted: t.ageInWeeks > 3, // we may want better way to track this. The didPilotConvert field on contracts is not being used anymore.
    startedAt: moment(t.dates.startDate).format('YYYY-MM-DD'),
    pilotEnd: moment(t.dates.pilotEndDate).format('YYYY-MM-DD'),
    renewsAt: moment(t.dates.renewsAt).format('YYYY-MM-DD'),
    completeness: t.metrics.tests?.percentComplete || 0,
    staleness: {
      maxCrAge: maxCrsAge,
      avgCrAge: avgCrsAge,
      maxMaintAge: maxMrsAge,
      avgMaintAge: avgMrsAge,
    },
  };
}
