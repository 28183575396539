import { useMemo } from 'react';

import CardBase from './CardBase';

import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';

import { WOLF_LOGO } from '../../../constants';

/**
 * Pack card for metrics playground legend
 * @param {object} props
 * @param {import('../types').ActiveCard} props.card
 * @param {{[key: string]: boolean}} props.visibilityProps
 * @param {import('react').Dispatch<import('react').SetStateAction<{[key: string]: boolean}>>} props.visibilitySetter
 * @param {{hover: string | null}} props.hoverProps
 * @param {import('react').Dispatch<import('react').SetStateAction<{hover: string | null}>>} props.hoverSetter
 * @returns {import('react').JSX.Element}
 */
export default function IndividualQAECard({ card, visibilityProps, visibilitySetter, hoverProps, hoverSetter }) {
  const noNameProvidedPlaceholder = 'Un-Named QAE Card';
  const cardId = `${card.cardSetId}:${card.cardName || noNameProvidedPlaceholder}`;

  // handle no image url
  if (!card.imageUrl) {
    card.imageUrl = WOLF_LOGO;
  }

  // build QAE toggles from data
  const QAEToggles = useMemo(
    /** @returns {import('../types').CustomLegendToggle[]} */
    () => [
      {
        value: 'Impact Score',
        color: hslStringToRgbString(stringToColor(`${cardId}:Impact Score`)),
        type: 'pack',
        identifier: `${cardId}:Impact Score`,
      },

      {
        value: 'Tests Investigated', // across all QAEs in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Tests Investigated`)),
        type: 'pack',
        identifier: `${cardId}:Tests Investigated`,
      },
      {
        value: 'Tests Created', // across all QAEs in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Tests Created`)),
        type: 'pack',
        identifier: `${cardId}:Tests Created`,
      },
      {
        value: 'Tests Planned', // across all QAEs in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Tests Planned`)),
        type: 'pack',
        identifier: `${cardId}:Tests Planned`,
      },
      {
        value: 'Tests Investigated Per Hour', // per QAE in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Tests Investigated Per Hour`)),
        type: 'pack',
        identifier: `${cardId}:Tests Investigated Per Hour`,
      },
      {
        value: 'Tests Created Per Hour', // per QAE in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Tests Created Per Hour`)),
        type: 'pack',
        identifier: `${cardId}:Tests Created Per Hour`,
      },
    ],
    [card.cardName],
  );

  return (
    <CardBase
      card={card}
      toggles={QAEToggles}
      visibilityProps={visibilityProps}
      visibilitySetter={visibilitySetter}
      hoverProps={hoverProps}
      hoverSetter={hoverSetter}
    />
  );
}
