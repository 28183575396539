import _ from 'lodash';

import { useEffect, useState } from 'react';
import { Bar, BarChart, Legend, XAxis, YAxis } from 'recharts';

export function MetricsBarChart({ normalizedData }) {
  const [categoryHover, setCategoryHover] = useState(null);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // sum all hours spent
  const totalHours = _.sumBy(normalizedData, 'value');

  // calculate each category's percentage of total hours
  const chartData = [{}];
  normalizedData.forEach((category) => {
    const percent = _.round((category.value / totalHours) * 100, 2);
    category.percent = percent;
    chartData[0][category.name.toLowerCase()] = percent;
  });

  // sort categories by name
  normalizedData.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        {categoryHover ? (
          <span className="font-bold">
            {categoryHover.name}: {categoryHover.value} hrs | {categoryHover.percent}%
          </span>
        ) : (
          <span className="font-bold">Hover for category details</span>
        )}
        <span className="font-bold">Total: {new Intl.NumberFormat('en-US').format(totalHours)} hrs</span>
      </div>
      <div>
        <BarChart layout="vertical" width={containerWidth * 0.65} height={75} data={chartData} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
          <XAxis type="number" dataKey="value" domain={[0, 100]} hide={true} />
          <YAxis type="category" dataKey="name" hide={true} />
          {normalizedData.map((category, categoryIdx) => {
            return (
              <Bar
                key={categoryIdx}
                dataKey={category.name.toLowerCase()}
                stackId="a"
                fill={category.fill}
                onMouseOver={() => setCategoryHover(category)}
              />
            );
          })}
          <Legend verticalAlign="bottom" layout="horizontal" align="center" wrapperStyle={{ fontSize: '0.75em' }} />
        </BarChart>
      </div>
    </div>
  );
}
