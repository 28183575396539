/**
 * @typedef {object} SumObject
 * @property {number} sumTasks
 * @property {number} sumTests
 * @property {SumsByType} sumsByType
 */

/**
 * @typedef {object} SumsByType
 * @property {SumObject} testCreation
 * @property {SumObject} outline
 */

/**
 * @typedef {object} QaeCreationMetrics
 * @property {object} qae
 * @property {WeeklyTaskBreakdown[]} qaeCreationByWeekAndType
 */

/**
 * @typedef {object} TeamCreationMetrics
 * @property {object} team
 * @property {WeeklyTaskBreakdown[]} teamCreationByWeekAndType
 */

/**
 * @typedef {object} User
 * @property {number} id
 * @property {string} slackId
 * @property {string} name
 * @property {string} email
 * @property {string} secure
 * @property {boolean} is_qawolf
 * @property {boolean} isQAE
 * @property {string} qawId
 * @property {string} avatar48
 * @property {number} teamId
 * @property {boolean} isLead
 * @property {boolean} isManager
 * @property {boolean} isCSM
 * @property {string} position
 * @property {number} salary
 * @property {string} startDate
 */

/**
 * @typedef {object} WeeklyTaskBreakdown
 * @property {string} WeekNumber
 * @property {string} type
 * @property {string} total_tasks
 * @property {string} total_steps
 * @property {string} name
 * @property {string} completed_by_qaw_id
 */

/**
 * @typedef {object} QAEMetricsFilter
 * @property {object} entity
 * @property {'qae'} entity.type
 * @property {object} entity.data
 * @property {string} entity.data.id
 * @property {InvestigationOptions} investigationOptions
 * @property {string[]} types
 * @property {string} gte
 * @property {string} lte
 */

/**
 * @typedef {object} TeamMetricsFilter
 * @property {object} entity
 * @property {'team'} entity.type
 * @property {object} entity.data
 * @property {string} entity.data.name
 * @property {InvestigationOptions} investigationOptions
 * @property {string[]} types
 * @property {string} gte
 * @property {string} lte
 */

/**
 * @typedef {object} PackMetricsFilter
 * @property {object} entity
 * @property {'pack'} entity.type
 * @property {object} entity.data
 * @property {number | string} entity.data.id
 * @property {InvestigationOptions} investigationOptions
 * @property {string[]} types
 * @property {string} gte
 * @property {string} lte
 */

/**
 * @typedef {object} InvestigationOptions
 * @property {boolean} includeRuns - Indicates whether runs should be included.
 * @property {boolean} includeEvents - Indicates whether events should be included.
 * @property {boolean} includeFlatOrderedData - Indicates whether flat ordered data should be included.
 * @property {boolean} includeRunsByLastAttemptStatus - Indicates whether runs by last attempt status should be included.
 * @property {boolean} includeAssistedSuites - Indicates whether assisted suites should be included.
 */

/**
 * @typedef {object} InvestigationTask
 * @property {string} id - The unique identifier of the task.
 * @property {string} customerId - The customer ID associated with the task.
 * @property {string} customerSlug - The customer slug.
 * @property {string} triggerName - The name of the trigger.
 * @property {string} startTimeString - The start time of the task in string format.
 * @property {string} taskClaimedBy - The email of the person who claimed the task.
 * @property {Array<object>} supportedBy - An array of supporting objects.
 * @property {number} numberOfRuns - The number of runs.
 * @property {number} numberOfRunAttempts - The number of run attempts.
 * @property {number} numberOfRunsPassingOnFirstAttempt - The number of runs passing on the first attempt.
 * @property {number} numberOfRunsPassingOnSecondAttempt - The number of runs passing on the second attempt.
 * @property {number} numberOfRunsPassingOnThirdAttempt - The number of runs passing on the third attempt.
 * @property {number} numberOfRunsPassingOnQaeFlake - The number of runs passing on QAE flake.
 * @property {number} numberOfRunsPassingOnQaeFix - The number of runs passing on QAE fix.
 * @property {number} numberOfRunsAutoInvestigation - The number of runs auto-investigation.
 * @property {number} numberOfRunsTriagedAsBug - The number of runs investigation as bugs.
 * @property {number} numberOfRunsTriagedAsMaintenance - The number of runs investigation as maintenance.
 * @property {number} numberOfRunsCanceled - The number of runs canceled.
 * @property {number} numberOfRunsTriagedAsDoNotInvestigate - The number of runs investigation as do not investigate.
 * @property {number} numberOfSupersededRuns - The number of superseded runs.
 * @property {number} successRate - The success rate.
 * @property {number} failureRate - The failure rate.
 * @property {number} flakeRate - The flake rate.
 * @property {number} runTimeInMinutes - The run time in minutes.
 * @property {number} timeToCompleteSecondAttemptInMinutes - The time to complete the second attempt in minutes.
 * @property {number} timeToCompleteThirdAttemptInMinutes - The time to complete the third attempt in minutes.
 * @property {number} timeToCompleteAllAutomaticAttemptsInMinutes - The time to complete all automatic attempts in minutes.
 * @property {number} investigationTimeInMinutes - The investigation time in minutes.
 * @property {number} totalTimeInMinutes - The total time in minutes.
 * @property {number} totalSuiteExecutionTimeInMinutes - The total suite execution time in minutes.
 * @property {number} minutesUntilFirstQaeAttempt - The minutes until the first QAE attempt.
 * @property {number} minutesUntilInvestigationStarted - The minutes until investigation started.
 * @property {Array<object>} supersededSuiteIds - An array of superseded suite IDs.
 * @property {Array<object>} cleanVersions - An array of clean versions.
 * @property {string} environmentId - The environment ID.
 * @property {string} startTime - The start time of the task in ISO 8601 format.
 * @property {string} customerName - The customer name.
 * @property {number} deterministicTestsPercent - The percentage of deterministic tests.
 * @property {number} flakePercent - The percentage of flakes.
 * @property {boolean} isCanceled - Whether the task is canceled.
 * @property {boolean} isCompleted - Whether the task is completed.
 * @property {boolean} isMovedToNewerSuite - Whether the task is moved to a newer suite.
 * @property {boolean} isSuperseded - Whether the task is superseded.
 * @property {number} successPercent - The success percentage.
 * @property {object[]} [runs] - Array of runs having QAE uptime on them.
 */

/**
 * @typedef {object} WeeklyInvestigationAggregates
 * @property {number} sumFailures
 * @property {number} sumTests
 * @property {number} sumInvestigatedAsBug
 * @property {number} sumInvestigatedAsMaint
 * @property {number} sumPassedOnFlake
 * @property {number} sumPassedOnFix
 * @property {number} avgMinsUntilInvestigationStart
 * @property {number} avgMinsUntilFirstQaeAttempt
 * @property {number} avgInvestigationTime
 */

/**
 * @typedef {object} QATeam
 *  @property {number} id
 *  @property {string | null} slackSubteamId
 *  @property {string | null} primaryChannelId
 *  @property {string | null} emoji
 *  @property {number} leadId
 *  @property {string} name
 *  @property {boolean} active
 *  @property {number | null} packId
 *  @property {string | null} imageUrl
 * @property {User[]} [members]
 */

/**
 * @typedef {object} QATeamFromLocalStorage
 * @property {number} id
 * @property {string} name
 * @property {string} teamName
 * @property {number} packId
 * @property {boolean} active
 * @property {string} email
 * @property {string} qawId
 * @property {string} imageUrl
 * @property {boolean} selectedInSettings
 */

/**
 * @typedef {object} QAEFromLocalStorage
 * @property {string} qawId
 * @property {string} name
 * @property {string} email
 * @property {string} avatar48
 * @property {number} teamId
 * @property {boolean} isQAE
 * @property {boolean} isLead
 * @property {boolean} isManager
 * @property {string} startDate
 * @property {string} position
 */

/**
 * @typedef {object} ParsedEvent
 * @property {string} id
 * @property {EventType} type
 * @property {*} date
 * @property {*} start
 * @property {*} end
 * @property {number} durationInMinutes
 * @property {string} [customer]
 * @property {string} [timeTrackingCategory]
 */

/**
 * @typedef {"OOO" | "Other" | "Tracked" | "Triage"} EventType
 */

/**
 * @typedef {object} TimeTrackingTeamResponse
 * @property {QATeam} qaTeam
 * @property {{[key: number]: ParsedEvent[]}} events
 */

/*
----------------------------- VELOCITY & IMPACT METRICS TYPES -----------------------------
*/

/**
 * @typedef {object} InvestigationTrackedTimeAggregate
 * @property {string} user_id
 * @property {string} user_email
 * @property {string} week
 * @property {number} hours_tracked
 * @property {number} group_total_hours_tracked
 * @property {number} completed_snapshots
 * @property {number} group_total_completed_snapshots
 * @property {number} total_failures_investigated_this_week
 * @property {number} group_total_failures_investigated_this_week
 * @property {number} [total_tests_investigated_this_week]
 * @property {number} [group_total_tests_investigated_this_week]
 * @property {string[]} suite_ids
 * @property {Suite[]} suites
 */

/**
 * @typedef {object} Suite
 * @property {string} suite_id
 * @property {string} task_claimed_by_id
 * @property {string} task_claimed_by
 * @property {string} customer_name
 * @property {number} number_of_runs
 * @property {number} number_of_runs_passing_on_qae_flake
 * @property {number} number_of_runs_passing_on_qae_fix
 * @property {number} number_of_runs_triaged_as_bug
 * @property {number} number_of_runs_triaged_as_maintenance
 * @property {number} total_failures_investigated
 * @property {string} week
 * @property {Run[]} [runs]
 */

/**
 * @typedef {object} Run
 * @property {string} id
 * @property {string} suite_id
 * @property {string} workflow_id
 * @property {string} workflow_name
 * @property {string} triage_time
 * @property {string} triaged_by
 * @property {string} last_run_status
 * @property {string} triage_type
 * @property {boolean} issue_reported_on_run
 * @property {string} issue_id
 * @property {string} issue_name
 * @property {WorkflowTest[]} workflow_tests
 */

/**
 * @typedef {object} WorkflowTest
 * @property {string} id
 * @property {string} name
 * @property {string} environment_id
 * @property {string} environment_name
 */

/**
 * @typedef {object} PackInvestigationAggregateResponse
 * @property {QATeam} pack
 * @property {QATeam[]} packTeams
 * @property {InvestigationTrackedTimeAggregate[]} packInvestigationAggregatedWithTrackedTime
 */

/**
 * @typedef {object} TeamInvestigationAggregateResponse
 * @property {QATeam} team
 * @property {InvestigationTrackedTimeAggregate[]} teamInvestigationAggregatedWithTrackedTime
 */

/**
 * @typedef {object} QAEInvestigationAggregateResponse
 * @property {User} qae
 * @property {InvestigationTrackedTimeAggregate[]} qaeInvestigationAggregatedWithTrackedTime
 */

/**
 * @typedef { PackInvestigationAggregateResponse | TeamInvestigationAggregateResponse | QAEInvestigationAggregateResponse } InvestigationAggregateResponse
 */

/**
 * @typedef {object} TaskSnapshotTrackedTimeAggregate
 * @property {string} user_id
 * @property {string} user_email
 * @property {string} week
 * @property {number} hours_tracked
 * @property {number} group_total_hours_tracked
 * @property {number} workflows_completed_this_week
 * @property {number} group_workflows_completed_this_week
 * @property {number} tests_completed_this_week
 * @property {number} group_tests_completed_this_week
 * @property {string[]} workflow_ids
 * @property {TaskSnapshotWorkflow[]} workflows
 */

/**
 * @typedef {object} TaskSnapshotDetail
 * @property {string} id
 * @property {string} type
 * @property {string} dueAt
 * @property {string} status
 * @property {string} priority
 * @property {TaskSnapshotWorkflowDetail} workflow
 * @property {string} createdAt
 * @property {TaskSnapshotAssignedTo} assignedTo
 * @property {string} completedAt
 */

/**
 * @typedef {object} TaskSnapshotWorkflowDetail
 * @property {string} id
 * @property {string} name
 * @property {string} status
 * @property {number} stepCount
 */

/**
 * @typedef {object} TaskSnapshotAssignedTo
 * @property {string} id
 * @property {string} name
 * @property {string} email
 * @property {string} avatarUrl
 */

/**
 * @typedef {object} TaskSnapshotWorkflow
 * @property {string} workflow_id
 * @property {TaskSnapshotDetail} details
 * @property {string} task_claimed_by_id
 * @property {string} email
 * @property {string} customer_name
 * @property {string} week
 */

/**
 * @typedef {object} PackTaskSnapshotAggregateResponse
 * @property {QATeam} pack
 * @property {QATeam[]} packTeams
 * @property {TaskSnapshotTrackedTimeAggregate[]} packTaskSnapshotsAggregatedWithTrackedTime
 */

/**
 * @typedef {object} TeamTaskSnapshotAggregateResponse
 * @property {QATeam} team
 * @property {TaskSnapshotTrackedTimeAggregate[]} teamTaskSnapshotsAggregatedWithTrackedTime
 */

/**
 * @typedef {object} QAETaskSnapshotAggregateResponse
 * @property {User} qae
 * @property {TaskSnapshotTrackedTimeAggregate[]} qaeTaskSnapshotsAggregatedWithTrackedTime
 */

/**
 * @typedef {object} PackPlannedCreationAggregateResponse
 * @property {QATeam} pack
 * @property {{[key: string]: PackPlannedCreationData & {[key: string]: number}}} weeklyPlannedTestCounts
 */

/**
 * @typedef {object} PackPlannedCreationData
 * @property {number} packTotal
 */

/**
 * @typedef { PackTaskSnapshotAggregateResponse | TeamTaskSnapshotAggregateResponse | QAETaskSnapshotAggregateResponse } TaskSnapshotAggregateResponse
 */

/*
------------------------------ TYPE MAPS -----------------------------
*/

const taskTypeMap = {
  outline: 'Outlining',
  testCreation: 'Creation',
};

export { taskTypeMap };
