import axios from 'axios';

// eslint-disable-next-line no-undef
const baseUrl = `${process.env.REACT_APP_URL}/apis/task-wolf`;

export async function sendGetRequest(url) {
  const targetUrl = `${baseUrl}${url}`;

  const token = localStorage.getItem('token');

  const result = await axios.get(targetUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  });

  return result.data;
}

export async function sendPostRequest(url, body) {
  const targetUrl = `${baseUrl}${url}`;
  const stringifiedData = JSON.stringify(body);

  const token = localStorage.getItem('token');

  const result = await axios.post(targetUrl, stringifiedData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  });

  return result.data;
}

export async function sendPutRequest(url, body) {
  const targetUrl = `${baseUrl}${url}`;
  const stringifiedData = JSON.stringify(body);

  const token = localStorage.getItem('token');

  const result = await axios.put(targetUrl, stringifiedData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    },
  });

  return result.data;
}
