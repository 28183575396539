const TeamsAuthCode = () => {
  const tenantId = process.env.REACT_APP_TEAMS_TENANT_ID;
  const clientId = process.env.REACT_APP_TEAMS_CLIENT_ID;

  const getAuthCode = () => {
    window.location.replace(
      `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=https://qawolf-automation.herokuapp.com/apis/ms-teams/reports-auth/code&response_mode=form_post&scope=offline_access ChannelMessage.Read.All ChannelMessage.ReadWrite ChannelMessage.Send Chat.Read Chat.ReadBasic Chat.ReadWrite Chat.ReadWrite.All&state=automation-request`,
    );
  };

  return (
    <>
      <p className="mb-4">Press button to force a token reset on the server</p>
      <button
        type="button"
        className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        onClick={() => {
          getAuthCode();
        }}
      >
        Request Auth Code
      </button>
    </>
  );
};

export default TeamsAuthCode;
