import { classNames } from '../../InvestigationBoard/helpers.jsx';
import {
  computeComparisonMetricsForCreationCompTable,
  computeQAEMetricsForCreationCompTable,
  computeTeamMetricsForCreationCompTable,
  determineColorForCreationCompTable,
} from './tableHelpers.js';

export default function WeeklyCreationComparisonTable({ creationMetrics, qaeSums, teamCreationMetrics, teamSums }) {
  // default static categories
  const CATEGORIES = [
    'Workflows Created',
    'Tests Created',
    'Outlining Tasks Completed',
    'Average Tests Created Per Week',
    'Median Tests Created Per Week',
    'Max Tests Per Week',
    'Min Tests Per Week',
  ];

  // compute metrics for comparison table
  const computedQAEMetrics = computeQAEMetricsForCreationCompTable(creationMetrics, qaeSums);
  const computedTeamMetrics = computeTeamMetricsForCreationCompTable(teamCreationMetrics, teamSums);
  const computedComparisonMetrics = computeComparisonMetricsForCreationCompTable(computedQAEMetrics, computedTeamMetrics);

  return (
    <div className="w-full h-full overflow-hidden">
      <table className="min-w-full min-h-full text-left">
        <thead className="bg-white">
          <tr className="divide-x divide-gray-200 border-b border-b-gray-200">
            <th scope="col" className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 w-1/3">
              <span className="flex justify-center items-center">
                <img src={creationMetrics.qae.avatar48} alt="avatar" className="rounded-full h-10 w-auto" />
              </span>
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-1/6">
              {teamCreationMetrics.team.name}:
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-1/6">
              {creationMetrics.qae.name.split(' ')[0]}:
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-1/6">
              Comparison:
            </th>
          </tr>
        </thead>
        <tbody>
          {CATEGORIES.map((c, cIdx) => (
            <tr key={cIdx} className="divide-x divide-x-gray-200 border-b border-b-gray-200">
              <td className="relative pl-4 py-4 text-sm font-medium text-gray-900 truncate w-1/6">{c}</td>
              <td className="tabular-nums hidden px-3 py-4 text-sm text-gray-500 sm:table-cell w-1/6">{computedTeamMetrics[c]}</td>
              <td
                className={classNames(
                  determineColorForCreationCompTable(c, computedComparisonMetrics[c], teamCreationMetrics.team, teamSums),
                  'tabular-nums hidden px-3 py-4 text-sm text-gray-500 sm:table-cell w-1/6',
                )}
              >
                {computedQAEMetrics[c]}
              </td>
              <td
                className={classNames(
                  determineColorForCreationCompTable(c, computedComparisonMetrics[c], teamCreationMetrics.team, teamSums),
                  'tabular-nums hidden px-3 py-4 text-sm text-gray-500 sm:table-cell w-1/6',
                )}
              >
                {computedComparisonMetrics[c] > 0 ? '+' : ''}
                {computedComparisonMetrics[c]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
