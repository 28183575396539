import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import dayjs from 'dayjs';

const SuiteWorkflowStatusTable = ({ filteredSuiteData }) => {

  // Status to Description Map
  const statusToDescription = {
    passedFirst: "Passed on First Attempt",
    passedSecond: "Passed on Second Attempt",
    passedThird: "Passed on Third Attempt",
    qaeFlake: "Passed on QAE Retry",
    qaeTriage: "Passed on QAE Change",
    autoTriaged: "Previously Bugged",
    newlyBugged: "Newly Bugged",
    maintenance: "Maintenance",
    doNotInvestigate: "Do Not Investigate",
    canceled: "Canceled",
    failed: "Failed",
    supersededRuns: "Superseded",
  };

  // Create headers from the suite start times
  const headers = filteredSuiteData
    .sort((a, b) => dayjs(a.startTime).valueOf() - dayjs(b.startTime).valueOf())
    .map((suite) => `${suite.startTimeString} (PT)`);

  // Mapping of workflow names to their statuses across different suites
  const workflowStatusMap = {};
  const workflowSuiteSlowdownMap = {};
  const workflowFlakinessMap = {};

  const suiteSlowdownWeightage = {
    "Passed on First Attempt": 0,
    "Passed on Second Attempt": 30,
    "Passed on Third Attempt": 80,
    "Passed on QAE Retry": 130,
    "Passed on QAE Change": 0,
    "Previously Bugged": 0,
    "Newly Bugged": 0,
    "Maintenance": 0,
    "Do Not Investigate": 0,
    "Superseded": 90,
    "Canceled": 0,
    "Failed": 0,
  };

  const workflowFlakiness = {
    "Passed on First Attempt": 0,
    "Passed on Second Attempt": 1,
    "Passed on Third Attempt": 1,
    "Passed on QAE Retry": 1,
    "Passed on QAE Change": 0,
    "Previously Bugged": 0,
    "Newly Bugged": 0,
    "Maintenance": 0,
    "Do Not Investigate": 0,
    "Superseded": 0,
    "Canceled": 0,
    "Failed": 0,
  };

  filteredSuiteData.forEach((suite) => {
    Object.keys(suite.runsByLastAttemptStatus).forEach((status) => {
      suite.runsByLastAttemptStatus[status].forEach((run) => {
        const workflowName = run.name.split(': ').slice(1).join(': ').trim();
        if (!workflowStatusMap[workflowName]) {
          workflowStatusMap[workflowName] = Array(filteredSuiteData.length).fill('X'); // Initialize with 'X' assuming not found
          workflowSuiteSlowdownMap[workflowName] = { totalWeight: 0, count: 0 }; // Initialize Suite Health Effect tracking
          workflowFlakinessMap[workflowName] = { totalFlakiness: 0, count: 0 }; // Initialize Workflow Flakiness tracking
        }
        const index = filteredSuiteData.indexOf(suite);
        workflowStatusMap[workflowName][index] = statusToDescription[status]; // Set the status for the suite

        // Calculate Suite Health Effect
        workflowSuiteSlowdownMap[workflowName].totalWeight += suiteSlowdownWeightage[statusToDescription[status]] || 0;
        workflowSuiteSlowdownMap[workflowName].count += 1;

        // Calculate Workflow Flakiness
        workflowFlakinessMap[workflowName].totalFlakiness += workflowFlakiness[statusToDescription[status]] || 0;
        workflowFlakinessMap[workflowName].count += 1;
      });
    });
  });

  // Create rows with flakiness and suite health effect scores, and sort by flakiness
  const workflowRows = Object.entries(workflowStatusMap).map(([workflowName, statuses]) => {
    const flakiness = workflowFlakinessMap[workflowName].totalFlakiness / workflowFlakinessMap[workflowName].count;
    const suiteHealthEffect = workflowSuiteSlowdownMap[workflowName].totalWeight / workflowSuiteSlowdownMap[workflowName].count;
    return { workflowName, statuses, flakiness, suiteHealthEffect };
  }).sort((a, b) => b.flakiness - a.flakiness);

  const getFlakinessColor = (flakiness) => {
    if (flakiness <= 0.2) return '#ccffcc'; // light green
    if (flakiness <= 0.5) return '#ffff99'; // light yellow
    if (flakiness <= 0.8) return '#ffd699'; // light orange
    return '#ff6666'; // light red
  };

  const getSlowdownEffectColor = (flakiness) => {
    if (flakiness <= 20) return '#ccffcc'; // light green
    if (flakiness <= 50) return '#ffff99'; // light yellow
    if (flakiness <= 80) return '#ffd699'; // light orange
    return '#ff6666'; // light red
  };

  return (
    <TableContainer component={Paper} sx={{ width: "100%", maxHeight: '85vh' }}>
      <Table sx={{ minWidth: 650 }} aria-label="suite workflow status table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#f5f5f5' }}>Workflow Name</TableCell>
            {headers.map((header, index) => (
              <TableCell key={index} sx={{ fontWeight: 'bold', position: 'sticky', top: 0, zIndex: 0.9, backgroundColor: 'white' }} align="center">{header}</TableCell>
            ))}
            <TableCell sx={{ fontWeight: 'bold', position: 'sticky', top: 0, zIndex: 0.9, backgroundColor: 'white' }} align="center">Workflow Flakiness</TableCell>
            <TableCell sx={{ fontWeight: 'bold', position: 'sticky', top: 0, zIndex: 0.9, backgroundColor: 'white' }} align="center">Suite Health Effect</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workflowRows.map(({ workflowName, statuses, flakiness, suiteHealthEffect }, idx) => (
            <TableRow key={idx}>
              <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#f5f5f5' }}>
                {workflowName}
              </TableCell>
              {statuses.map((status, index) => (
                <TableCell key={index} align="center" sx={{ backgroundColor: getStatusColor(status) }}>{status}</TableCell>
              ))}
              <TableCell align="center" sx={{ backgroundColor: getFlakinessColor(flakiness) }}>{flakiness.toFixed(2)}</TableCell>
              <TableCell align="center" sx={{ backgroundColor: getSlowdownEffectColor(suiteHealthEffect) }}>{suiteHealthEffect.toFixed(0)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// Function to get color based on status
const getStatusColor = (status) => {
  const statusColorMap = {
    "Passed on First Attempt": '#ccffcc', // light green,
    "Previously Bugged": '#ffcccc', // lighter red
    "Passed on Second Attempt": '#ffff99', // light yellow
    "Passed on Third Attempt": '#ffd699', // light orange
    "Passed on QAE Change": '#99ccff', // light blue
    "Passed on QAE Retry": '#ffdb4d', // yellow-orange mix
    "Newly Bugged": '#ff6666', // a slightly lighter red than deep reds
    "Maintenance": '#e6ccff', // light purple
    "Do Not Investigate": '#d9d9d9', // light grey, neutral
    "Superseded": '#c0c0c0', // silver, neutral
    "Canceled": '#f2f2f2', // very light grey, almost white, neutral
    "Failed": '#cccccc', // grey, neutral
  };
  return statusColorMap[status] || '#ffffff'; // default to white if undefined
};

export default SuiteWorkflowStatusTable;
