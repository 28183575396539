import { differenceInMinutes } from 'date-fns';

export function getSince(diff) {
  if (diff === 0) return `0 minutes ago`;

  if (diff === 1) return `1 minute ago`;

  if (diff < 60) return `${diff} minutes ago`;

  if (diff === 60) return `1 hour ago`;

  if (diff < 1440) return `${Math.round(diff / 60)} hours ago`;

  if (diff < 2880) return `1 day ago`;

  return `${Math.floor(diff / 1440)} days ago`;
}

export function getElapsed(date) {
  const diff = differenceInMinutes(new Date(), date);
  return getSince(diff);
}

export function plural(n) {
  if (n === 1) return '';
  else return 's';
}

export function thisOrThese(n, capitalize = false) {
  if (n === 1) return capitalize ? 'This' : 'this';
  else return capitalize ? 'These' : 'these';
}
export function isOrAre(n) {
  if (n === 1) return 'is';
  else return 'are';
}

export function hasOrHave(n) {
  if (n === 1) return 'has';
  else return 'have';
}

export function capitalize(text) {
  if (!text.length) return text;

  return text[0].toUpperCase() + text.slice(1);
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function getBackgroundColor(parsedInfo) {
  if (parsedInfo.priority === 'high') {
    if (parsedInfo.type === 'generic') {
      if (parsedInfo.subType === 'message') return 'bg-purple-300';
      if (parsedInfo.subType === 'projectPlan') return 'bg-green-200';
      return 'bg-orange-200';
    }

    if (parsedInfo.type === 'promotion') {
      return 'bg-yellow-300';
    }

    return 'bg-red-300';
  }

  if (parsedInfo.isCurrentUser) {
    return 'bg-blue-100';
  }

  if (parsedInfo.priority === 'medium') {
    if (parsedInfo.type === 'generic') {
      if (parsedInfo.subType === 'message') return 'bg-purple-200';
      if (parsedInfo.subType === 'projectPlan') return 'bg-green-200';
      return 'bg-orange-200';
    }

    if (parsedInfo.type === 'promotion') {
      return 'bg-yellow-200';
    }

    return 'bg-red-200';
  }

  if (parsedInfo.type === 'generic') {
    if (parsedInfo.subType === 'message') return 'bg-purple-100';
    if (parsedInfo.subType === 'projectPlan') return 'bg-green-100';
    return 'bg-orange-100';
  }

  if (parsedInfo.type === 'promotion') {
    return 'bg-yellow-200';
  }

  return 'bg-gray-100';
}
