import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

export default function ProgressDateRange({ handleDateChange, startDate, endDate }) {
  return (
    <Box className="ml-10">
      <DatePicker
        value={startDate}
        onChange={(val) => handleDateChange(val, 'start')}
        disableFuture
        label="From"
        sx={{ marginRight: '10px' }}
        showDaysOutsideCurrentMonth
      />
      <DatePicker value={endDate} onChange={(val) => handleDateChange(val, 'end')} disableFuture label="To" showDaysOutsideCurrentMonth />
    </Box>
  );
}
