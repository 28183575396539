import { useEffect, useRef, useState } from 'react';

import { Bar, Brush, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';

import CustomizedLabel from './CustomizedLabel';
import CustomTooltip from './CustomTooltip';

/**
 * Formats aggregate data into bar chart format (task snapshots)
 * @param {import('../types').TaskSnapshotTrackedTimeAggregate[]} creationData
 * @param {number} teamSize
 * @returns {{week: string, 'Workflows Per Hour': number, 'Average Workflows Per Hour': number, 'Tests Per Hour': number, 'Average Tests Per Hour': number}[]} dataArray
 */
const formatChartData = (creationData, teamSize) => {
  const dataArray = [];

  // for each week, calculate the number of [workflows, tests] per tracked hour
  for (const week of creationData) {
    const hoursTrackedThisWeek = week.hours_tracked;
    const averageHoursTrackedThisWeek = week.group_total_hours_tracked / teamSize;

    // get qae velocities
    const qaeWeekData = {
      week: week.week,
      'Workflows Per Hour': Math.round((week.workflow_ids.length / hoursTrackedThisWeek) * 100) / 100,
      'Average Workflows Per Hour': Math.round((week.group_workflows_completed_this_week / teamSize / averageHoursTrackedThisWeek) * 100) / 100,
      'Tests Per Hour': Math.round((week.tests_completed_this_week / hoursTrackedThisWeek) * 100) / 100,
      'Average Tests Per Hour': Math.round((week.group_tests_completed_this_week / teamSize / averageHoursTrackedThisWeek) * 100) / 100,
    };

    // push weekly data into dataArray
    dataArray.push(qaeWeekData);
  }

  return dataArray;
};

/**
 *
 * @param {object} props
 * @param {import('../types').TaskSnapshotTrackedTimeAggregate[]} props.chartData
 * @param {import('../types').QATeam} props.team
 * @returns {import('react').JSX.Element}
 */
export default function CreationVelocityChart({ chartData, team }) {
  const parentRef = useRef(null);
  const [width, setWidth] = useState(775);

  const qaeData = formatChartData(chartData, team.members.filter((m) => !m.isLead).length);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setWidth(parentRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, []);

  return (
    <div className="flex items-center justify-center" ref={parentRef}>
      <ComposedChart
        width={width}
        height={400}
        data={qaeData}
        margin={{
          top: 20,
          right: 10,
          left: -10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="week" />
        <YAxis domain={[0, 'dataMax']} />
        <Tooltip content={<CustomTooltip />} />
        <Bar stackId="workflows" dataKey="Workflows Per Hour" fill={hslStringToRgbString(stringToColor('workflows'))} opacity={0.65} />
        <Bar stackId="tests" dataKey="Tests Per Hour" fill={hslStringToRgbString(stringToColor('tests'))} opacity={0.65} />
        <Line
          label={<CustomizedLabel />}
          dataKey="Average Workflows Per Hour"
          dot={false}
          stroke={hslStringToRgbString(stringToColor('workflows'))}
        />
        <Line label={<CustomizedLabel />} dataKey="Average Tests Per Hour" dot={false} stroke={hslStringToRgbString(stringToColor('tests'))} />
        <Legend
          payload={[
            { value: 'Workflows Per Hour', type: 'circle', color: hslStringToRgbString(stringToColor('workflows')) },
            { value: 'Tests Per Hour', type: 'circle', color: hslStringToRgbString(stringToColor('tests')) },
          ]}
        />
        <Brush dataKey={'week'} height={20} stroke={hslStringToRgbString(stringToColor('brush-component'))} />
      </ComposedChart>
    </div>
  );
}
