import { classNames } from '../../InvestigationBoard/helpers';
import dayjs from 'dayjs';

export default function ClientMetricsTable({ data, startDate, endDate, team }) {
  // Calculate the number of weeks between the start and end date (only inclusive of full weeks)
  const numWeeks = dayjs(endDate).startOf('week').diff(dayjs(startDate).startOf('week'), 'weeks');

  // Create an array of weeks, each containing the number of completed outline/creation/maintenance tasks for that week
  const weeks = [];
  for (let i = 0; i < numWeeks; i++) {
    const weekDate = dayjs(startDate).startOf('week').add(i, 'week');
    const weekTasks = data.TaskSnapshot.filter((task) => dayjs(task.completedAt).startOf('week').isSame(dayjs(weekDate), 'week'));
    const weekOutlines = weekTasks
      .filter((task) => task.type === 'outline')
      .map((task) => +task.details.workflow?.stepCount || 0)
      .reduce((a, b) => a + b, 0);

    const weekCreation = weekTasks
      .filter((task) => task.type === 'testCreation' && task.details.workflow.stepCount)
      .reduce((prev, curr) => prev + curr.details.workflow.stepCount, 0);

    const weekMaintenance = weekTasks
      .filter((task) => task.type === 'testMaintenance')
      .map((task) => +task.details.workflow.stepCount || 0)
      .reduce((a, b) => a + b, 0);

    weeks.push({
      weekStr: `${weekDate.format('MMM D')} - ${weekDate.add(6, 'days').format('MMM D')}`,
      outlines: weekOutlines,
      creation: weekCreation,
      maintenance: weekMaintenance,
    });
  }

  // Calculate the average number of tasks completed per week
  const avgOutlines = weeks.reduce((acc, week) => acc + week.outlines, 0) / numWeeks;
  const avgCreation = weeks.reduce((acc, week) => acc + week.creation, 0) / numWeeks;
  const avgMaintenance = weeks.reduce((acc, week) => acc + week.maintenance, 0) / numWeeks;

  // Add the delta between the weekly average and the actual number of tasks completed
  const weeksWithDeltas = weeks.map((week) => ({
    ...week,
    outlinesDelta: (week.outlines - avgOutlines).toFixed(2),
    creationDelta: (week.creation - avgCreation).toFixed(2),
    maintenanceDelta: (week.maintenance - avgMaintenance).toFixed(2),
  }));

  // Get metrics for this week
  const thisWeeksOutlineTestCount = team.thisWeeksCompletedOutlines.testCount;
  const thisWeeksProjectedOutlineTestCount = team.thisWeeksScheduledOutlines.testCount + thisWeeksOutlineTestCount;

  const thisWeeksCreationTestCount = team.thisWeeksCompletedCreation.testCount;
  const thisWeeksCurrentCreationDelta = (thisWeeksCreationTestCount - avgCreation).toFixed(2);
  const currentCreationAboveAvg = +thisWeeksCurrentCreationDelta > 0;
  const currentCreationBelowAvg = +thisWeeksCurrentCreationDelta < 0;
  const thisWeeksProjectedTestCount = team.thisWeeksScheduledCreation.testCount + thisWeeksCreationTestCount;
  const thisWeeksProjectedCreationDelta = (thisWeeksProjectedTestCount - avgCreation).toFixed(2);
  const projectedCreationAboveAvg = +thisWeeksProjectedCreationDelta > 0;
  const projectedCreationBelowAvg = +thisWeeksProjectedCreationDelta < 0;

  const thisWeeksMaintTestCount = team.thisWeeksCompletedMaint.testCount;
  const thisWeeksProjectedMaintTestCount = team.thisWeeksScheduledMaint.testCount + thisWeeksMaintTestCount;

  const tableHeaders = [
    `Tests outlined (${numWeeks} Week Avg: ${avgOutlines.toFixed(2)})`,
    `Tests created (${numWeeks} Week Avg: ${avgCreation.toFixed(2)})`,
    `Tests maintained (${numWeeks} Week Avg: ${avgMaintenance.toFixed(2)})`,
  ];

  return (
    <div className="col-span-12 p-2">
      <table className="divide-y divide-gray-300 w-full">
        <thead>
          <tr>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Week
            </th>
            {tableHeaders.map((header) => (
              <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900" key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {weeksWithDeltas.map(({ weekStr, outlines, outlinesDelta, creation, creationDelta, maintenance, maintenanceDelta }) => {
            const negativeOutlines = +outlinesDelta < 0;
            const negativeCreation = +creationDelta < 0;
            const negativeMaintenance = +maintenanceDelta < 0;
            return (
              <tr key={weekStr}>
                <td className={classNames('whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 w-10')}>{weekStr}</td>
                <td className={classNames('whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500 border-l border-gray-900/2')}>
                  <p>
                    {`${outlines} `}
                    <i className={negativeOutlines ? 'text-red-500' : 'text-green-500'}>{`${!negativeOutlines ? '+' : ''}${outlinesDelta}`}</i>
                  </p>
                </td>
                <td className={classNames('whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500 border-l border-gray-900/2')}>
                  <p>
                    {`${creation} `}
                    <i className={negativeCreation ? 'text-red-500' : 'text-green-500'}>{`${!negativeCreation ? '+' : ''}${creationDelta}`}</i>
                  </p>
                </td>
                <td className={classNames('whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500 border-l border-gray-900/2')}>
                  {' '}
                  <p>
                    {`${maintenance} `}
                    <i className={negativeMaintenance ? 'text-red-500' : 'text-green-500'}>{`${
                      !negativeMaintenance ? '+' : ''
                    }${maintenanceDelta}`}</i>
                  </p>
                </td>
              </tr>
            );
          })}
          <tr className="bg-gray-300  text-gray-800 border-gray-900/2">
            <td className="whitespace-nowrap py-4 px-3 w-10 flex text-start font-bold">This week</td>
            <td className=" px-3 py-4 border-l border-gray-900/2">
              <div className="w-full flex justify-center">
                <div className="flex flex-col w-max">
                  <p className="w-max">{`Tests outlined: ${thisWeeksOutlineTestCount}`}</p>
                  <p className="w-max">{`Projected outlining: ${thisWeeksProjectedOutlineTestCount}`}</p>
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 border-l border-gray-900/2">
              <div className="w-full flex justify-center">
                <div className="flex flex-col w-max">
                  <p>
                    {`Tests created: ${thisWeeksCreationTestCount}`}{' '}
                    <i className={currentCreationAboveAvg ? 'text-green-500' : currentCreationBelowAvg ? 'text-red-500' : ''}>
                      {`${currentCreationAboveAvg ? '+' : ''}${thisWeeksCurrentCreationDelta}`}
                    </i>
                  </p>
                  <p>
                    {`Projected creation: ${thisWeeksProjectedTestCount}`}{' '}
                    <i className={projectedCreationAboveAvg ? 'text-green-500' : projectedCreationBelowAvg ? 'text-red-500' : ''}>
                      {`${projectedCreationAboveAvg ? '+' : ''}${thisWeeksProjectedCreationDelta}`}
                    </i>
                  </p>
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 border-l border-gray-900/2">
              <div className="w-full flex justify-center">
                <div className="flex flex-col w-max">
                  <p>{`Tests maintained: ${thisWeeksMaintTestCount}`}</p>
                  <p>{`Projected maintenance: ${thisWeeksProjectedMaintTestCount}`}</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
