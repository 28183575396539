import _ from 'lodash';

import { computeMedian } from '../helpers.js';

/*
------------------------------------- CREATION COMPARISON TABLE HELPERS -------------------------------------
*/

/**
 * Computes QAE C&O metrics
 * @param {import('../types.js').QaeCreationMetrics} metrics
 * @param {import('../types.js').SumObject} sums
 * @returns {object} qaeMetrics
 */
export const computeQAEMetricsForCreationCompTable = (metrics, sums) => {
  const creationMetrics = metrics.qaeCreationByWeekAndType.filter((m) => m.type === 'testCreation');
  const maxTestPerWeek = _.maxBy(creationMetrics, (w) => parseInt(w.total_steps));
  const minTestsPerWeek = _.minBy(creationMetrics, (w) => parseInt(w.total_steps));

  const qaeMetrics = {
    'Workflows Created': sums.sumsByType.testCreation?.sumTasks ?? 0,
    'Tests Created': sums.sumsByType.testCreation?.sumTests ?? 0,
    'Outlining Tasks Completed': sums.sumsByType.outline?.sumTasks ?? 0,
    'Average Tests Created Per Week': (sums.sumsByType.testCreation?.sumTests / Object.keys(creationMetrics).length).toFixed(1) || 0,
    'Median Tests Created Per Week': computeMedian(creationMetrics.map((m) => parseInt(m.total_steps))) || 0,
    'Max Tests Per Week': parseInt(maxTestPerWeek?.total_steps) || 0,
    'Min Tests Per Week': parseInt(minTestsPerWeek?.total_steps) || 0,
  };

  return qaeMetrics;
};

/**
 * Computes team C&O metrics
 * @param {import('../types.js').TeamCreationMetrics} metrics
 * @param {import('../types.js').SumObject} sums
 * @returns {object} teamMetrics
 */
export const computeTeamMetricsForCreationCompTable = (metrics, sums) => {
  const teamLead = metrics.team.members.find((m) => m.isLead);

  const creationMetrics = metrics.teamCreationByWeekAndType.filter((m) => m.type === 'testCreation' && m.completed_by_qaw_id !== teamLead.qawId);
  const maxTestPerWeek = _.maxBy(creationMetrics, (w) => parseInt(w.total_steps));
  const minTestsPerWeek = _.minBy(creationMetrics, (w) => parseInt(w.total_steps));

  const teamMetrics = {
    'Workflows Created': sums.sumsByType.testCreation?.sumTasks ?? 0,
    'Tests Created': sums.sumsByType.testCreation?.sumTests ?? 0,
    'Outlining Tasks Completed': sums.sumsByType.outline?.sumTasks ?? 0,
    'Average Tests Created Per Week': (sums.sumsByType.testCreation?.sumTests / Object.keys(creationMetrics).length).toFixed(1) ?? 0,
    'Median Tests Created Per Week': computeMedian(creationMetrics.map((m) => parseInt(m.total_steps))) || 0,
    'Max Tests Per Week': parseInt(maxTestPerWeek?.total_steps) || 0,
    'Min Tests Per Week': parseInt(minTestsPerWeek?.total_steps) || 0,
  };

  return teamMetrics;
};

/**
 * Computes C&O comparison metrics
 * @param {import('../types.js').QaeCreationMetrics} qaeMetrics
 * @param {import('../types.js').TeamCreationMetrics} teamMetrics
 * @returns {object} comparisonMetrics
 */
export const computeComparisonMetricsForCreationCompTable = (qaeMetrics, teamMetrics) => {
  const comparisonMetrics = {
    // QAEs percentage of teams total workflows created
    'Workflows Created': `${((qaeMetrics['Workflows Created'] / teamMetrics['Workflows Created']) * 100).toFixed(1)}%`,
    // QAEs percentage of teams total tests created
    'Tests Created': `${((qaeMetrics['Tests Created'] / teamMetrics['Tests Created']) * 100).toFixed(1)}%`,
    // QAEs percentage of teams total outlining tasks completed
    'Outlining Tasks Completed': `${((qaeMetrics['Outlining Tasks Completed'] / teamMetrics['Outlining Tasks Completed']) * 100).toFixed(1)}%`,
    // Difference between QAEs average tests created per week and teams
    'Average Tests Created Per Week': (qaeMetrics['Average Tests Created Per Week'] - teamMetrics['Average Tests Created Per Week']).toFixed(1),
    // Difference between QAEs median tests created per week and teams
    'Median Tests Created Per Week': (qaeMetrics['Median Tests Created Per Week'] - teamMetrics['Median Tests Created Per Week']).toFixed(1),
    // Difference between QAEs max tests per week and teams
    'Max Tests Per Week': (qaeMetrics['Max Tests Per Week'] - teamMetrics['Max Tests Per Week']).toFixed(1),
    // Difference between QAEs min tests per week and teams
    'Min Tests Per Week': (qaeMetrics['Min Tests Per Week'] - teamMetrics['Min Tests Per Week']).toFixed(1),
  };

  return comparisonMetrics;
};

/**
 * Determines tailwind color class based on category and value
 * @param {string} category
 * @param {string | number} value - can represent a number or a percent
 * @param {object} team
 * @param {import('../types.js').SumObject} teamSums
 * @returns {string} tailwind_color_class
 */
export const determineColorForCreationCompTable = (category, value, team, teamSums) => {
  const nonLeadTeamMembers = team.members.filter((m) => !m.isLead);
  const teamSize = nonLeadTeamMembers.length;
  const individualContribution = parseFloat(value.toString());

  // if value is a percent category, determine contribution % based on team size and return color class based on tolerance
  if (category === 'Workflows Created' || category === 'Tests Created' || category === 'Outlining Tasks Completed') {
    // 15% tolerance
    const tolerance = 0.15;
    const expectedContribution = (1 / teamSize) * 100;

    if (individualContribution >= expectedContribution) {
      // good if equal or greater than even contribution to the team
      return 'text-green-400';
    } else if (individualContribution >= expectedContribution * (1 - tolerance)) {
      // "okay" if within 15% of your EXPECTED contribution to the team
      return 'text-yellow-400';
    } else {
      // bad if less than 15% of your EXPECTED contribution to the team
      return 'text-red-400';
    }
  }

  // if value is an average/median, determine positive/negative threshold and return color class based on tolerance
  if (category === 'Average Tests Created Per Week' || category === 'Median Tests Created Per Week') {
    // determine tolerance based on total team tests created
    const tolerance = teamSums.sumTests * 0.15;

    if (individualContribution >= 0) {
      // good if positive
      return 'text-green-400';
    } else if (individualContribution >= -tolerance) {
      // "okay" if within 15% of the team average/median
      return 'text-yellow-400';
    } else {
      // bad if less than 15% of the team average/median
      return 'text-red-400';
    }
  }

  // if max tests, return good if 0, bad if negative
  if (category === 'Max Tests Per Week') {
    if (individualContribution >= 0) {
      return 'text-green-400';
    } else {
      return 'text-red-400';
    }
  }

  // if min tests, return good if positive, bad if negative or 0 (it was you or a tie)
  if (category === 'Min Tests Per Week') {
    if (individualContribution > 0) {
      return 'text-green-400';
    } else {
      return 'text-red-400';
    }
  }

  return '';
};

/*
------------------------------------ INVESTIGATION COMPARISON TABLE HELPERS ------------------------------------
*/

/**
 * Computes QAE investigation metrics
 * @param {{qaeInvestigationData: object, qaeAssistedInvestigationData: object, qaeInvestigationDataGroupedByWeek: object, qaeAssistedInvestigationDataGroupedByWeek: object, aggregatedQAEInvestigationDataGroupedByWeek: object}} metrics
 * @returns {object} qaeMetrics
 */
export const computeQAEMetricsForInvestigationCompTable = (metrics) => {
  // min/max
  const maxSuitesPerWeek = _.max(_.values(metrics.qaeInvestigationDataGroupedByWeek).map((week) => week.length));
  const minSuitesPerWeek = _.min(_.values(metrics.qaeInvestigationDataGroupedByWeek).map((week) => week.length));

  // avg/median
  const medianSuitesPerWeek = computeMedian(_.values(metrics.qaeInvestigationDataGroupedByWeek).map((week) => week.length)) || 0;
  const averageSuitesPerWeek = _.mean(_.values(metrics.qaeInvestigationDataGroupedByWeek).map((week) => week.length)) || 0;
  const averageFailuresPerWeek = _.meanBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumFailures') || 0;
  const averageTestsPerWeek = _.meanBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumTests') || 0;
  const averageFailuresPassedOnFlakePerWeek = _.meanBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumPassedOnFlake') || 0;
  const averageFailuresPassedOnFixPerWeek = _.meanBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumPassedOnFix') || 0;

  const averageFailuresInvestigatedAsBugPerWeek =
    _.meanBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumInvestigatedAsBug') || 0;

  const averageFailuresInvestigatedAsMaintPerWeek =
    _.meanBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumInvestigatedAsMaint') || 0;

  const qaeMetrics = {
    'Failures Investigated': _.sumBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumFailures') || 0,
    'Average Failures Per Week': averageFailuresPerWeek.toFixed(1) || 0,
    'Test Steps Investigated': _.sumBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumTests') || 0,
    'Average Test Steps Per Week': averageTestsPerWeek.toFixed(1) || 0,
    'Failures Passed on Retry': _.sumBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumPassedOnFlake') || 0,
    'Average Failures Passed on Retry Per Week': averageFailuresPassedOnFlakePerWeek.toFixed(1) || 0,
    'Failures Passed with Fixes': _.sumBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumPassedOnFix') || 0,
    'Average Failures Passed with Fixes Per Week': averageFailuresPassedOnFixPerWeek.toFixed(1) || 0,
    'Failures Investigated as Bug': _.sumBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumInvestigatedAsBug') || 0,
    'Average Failures Investigated as Bug Per Week': averageFailuresInvestigatedAsBugPerWeek.toFixed(1) || 0,
    'Failures Investigated as Maintenance': _.sumBy(_.values(metrics.aggregatedQAEInvestigationDataGroupedByWeek), 'sumInvestigatedAsMaint') || 0,
    'Average Failures Investigated as Maintenance Per Week': averageFailuresInvestigatedAsMaintPerWeek.toFixed(1) || 0,
    'Suites Investigated': _.sumBy(_.values(metrics.qaeInvestigationDataGroupedByWeek), 'length') || 0,
    'Average Suites Per Week': averageSuitesPerWeek.toFixed(1) || 0,
    'Median Suites Per Week': medianSuitesPerWeek.toFixed(1) || 0,
    'Max Suites Per Week': maxSuitesPerWeek || 0,
    'Min Suites Per Week': minSuitesPerWeek || 0,
    'Suites Assisted': _.sumBy(_.values(metrics.qaeAssistedInvestigationDataGroupedByWeek), 'length') || 0,
  };

  return qaeMetrics;
};

/**
 * Computes team investigation metrics
 * @param {{teamInvestigationDataGroupedByWeek: object, teamAssistedInvestigationDataGroupedByWeek: object, teamAverageInvestigationDataGroupedByWeek: object, teamAverageAssistedInvestigationDataGroupedByWeek: object, aggregatedTeamInvestigationDataGroupedByWeek: object}} metrics
 * @param {object} targetTeam
 * @returns {object} teamMetrics
 */
export const computeTeamMetricsForInvestigationCompTable = (metrics, targetTeam) => {
  // do per qae breakdown
  const weeklyMetricsGroupedByQAE = {};
  for (const week of Object.keys(metrics.teamInvestigationDataGroupedByWeek)) {
    // get weekly metrics and aggregated metrics grouped by qae
    const weeklyMetrics = metrics.teamInvestigationDataGroupedByWeek[week];
    const groupedMetrics = _.groupBy(weeklyMetrics, 'taskClaimedBy');

    // get suites investigated this week per qae
    const suitesInvestigatedThisWeekPerQAE = _.mapValues(groupedMetrics, (tasks) => {
      return { suitesInvestigated: tasks.length };
    });

    // get averages for this week per qae
    weeklyMetricsGroupedByQAE[week] = suitesInvestigatedThisWeekPerQAE;
  }

  // totals
  const allSuiteCounts = _.flatMap(weeklyMetricsGroupedByQAE, (week) => _.values(week).map((qae) => qae.suitesInvestigated));
  const totalSuites = _.sum(allSuiteCounts);
  const totalSuiteCount = allSuiteCounts.length;

  // min/max
  const maxSuitesPerWeek = _.max(_.values(weeklyMetricsGroupedByQAE).map((week) => _.max(_.values(week).map((qae) => qae.suitesInvestigated))));
  const minSuitesPerWeek = _.min(_.values(weeklyMetricsGroupedByQAE).map((week) => _.min(_.values(week).map((qae) => qae.suitesInvestigated))));

  // avg/median
  const medianSuitesPerWeek = computeMedian(allSuiteCounts);
  const averageSuitesPerWeek = totalSuites / totalSuiteCount;

  const averageFailuresPerWeekPerQAE =
    _.meanBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumFailures') / targetTeam.members.length;

  const averageTestsPerWeekPerQAE = _.meanBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumTests') / targetTeam.members.length;

  const averageFailuresPassedOnFlakePerWeekPerQAE =
    _.meanBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumPassedOnFlake') / targetTeam.members.length;

  const averageFailuresPassedOnFixPerWeekPerQAE =
    _.meanBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumPassedOnFix') / targetTeam.members.length;

  const averageFailuresInvestigatedAsBugPerWeekPerQAE =
    _.meanBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumInvestigatedAsBug') / targetTeam.members.length;

  const averageFailuresInvestigatedAsMaintPerWeekPerQAE =
    _.meanBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumInvestigatedAsMaint') / targetTeam.members.length;

  const teamMetrics = {
    'Failures Investigated': _.sumBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumFailures') || 0,
    'Average Failures Per Week': averageFailuresPerWeekPerQAE.toFixed(1) || 0,
    'Test Steps Investigated': _.sumBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumTests') || 0,
    'Average Test Steps Per Week': averageTestsPerWeekPerQAE.toFixed(1) || 0,
    'Failures Passed on Retry': _.sumBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumPassedOnFlake') || 0,
    'Average Failures Passed on Retry Per Week': averageFailuresPassedOnFlakePerWeekPerQAE.toFixed(1) || 0,
    'Failures Passed with Fixes': _.sumBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumPassedOnFix') || 0,
    'Average Failures Passed with Fixes Per Week': averageFailuresPassedOnFixPerWeekPerQAE.toFixed(1) || 0,
    'Failures Investigated as Bug': _.sumBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumInvestigatedAsBug') || 0,
    'Average Failures Investigated as Bug Per Week': averageFailuresInvestigatedAsBugPerWeekPerQAE.toFixed(1) || 0,
    'Failures Investigated as Maintenance': _.sumBy(_.values(metrics.aggregatedTeamInvestigationDataGroupedByWeek), 'sumInvestigatedAsMaint') || 0,
    'Average Failures Investigated as Maintenance Per Week': averageFailuresInvestigatedAsMaintPerWeekPerQAE.toFixed(1) || 0,
    'Suites Investigated': _.sumBy(_.values(metrics.teamInvestigationDataGroupedByWeek), 'length') || 0,
    'Average Suites Per Week': averageSuitesPerWeek.toFixed(1) || 0,
    'Median Suites Per Week': medianSuitesPerWeek.toFixed(1) || 0,
    'Max Suites Per Week': maxSuitesPerWeek || 0,
    'Min Suites Per Week': minSuitesPerWeek || 0,
    'Suites Assisted': _.sumBy(_.values(metrics.teamAssistedInvestigationDataGroupedByWeek), 'length') || 0,
  };

  return teamMetrics;
};

/**
 * Computes comparison metrics
 * @param {*} qaeMetrics
 * @param {*} teamMetrics
 * @returns {object} comparisonMetrics
 */
export const computeComparisonMetricsForInvestigationCompTable = (qaeMetrics, teamMetrics) => {
  const getPercentage = (num, den) => {
    const result = num / den;
    return isNaN(result) ? '0%' : `${(result * 100).toFixed(1)}%`;
  };

  const getDiff = (qaeVal, teamVal) => {
    const result = qaeVal - teamVal;
    return isNaN(result) ? '0' : result.toFixed(1);
  };

  const comparisonMetrics = {
    // QAEs percentage of team's failures investigated
    'Failures Investigated': getPercentage(qaeMetrics['Failures Investigated'], teamMetrics['Failures Investigated']),

    // Difference between QAEs average failures per week and team's average failures per week
    'Average Failures Per Week': getDiff(qaeMetrics['Average Failures Per Week'], teamMetrics['Average Failures Per Week']),

    // QAEs percentage of team's test steps investigated
    'Test Steps Investigated': getPercentage(qaeMetrics['Test Steps Investigated'], teamMetrics['Test Steps Investigated']),

    // Difference between QAEs average test steps per week and team's average test steps per week
    'Average Test Steps Per Week': getDiff(qaeMetrics['Average Test Steps Per Week'], teamMetrics['Average Test Steps Per Week']),

    // QAEs percentage of team's failures passed on flake
    'Failures Passed on Retry': getPercentage(qaeMetrics['Failures Passed on Retry'], teamMetrics['Failures Passed on Retry']),

    // Difference between QAEs average failures passed on flake per week and team's average failures passed on flake per week
    'Average Failures Passed on Retry Per Week': getDiff(
      qaeMetrics['Average Failures Passed on Retry Per Week'],
      teamMetrics['Average Failures Passed on Retry Per Week'],
    ),

    // QAEs percentage of team's failures passed on fix
    'Failures Passed with Fixes': getPercentage(qaeMetrics['Failures Passed with Fixes'], teamMetrics['Failures Passed with Fixes']),

    // Difference between QAEs average failures passed on fix per week and team's average failures passed on fix per week
    'Average Failures Passed with Fixes Per Week': getDiff(
      qaeMetrics['Average Failures Passed with Fixes Per Week'],
      teamMetrics['Average Failures Passed with Fixes Per Week'],
    ),

    // QAEs percentage of team's failures investigated as bug
    'Failures Investigated as Bug': getPercentage(qaeMetrics['Failures Investigated as Bug'], teamMetrics['Failures Investigated as Bug']),

    // Difference between QAEs average failures investigated as bug per week and team's average failures investigated as bug per week
    'Average Failures Investigated as Bug Per Week': getDiff(
      qaeMetrics['Average Failures Investigated as Bug Per Week'],
      teamMetrics['Average Failures Investigated as Bug Per Week'],
    ),

    // QAEs percentage of team's failures investigated as maintenance
    'Failures Investigated as Maintenance': getPercentage(
      qaeMetrics['Failures Investigated as Maintenance'],
      teamMetrics['Failures Investigated as Maintenance'],
    ),

    // Difference between QAEs average failures investigated as maintenance per week and team's average failures investigated as maintenance per week
    'Average Failures Investigated as Maintenance Per Week': getDiff(
      qaeMetrics['Average Failures Investigated as Maintenance Per Week'],
      teamMetrics['Average Failures Investigated as Maintenance Per Week'],
    ),

    // QAEs percentage of team's suites investigated
    'Suites Investigated': getPercentage(qaeMetrics['Suites Investigated'], teamMetrics['Suites Investigated']),

    // Difference between QAEs average suites per week and team's average suites per week
    'Average Suites Per Week': getDiff(qaeMetrics['Average Suites Per Week'], teamMetrics['Average Suites Per Week']),

    // Difference between QAEs median suites per week and team's median suites per week
    'Median Suites Per Week': getDiff(qaeMetrics['Median Suites Per Week'], teamMetrics['Median Suites Per Week']),

    // Difference between QAEs max suites per week and team's max suites per week
    'Max Suites Per Week': getDiff(qaeMetrics['Max Suites Per Week'], teamMetrics['Max Suites Per Week']),

    // Difference between QAEs min suites per week and team's min suites per week
    'Min Suites Per Week': getDiff(qaeMetrics['Min Suites Per Week'], teamMetrics['Min Suites Per Week']),

    // QAEs percentage of team's suites assisted
    'Suites Assisted': getPercentage(qaeMetrics['Suites Assisted'], teamMetrics['Suites Assisted']),
  };

  return comparisonMetrics;
};

// TODO: Implement color determination
/**
 * Determines tailwind color class based on category and value
 * @param {*} category
 * @param {*} value
 * @param {*} team
 * @param {*} teamSums
 * @returns {string} tailwind_color_class
 */
export const determineColorForInvestigationCompTable = (category, value, team, teamSums) => {
  const nonLeadTeamMembers = team.members.filter((m) => !m.isLead);
  const teamSize = nonLeadTeamMembers.length;
  let individualContribution = parseFloat(value.toString());
  const isMaintenance = category.includes('Maintenance');

  if (category.includes('Average') || category.includes('Median')) {
    // handle opposite expectation for maintenance
    if (isMaintenance) {
      individualContribution = -individualContribution;
    }

    // determine tolerance based on total team tests created
    const tolerance = teamSums[category] * 0.15;

    if (individualContribution >= 0) {
      // good if positive
      return 'text-green-400';
    } else if (individualContribution >= -tolerance) {
      // "okay" if within 15% of the team average/median
      return 'text-yellow-400';
    } else {
      // bad if less than 15% of the team average/median
      return 'text-red-400';
    }
  } else if (category === 'Max Suites Per Week') {
    return individualContribution >= 0 ? 'text-green-400' : 'text-red-400';
  } else if (category === 'Min Suites Per Week') {
    return individualContribution > 0 ? 'text-green-400' : 'text-red-400';
  } else {
    // 15% tolerance
    const tolerance = 0.15;
    const expectedContribution = (1 / teamSize) * 100;

    if (isMaintenance) {
      if (individualContribution <= expectedContribution) {
        // good if equal or greater than even contribution to the team
        return 'text-green-400';
      } else if (individualContribution <= expectedContribution * (1 - tolerance)) {
        // "okay" if within 15% of your EXPECTED contribution to the team
        return 'text-yellow-400';
      } else {
        // bad if less than 15% of your EXPECTED contribution to the team
        return 'text-red-400';
      }
    } else {
      if (individualContribution >= expectedContribution) {
        // good if equal or greater than even contribution to the team
        return 'text-green-400';
      } else if (individualContribution >= expectedContribution * (1 - tolerance)) {
        // "okay" if within 15% of your EXPECTED contribution to the team
        return 'text-yellow-400';
      } else {
        // bad if less than 15% of your EXPECTED contribution to the team
        return 'text-red-400';
      }
    }
  }
};
