import { sendPostRequest } from '../../utils/network';
import Toast from '../Layout/Toast';
import { useState } from 'react';

export default function LoginPage() {
  const [toast, setToast] = useState(<></>);

  async function submitEmail(e) {
    e.preventDefault();
    let email = e.target.parentNode.parentNode.elements.email.value;
    localStorage.setItem('email', email);

    let result = await sendPostRequest('/sendlink', { email: email });

    let success = result.success;

    if (!success) {
      console.log(result);
    }

    let message = success ? result.data.message : result.error.message;
    let title = success ? 'Success' : 'Failed';
    setToast(<Toast title={title} message={message} key={new Date().toISOString()} isSuccess={success} />);
  }

  return (
    <>
      {toast}
      <div className="bg-white py-16 sm:py-24 lg:py-32 text-center">
        <div className="text-center">
          <div className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <h2 className="inline sm:block">Please provide your email address.</h2>
            <p className="inline sm:block">A log in link will be sent to you over slack.</p>
            <img
              className="bg-indigo-600 mx-auto mt-10 p-4"
              src="https://assets-global.website-files.com/6260298eca091b57c9cf188e/6260298eca091b8710cf18ea_logo.svg"
              alt="logo"
              width="200"
            />
          </div>

          <form className="mt-10 max-w-md mx-auto">
            <div className="flex gap-x-4">
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter your qawolf.com email here"
                defaultValue={localStorage.getItem('email')}
              />
              <button
                type="submit"
                onClick={async (e) => submitEmail(e)}
                className="flex-none rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
