import { SignedOut, SignUp } from '@clerk/clerk-react';

export default function ClerkDemoSignUp() {
  return (
    <SignedOut>
      <div className="min-h-screen p-4 flex items-center justify-center border border-blue-600 ">
        <SignUp forceRedirectUrl="/clerk-demo" signInUrl="/clerk-demo" />
      </div>
    </SignedOut>
  );
}
