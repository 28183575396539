import { SignedIn, SignedOut, SignIn, UserButton, UserProfile, useUser } from '@clerk/clerk-react';

export default function ClerkDemo() {
  const { user, isLoaded } = useUser();

  return (
    <>
      <SignedOut>
        <div className="min-h-screen p-4 flex items-center justify-center border border-blue-600 ">
          <SignIn forceRedirectUrl="/clerk-demo" signUpUrl="/clerk-demo-sign-up" />
        </div>
      </SignedOut>
      <SignedIn>
        {isLoaded && user && (
          <>
            <header className="w-full h-auto p-4 flex gap-x-4 items-center justify-end border border-b-1">
              <span>{user.fullName ?? user.emailAddresses[0].emailAddress}</span>
              <UserButton signInUrl="/clerk-demo" />
            </header>
            <div className="min-h-screen p-4 flex flex-col gap-y-10 items-center justify-center">
              {!user.firstName && 'Hello and welcome! Please ask your admin for assistance updating your profile.'}
              {user.firstName && (
                <p>
                  <UserProfile />
                </p>
              )}
            </div>
          </>
        )}
      </SignedIn>
    </>
  );
}
