import { useState } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, ResponsiveContainer, Cell, ReferenceLine } from 'recharts';
import ScatterPlotLegend from './ScatterPlotInvestigationLegend';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';

const TriangleShape = ({ cx, cy, fill, size, onClick }) => {
  const path = `M${cx},${cy - size} L${cx - size},${cy + size} L${cx + size},${cy + size} Z`;
  return <path d={path} fill={fill} onClick={onClick} style={{ cursor: 'pointer' }} />;
};

const CircleShape = ({ cx, cy, fill, r, onClick }) => (
  <circle cx={cx} cy={cy} r={r} fill={fill} onClick={onClick} style={{ cursor: 'pointer' }} />
);

const renderTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: '#f0f0f0',
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          zIndex: 1000, // Ensure tooltip is on top of other elements
        }}
      >
        <p style={{ margin: 0 }}>
          <span style={{ fontWeight: 'bold' }}>Customer: </span>
          {data.customerName}
        </p>
        <p style={{ margin: 0 }}>
          <span style={{ fontWeight: 'bold' }}>Trigger: </span>
          {data.triggerName}
        </p>
        <p style={{ margin: 0, fontWeight: 'bold' }}>{`${data.day} ${data.date} ${data.time}`}</p>
        <p style={{ margin: 0 }}>
          <span style={{ fontWeight: 'bold' }}>Total Time (hours): </span>
          {(data.actualTotalTime / 60).toFixed(2)}
        </p>
        <p style={{ margin: 0 }}>
          <span style={{ fontWeight: 'bold' }}>Failures: </span>
          {data.value}
        </p>
        {data.isSuperseded && (
          <p style={{ margin: 0 }}>
            <span style={{ fontWeight: 'bold' }}>Time w/ Superseded: </span>
            {(data.totalTimeInMinutes / 60).toFixed(2)}
          </p>
        )}
      </div>
    );
  }
  return null;
};

const ScatterPlot = ({ data }) => {
  const [selectedCustomers, setSelectedCustomers] = useState(new Set(data.map((d) => d.customerName)));

  const handleLegendClick = (customerName) => {
    const newSelectedCustomers = new Set(selectedCustomers);
    if (newSelectedCustomers.has(customerName)) {
      newSelectedCustomers.delete(customerName);
    } else {
      newSelectedCustomers.add(customerName);
    }
    setSelectedCustomers(newSelectedCustomers);
  };

  const filteredData = data.filter((d) => selectedCustomers.has(d.customerName));

  const domain = [0, 1440]; // Cap the y-axis at 24 hours (1440 minutes)
  const range = [0, 8500];

  // Get unique customer names
  const customerNames = [...new Set(data.map((d) => d.customerName))];

  // Format Axis
  const formatXAxisTicks = (tick) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[tick];
  };

  const formatYAxisTicks = (tick) => {
    return `${(tick / 60)}hrs`;
  };

  // Sort filteredData by start time within each day of the week
  filteredData.sort((a, b) => a.dayOfWeek - b.dayOfWeek || a.timeInMinutes - b.timeInMinutes);

  // Adjust x-coordinates to represent the time the suite started within the day
  const adjustedData = filteredData.map((d) => ({
    ...d,
    x: d.dayOfWeek + d.timeInMinutes / 1440,
  }));

  return (
    <div>
      <ScatterPlotLegend customerNames={customerNames} selectedCustomers={selectedCustomers} onLegendClick={handleLegendClick} />
      <ResponsiveContainer width="95%" height={900}>
        <ScatterChart margin={{ top: 10, right: 10, bottom: 40, left: 70 }}>
          <defs>
            <marker id="arrow" markerWidth="10" markerHeight="10" refX="10" refY="5" orient="auto" markerUnits="strokeWidth">
              <path d="M0,0 L0,10 L10,5 Z" fill="#000" />
            </marker>
          </defs>
          <XAxis
            type="number"
            dataKey="x"
            name="Day"
            domain={[0, 6]} // Representing Sunday to Saturday
            tickFormatter={formatXAxisTicks}
            ticks={[0, 1, 2, 3, 4, 5, 6]} // Sunday to Saturday
            minTickGap={5} // Adjust the minimum tick gap
            tick={{ dx: 10 }} // Move the tick labels to the right
            label={{
              value: 'Day of the Week',
              position: 'insideBottom',
              offset: -20,
              fontSize: 12,
              fontWeight: 'bold',
            }}
          />
          <YAxis
            type="number"
            dataKey="totalTimeInMinutes"
            name="Total Time (hours)"
            domain={domain}
            tickFormatter={formatYAxisTicks}
            ticks={[...Array(25).keys()].map((i) => i * 60)} // Every hour
            label={{
              value: 'Hours to Investigate',
              angle: -90,
              position: 'insideLeft',
              textAnchor: 'middle',
              fontSize: 13,
              fontWeight: 'bold',
              dx: -10,
            }}
          />
          <ZAxis type="number" dataKey="bubbleSize" range={range} domain={range} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} content={renderTooltip} wrapperStyle={{ zIndex: 1000 }} />

          {/* Add ReferenceLines for each day */}
          {[...Array(7).keys()].map((i) => (
            <ReferenceLine key={`x-${i}`} x={i} stroke="#ccc" strokeDasharray="3 3" />
          ))}

          {/* Add ReferenceLines for y-axis (every hour) */}
          {[...Array(24).keys()].map((i) => (
            <ReferenceLine key={`y-${i}`} y={(i + 1) * 60} stroke="#ccc" strokeDasharray="3 3" />
          ))}

          <Scatter
            data={adjustedData}
            fill="#8884d8"
            shape={(props) => {
              const { cx, cy, fill, payload } = props;
              const onClick = () => window.open(`/flow-explorer/${payload.id}`, '_blank');
              if (payload.isSuperseded) {
                return <TriangleShape cx={cx} cy={cy} fill={fill} size={payload.bubbleSize / 20} onClick={onClick} />;
              }
              return <CircleShape cx={cx} cy={cy} fill={fill} r={payload.bubbleSize / 20} onClick={onClick} />;
            }}
          >
            {adjustedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={hslStringToRgbString(stringToColor(entry.customerName))} />
            ))}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ScatterPlot;
