import { createContext, useContext, useState } from 'react';

const TaskFilterCriteriaContext = createContext(null);

export function TaskFilterCriteriaProvider({ children }) {
  const [filterCriteria, setFilterCriteria] = useState([]);

  const updateFilterCriteria = (criteria) => {
    setFilterCriteria(criteria);
  };

  return <TaskFilterCriteriaContext.Provider value={{ filterCriteria, updateFilterCriteria }}>{children}</TaskFilterCriteriaContext.Provider>;
}

export function useTaskFilterCriteria() {
  return useContext(TaskFilterCriteriaContext);
}
