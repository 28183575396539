import { sendPostRequest } from '../../utils/network';
import PageError from '../../utils/pageErrorHandler';

export async function getSuiteInfo({ params }) {
  let suiteId = params.suiteId;

  // handles case where someone tries to access '/flow-explorer' without a valid suiteId
  if (suiteId === ':suiteId') throw new PageError('Please use a valid Suite ID in the URL.', { statusText: 'Invalid Suite ID' });

  let resultData = await sendPostRequest(`/fetchSuiteData`, { suiteId });
  let suiteData = resultData?.data;

  if (suiteData) {
    return suiteData;
  }

  if (resultData?.error.response.status === 404) {
    throw new PageError('Please make sure you\'re using a valid Suite ID.', { statusText: 'Suite Not Found' });
  } else {
    throw Error("Sorry, something went wrong. Please notify #qa-dragons if you need help.");
  }
}

export async function getTeamSuiteInfo({ params }) {
  const urlParams = new URLSearchParams(window.location.search);
  let daysBack = urlParams.get('daysBack');
  if (!daysBack) {
    daysBack = 30;
    window.location.search += 'daysBack=30';
  } else {
    daysBack = Number(daysBack);
  }
  let teamId = params.teamId;

  let resultData = await sendPostRequest(`/fetchTeamSuiteData`, { teamId, daysBack });
  let suiteData = resultData?.data;

  if (suiteData) {
    return suiteData;
  }

  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}
