import moment from 'moment';
import { statusIndicators } from './CustomerTasks/ganttHelpers';
import dayjs from 'dayjs';
import { hasOrHave, isOrAre, plural, thisOrThese } from '../../InvestigationBoard/helpers';

let lastWeek = moment().add(-1, 'week').startOf('week').unix();

const HIGH = 'high';
const MEDIUM = 'medium';
// const LOW = 'low';

export function generateWarnings(team) {
  let warnings = [];

  // Maintenance reports that are overdue (HIGH severity)
  warnings = lateMaintenanceReportsWarning(team, warnings);

  // No maintenance reports that are due this week and has open maintenance reports, not including those that are overdue (HIGH severity)
  warnings = noMaintenanceScheduledWarning(team, warnings);

  // CRs that are overdue (HIGH severity)
  warnings = lateCRsWarning(team, warnings);

  // Generate warnings for teams that have nothing scheduled for this week (HIGH severity)
  warnings = generateNoCrsScheduledWarning(team, warnings);

  // CRs that are due this week that are not outlined and not overdue (HIGH severity)
  warnings = crsDueThisWeekNotOutlinedWarning(team, warnings);

  // CRs that are not outlined and due next week (MEDIUM severity)
  warnings = crsDueNextWeekNotOutlinedWarning(team, warnings);

  // Generate warnings for teams that have high priority tasks not scheduled for this week and have low or medium priority tasks scheduled for this week (MEDIUM severity)
  warnings = generateHighPriorityTasksNotScheduledWarning(team, warnings);

  // Generate warnings for CRs that have had their due dates updated 3 or more times (MEDIUM severity)
  warnings = generateCrsDueDateUpdatedWarning(team, warnings);

  // Generate warning for below average test creation scheduled for this week (HIGH severity)
  warnings = belowAverageThisWeekScheduledWarning(team, warnings);

  // Generate warning for below average test creation scheduled for next week (MEDIUM severity)
  warnings = belowAverageNextWeekScheduledWarning(team, warnings);

  return warnings;
}

function belowAverageThisWeekScheduledWarning(team, warnings) {
  const avgTestCreation = team.eightWeekAvgTestCreation;
  const thisWeeksCompletedCreation = team.thisWeeksCompletedCreation.testCount;
  const thisWeeksScheduledCreation = team.thisWeeksScheduledCreation.testCount;

  if (thisWeeksCompletedCreation + thisWeeksScheduledCreation < avgTestCreation) {
    const warning = {
      warningKey: `${team.id}_below_avg_test_creation_this_week`,
      title: `This week's completed and scheduled test creation is below the 8 week average of ${avgTestCreation} tests per week.`,
      detailsArr: team.scheduledCrsForThisWeek
        .filter((cr) => cr.type === 'testCreation')
        .map((cr) => {
          return {
            value: cr.name,
            key: cr.issue.id,
            name: cr.name,
          };
        }),
      severity: HIGH,
    };

    warnings.push({
      warning,
      type: 'belowAverageTestCreationThisWeek',
      severity: HIGH,
    });
  }

  return warnings;
}

function belowAverageNextWeekScheduledWarning(team, warnings) {
  const avgTestCreation = team.eightWeekAvgTestCreation;
  const nextWeeksScheduledCreation = team.nextWeeksScheduledCreation.testCount;

  if (nextWeeksScheduledCreation < avgTestCreation) {
    const warning = {
      warningKey: `${team.id}_below_avg_test_creation_next_week`,
      title: `Next week's scheduled test creation is below the 8 week average of ${avgTestCreation} tests per week.`,
      detailsArr: [],
      severity: MEDIUM,
    };

    warnings.push({
      warning,
      type: 'belowAverageTestCreationNextWeek',
      severity: MEDIUM,
    });
  }

  return warnings;
}

function generateNoCrsScheduledWarning(team, warnings) {
  const hasBudget = team.metrics.percentComplete <= 99;
  const hasCrsScheduledForThisWeek = team.scheduledCrsForThisWeek.length > 0;
  const hasIncompleteUnblockedCrs = team.incompleteUnblockedCrs.length > 0;

  if (!hasCrsScheduledForThisWeek && (hasBudget || hasIncompleteUnblockedCrs)) {
    const warning = {
      warningKey: `${team.id}_nothing_scheduled`,
      title: 'There are no CRs scheduled for this week. Consider adjusting one of the following CR due dates:',
      detailsArr: team.incompleteUnblockedCrs
        .sort((a, b) => dayjs(a.dueAt).diff(b.dueAt))
        .map((cr) => {
          return {
            value: `${cr.name}, due ${dayjs(cr.dueAt).format('MMM D')}.`,
            key: cr.issue.id,
            name: cr.name,
          };
        }),
      severity: MEDIUM,
    };
    warnings.push({
      warning,
      type: 'noCrsScheduled',
      severity: MEDIUM,
    });
  }

  return warnings;
}

function generateHighPriorityTasksNotScheduledWarning(team, warnings) {
  const lowOrMediumPriorityCrsForThisWeek = team.scheduledCrsForThisWeek.filter((cr) => cr.priority === 'low' || cr.priority === 'medium');
  const highPriorityFutureCrs = team.incompleteUnblockedCrs.filter(
    (cr) => cr.priority === 'high' && dayjs(cr.dueAt).isAfter(dayjs().startOf('day'), 'week'),
  );

  if (lowOrMediumPriorityCrsForThisWeek.length && highPriorityFutureCrs.length) {
    const warning = {
      warningKey: `${team.id}_high_priority_not_scheduled`,
      title: `There ${isOrAre(highPriorityFutureCrs.length)} ${highPriorityFutureCrs.length} high priority CR${plural(
        highPriorityFutureCrs.length,
      )} that ${isOrAre(
        highPriorityFutureCrs.length,
      )} not scheduled for this week. Consider adjusting one of the following high priority CR due dates:`,
      detailsArr: highPriorityFutureCrs.map((cr) => {
        return {
          value: cr.name,
          key: cr.issue.id,
          name: cr.name,
        };
      }),
      severity: MEDIUM,
    };
    warnings.push({
      warning,
      type: 'highPriorityNotScheduled',
      severity: MEDIUM,
    });
  }

  return warnings;
}

function crsDueThisWeekNotOutlinedWarning(team, warnings) {
  let crsNotOutlined = team.rawCrs.filter(
    (request) =>
      request.childTasks.find((t) => t.type === 'outline' && t.status !== 'done' && dayjs(request.dueAt).isSame(dayjs().startOf('week'), 'week')) &&
      request.status !== 'done',
  );

  if (crsNotOutlined.length) {
    let details = crsNotOutlined.map((request) => {
      return {
        value: `${statusIndicators(request.status)} ${request.name} was not outlined yet, due ${dayjs(request.dueAt).format('MMM D')}.`,
        key: request.issue.id,
        name: request.name,
      };
    });

    let overDueNotOutlinedThisWeekSeverity = HIGH;
    let warning = {
      warningKey: `${team.id}_${lastWeek}_crs_not_outlined_due_this_week`,
      title: `There ${isOrAre(crsNotOutlined.length)} ${crsNotOutlined.length} coverage request${plural(crsNotOutlined.length)} that ${hasOrHave(
        crsNotOutlined.length,
      )} not been outlined and ${isOrAre(crsNotOutlined.length)} due this week.`,
      detailsArr: details,
      severity: overDueNotOutlinedThisWeekSeverity,
    };

    warnings.push({
      warning,
      type: 'crsDueThisWeekNotOutlined',
      severity: overDueNotOutlinedThisWeekSeverity,
    });
  }

  return warnings;
}

function crsDueNextWeekNotOutlinedWarning(team, warnings) {
  const crsNotOutlined = team.rawCrs.filter(
    (request) =>
      request.childTasks.find(
        (t) => t.type === 'outline' && t.status !== 'done' && dayjs(request.dueAt).isSame(dayjs().add(1, 'week').startOf('week'), 'week'),
      ) && request.status !== 'done',
  );

  if (crsNotOutlined.length) {
    let details = crsNotOutlined.map((request) => {
      return {
        value: `${statusIndicators(request.status)} ${request.name} was not outlined yet, due ${dayjs(request.dueAt).format('MMM D')}.`,
        key: request.issue.id,
        name: request.name,
      };
    });

    if (crsNotOutlined.length) {
      let warning = {
        warningKey: `${team.id}_${lastWeek}_crs_not_outlined_due_next_week`,
        title: `There ${isOrAre(crsNotOutlined.length)} ${crsNotOutlined.length} coverage request${plural(crsNotOutlined.length)} that ${hasOrHave(
          crsNotOutlined.length,
        )} not been outlined and ${isOrAre(crsNotOutlined.length)} due next week.`,
        detailsArr: details,
        severity: MEDIUM,
      };

      warnings.push({
        warning,
        type: 'crsDueNextWeekNotOutlined',
        severity: MEDIUM,
      });
    }
  }

  return warnings;
}

function lateCRsWarning(team, warnings) {
  let lateCRWarningSeverity = HIGH;

  let crsLate = team.rawCrs.filter(
    (request) => request.status !== 'done' && dayjs(request.dueAt).isBefore(dayjs().startOf('day')) && request.childTasks.length !== 0,
  );

  if (crsLate.length) {
    let warning = {
      warningKey: `${team.id}_${lastWeek}_crs_late`,
      title: `There ${isOrAre(crsLate.length)} ${crsLate.length} coverage request${plural(crsLate.length)} that ${isOrAre(crsLate.length)} overdue.`,
      detailsArr: crsLate.map((request) => {
        let blockedItems = request.childTasks.filter((t) => t.status === 'blocked').length;

        let notDoneItems = request.childTasks.filter((t) => t.status !== 'done').length;

        return {
          value:
            `${statusIndicators(request.status)} ${request.name} with ${blockedItems} blocked items` +
            ` and ${notDoneItems} incomplete items, due ${dayjs(request.dueAt).format('MMM D')}.`,
          key: request.issue.id,
          name: request.name,
        };
      }),
      severity: lateCRWarningSeverity,
    };

    warnings.push({
      warning,
      type: 'crOverdue',
      severity: lateCRWarningSeverity,
    });
  }

  return warnings;
}

function lateMaintenanceReportsWarning(team, warnings) {
  let severity = HIGH;

  let reportsLate = team.rawMaintenance.filter((r) => r.status !== 'done' && dayjs(r.dueAt).isBefore(dayjs().startOf('day')));

  if (reportsLate.length) {
    let warning = {
      warningKey: `${team.id}_${lastWeek}_maint_late`,
      title: `There ${isOrAre(reportsLate.length)} ${reportsLate.length} maintenance report${plural(reportsLate.length)} that ${isOrAre(
        reportsLate.length,
      )} overdue.`,
      detailsArr: reportsLate.map((r) => {
        let blockedItems = r.childTasks.filter((t) => t.status === 'blocked').length;

        let notDoneItems = r.childTasks.filter((t) => t.status !== 'done').length;

        return {
          value: `${statusIndicators(r.status)} ${r.issue.name} with ${blockedItems} blocked item${plural(
            blockedItems,
          )} and ${notDoneItems} incomplete item${plural(notDoneItems)}, due ${dayjs(r.dueAt).format('MMM D')}.`,
          key: r.issue.id,
          name: r.issue.name,
        };
      }),
      severity,
    };

    warnings.push({
      warning,
      type: 'maintenanceOverdue',
      severity: severity,
    });
  }

  return warnings;
}

function noMaintenanceScheduledWarning(team, warnings) {
  const severity = HIGH;
  const openMaint = team.rawMaintenance.filter(
    (r) =>
      // Not done
      r.status !== 'done' &&
      // At least partially unblocked
      r.childTasks.some((t) => t.status !== 'blocked') &&
      // Has a due date
      r.dueAt &&
      // Not overdue
      dayjs(r.dueAt).isAfter(dayjs().startOf('day')),
  );

  const scheduledForThisWeek = openMaint.filter((r) => dayjs(r.dueAt).isSame(dayjs().startOf('week'), 'week'));
  const notScheduledForThisWeek = openMaint.filter((r) => !dayjs(r.dueAt).isSame(dayjs().startOf('week'), 'week'));

  if (notScheduledForThisWeek.length && !scheduledForThisWeek.length) {
    let warning = {
      warningKey: `${team.id}_${lastWeek}_no_maint_scheduled`,
      title: `There is no maintenance scheduled for this week and there ${isOrAre(notScheduledForThisWeek.length)} ${
        notScheduledForThisWeek.length
      } maintenance report${plural(notScheduledForThisWeek.length)} that ${isOrAre(notScheduledForThisWeek.length)} scheduled further out.`,
      detailsArr: notScheduledForThisWeek.map((r) => {
        let blockedItems = r.childTasks.filter((t) => t.status === 'blocked').length;

        let notDoneItems = r.childTasks.filter((t) => t.status !== 'done').length;

        return {
          value: `${statusIndicators(r.status)} ${r.issue.name} with ${blockedItems} blocked item${plural(
            blockedItems,
          )} and ${notDoneItems} incomplete item${plural(notDoneItems)}, due ${dayjs(r.dueAt).format('MMM D')}.`,
          key: r.issue.id,
          name: r.issue.name,
        };
      }),
      severity,
    };

    warnings.push({
      warning,
      type: 'noMaintenanceScheduledThisWeek',
      severity: severity,
    });
  }

  return warnings;
}

function generateCrsDueDateUpdatedWarning(team, warnings) {
  const crsWithUpdatedDueDates = team.crsWithUpdatedDueDates.filter(
    (cr) => cr.dueDateUpdateCount >= 3 && cr.status !== 'archived' && cr.status === 'inProgress',
  );

  if (crsWithUpdatedDueDates.length) {
    let warning = {
      warningKey: `${team.id}_${lastWeek}_multiple_due_date_updates`,
      title: `${thisOrThese(crsWithUpdatedDueDates.length, true)} CR${plural(crsWithUpdatedDueDates.length)} ${hasOrHave(
        crsWithUpdatedDueDates.length,
      )} had 3 or more due date changes.`,
      detailsArr: crsWithUpdatedDueDates.map((cr) => {
        return {
          value: `${cr.issueName} - Rescheduled ${cr.dueDateUpdateCount} time${plural(cr.dueDateUpdateCount)}.`,
          key: cr.issueId,
          name: cr.issueName,
        };
      }),
      severity: MEDIUM,
    };

    warnings.push({
      warning,
      type: 'noMaintenanceScheduledThisWeek',
      severity: MEDIUM,
    });
  }

  return warnings;
}
