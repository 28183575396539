import { Box, Typography, Grid } from '@mui/material';

const BubbleChartLegend = () => {
  const legendItems = [
    { color: '#018749', text: 'All Failures investigated' },
    { color: '#AFE1AF', text: 'Significant Decrease (> 75)' },
    { color: '#FBEC5D', text: 'Moderate Decrease (> 15)' },
    { color: '#E49B0F', text: 'Slight Decrease (< 15)' },
    { color: '#FAA0A0', text: 'Slight Increase (< 15)' },
    { color: '#D70040', text: 'Moderate Increase (> 15)' },
    { color: '#811331', text: 'Significant Increase (> 75)' },
  ];

  return (
    <Box className="justify-self-center">
      <Grid className="justify-center" container spacing={1}>
        {legendItems.map((item, index) => (
          <div key={index}>
            <Box sx={{ width: 130, height: 20, backgroundColor: item.color, mr: 6, marginTop: 2 }}></Box>
            <Typography fontSize={12}>{item.text}</Typography>
          </div>
        ))}
      </Grid>
    </Box>
  );
};

export default BubbleChartLegend;
