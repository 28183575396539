import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { classNames } from '../../InvestigationBoard/helpers';

export default function ClientSummaryBanner({ team }) {
  const thisWeekTime = moment().startOf('week').unix();

  const incompleteMaintenance = team.rawMaintenance.filter((x) => x.status !== 'done' && x.status !== 'ignore');
  const allMaintenanceTasks = incompleteMaintenance.length;
  const maintenanceThisWeek = team.rawMaintenance.filter((x) => moment(x.dueAt).startOf('week').unix() === thisWeekTime).length;

  const incompleteCrs = team.rawCrs.filter((x) => x.status !== 'done' && x.status !== 'ignore');
  const allCrTasks = incompleteCrs.length;
  const crsThisWeek = team.rawCrs.filter((x) => moment(x.dueAt).startOf('week').unix() === thisWeekTime).length;

  return (
    <div className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-7 md:divide-x md:divide-y-0">
      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Age in Weeks</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="overflow-hidden flex items-baseline text-2xl font-semibold text-gray-900">
            {team.ageInWeeks}
            <span className="truncate ml-2 text-sm font-medium text-gray-500">{team.status}</span>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Pilot Converted</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">{team.pilotConverted ? 'Yes' : 'No'}</div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Weekly Points</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">
            {team.weeklyPoints}
            <span className="ml-2 text-sm font-medium text-gray-500">
              {team.stepLimit} tests {team.oldPricing ? '(old pricing)' : ''}
            </span>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Open CRs</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">
            {allCrTasks}
            <span className="ml-2 text-sm font-medium text-gray-500">{crsThisWeek} due this week</span>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Maintenance</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">
            {allMaintenanceTasks}
            <span className="ml-2 text-sm font-medium text-gray-500">{maintenanceThisWeek} due this week</span>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Open Bugs</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">
            {team.bugs.openReports}
            <span className="ml-2 text-sm font-medium text-gray-500">{team.bugs.affectedWfs} WFs</span>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        <div className="text-2xl font-normal text-gray-900">Flakiness</div>
        <div className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-gray-900">
            {team?.flakiness?.flakeScore || team?.flakiness?.flakeScore === 0 ? (
              <>
                {(team?.flakiness?.flakeScore * 100).toFixed(0)}
                <span className="ml-2 text-sm font-medium text-gray-500">from {`${(team?.flakiness?.lastWeek * 100).toFixed(0)}%`}</span>
              </>
            ) : (
              `No Data`
            )}
          </div>

          {team?.flakiness?.change && (
            <div
              className={classNames(
                team?.flakiness?.change < 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
              )}
            >
              {team?.flakiness?.change > 0 ? (
                <ArrowUpIcon className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
              ) : (
                <ArrowDownIcon className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
              )}
              {(team?.flakiness?.change * 100).toFixed(0)}%
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
