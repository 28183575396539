import { Box, Button, Drawer, ListItem, ListItemText, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';

export function Inner({ children }) {
  return <Box sx={{ width: '30vw', display: 'flex', flexDirection: 'column', p: '1em 2em' }}>{children}</Box>;
}

export function Header({ children }) {
  return <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>{children}</Box>;
}

export function Title({ text }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingLeft: '1em' }}>
      <Typography variant="h5">{text}</Typography>
    </Box>
  );
}

export function Content({ children, ...props }) {
  return (
    <Box {...props} sx={{ width: '100%', display: 'flex', flexDirection: 'column', ...props.sx }}>
      {children}
    </Box>
  );
}

export function SubDrawerBtn({ text, ...props }) {
  return (
    <Button
      {...props}
      fullWidth
      sx={{
        justifyContent: 'left',
        pl: '4em',
        color: 'GrayText',
        '&:hover': {
          bgcolor: 'white',
        },
        ...props.sx,
      }}
      size="large"
      disableRipple
    >
      {text}
    </Button>
  );
}

export function StyledDrawer({ children, ...props }) {
  return (
    <Drawer {...props} PaperProps={{ sx: { backgroundColor: '#f1f1f1', ...props.sx } }}>
      {children}
    </Drawer>
  );
}

// Customized DatePicker component that opens when clicking the input field
export function NoInputDatePicker({ ...props }) {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        textField: {
          ...props,
          sx: { input: { cursor: 'pointer' }, ...props.sx },
          InputProps: {
            onClick: () => setOpen(true),
          },
        },
      }}
    />
  );
}

export function CardItem({ name, value, secondarySx = {} }) {
  return (
    <ListItem sx={{ pt: 0, pb: 0 }}>
      <ListItemText primary={name} primaryTypographyProps={{ sx: { fontSize: 14 } }} />
      <Box sx={secondarySx}>
        <ListItemText secondary={value} />
      </Box>
    </ListItem>
  );
}

export function ScrollBox({ children, ...props }) {
  return (
    <Box sx={{ maxHeight: '100%', ...props.sx }}>
      <Box
        {...props}
        sx={{
          height: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          ...props.sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
