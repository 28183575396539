import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { Box, Button, Fade, Popper } from '@mui/material';
import { DateFilters } from '../../UtilityComponents';
import AddCardModal from './AddCardModal';
import FetchPendingIndicator from './FetchPendingIndicator';

import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import { classNames } from '../../InvestigationBoard/helpers';
import usePollQueryStatus from '../../../hooks/usePollQueryStatus';

/**
 *
 * @param {object} props
 * @param {import('../../MetricsExplorer/types').QAEFromLocalStorage[]} props.allUsers
 * @param {import('../../MetricsExplorer/types').QATeamFromLocalStorage[]} props.allTeams
 * @param {{gte: string, lte: string}} props.dates
 * @param {import('react').Dispatch<import('react').SetStateAction<{gte: string, lte: string}>>} props.setDates
 * @param {import('../types').ActiveCard[]} props.activeCards
 * @param {import('react').Dispatch<import('react').SetStateAction<import('../types').ActiveCard[]>>} props.setActiveCards
 * @returns {import('react').JSX.Element}
 */
export default function MetricsPlaygroundFilters({ allUsers, allTeams, dates, setDates, activeCards, setActiveCards }) {
  const allPacks = useMemo(
    () =>
      _.sortBy(
        allUsers.filter((user) => user.isManager),
        ['name'],
      ),
    [],
  );

  const [showRemoveSetPopper, setShowRemoveSetPopper] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState(/** @type {HTMLElement | null} */ (null));
  const [selectedCard, setSelectedCard] = useState(/** @type {import('../types').ActiveCard | null} */ (null));
  const [allSettled, setAllSettled] = useState(false);

  // monitor query states to set loading indicator
  const queryStates = allPacks.flatMap((pack) => [
    usePollQueryStatus(['impactPlaygroundPackInvestigation', pack.teamId, dates.gte, dates.lte]),
    usePollQueryStatus(['impactPlaygroundPackCreation', pack.teamId, dates.gte, dates.lte]),
  ]);

  useEffect(() => {
    if (queryStates.every((queryState) => queryState === 'success')) {
      setAllSettled(true);
    } else {
      setAllSettled(false);
    }
  }, [queryStates]);

  /**
   *
   * @param {import('react').MouseEvent<HTMLElement>} event
   * @param {import('../types').ActiveCard} card
   */
  const handleShowRemoveSetPopper = (event, card) => {
    setPopperAnchor(event.currentTarget);
    setShowRemoveSetPopper((previousShow) => !previousShow);
    setSelectedCard(card);
  };

  /**
   * @param {import('../types').ActiveCard} card
   */
  const handleRemoveSingleCard = (card) => {
    setActiveCards((prevActiveCards) => prevActiveCards.filter((c) => c.cardName !== card.cardName));
    setShowRemoveSetPopper(false);
    setPopperAnchor(null);
  };

  /**
   *
   * @param {import('../types').ActiveCard} card
   */
  const handleRemoveEntireSet = (card) => {
    setActiveCards((prevActiveCards) => prevActiveCards.filter((c) => c.cardSetId !== card.cardSetId));
    setShowRemoveSetPopper(false);
    setPopperAnchor(null);
  };

  return (
    <div className="bg-white">
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Margin and General Comparison Filters
        </h2>
        <div className="bg-white py-4 min-w-full">
          <div className=" flex max-w-8xl pb-2 items-center justify-between px-4 sm:px-8 lg:px-15">
            <div className="spacer flex-1">{!allSettled && <FetchPendingIndicator customStyles={{ width: '100%', height: 40 }} />}</div>
            <div className="hidden sm:block">
              <DateFilters
                values={{ gte: dates.gte, lte: dates.lte }}
                updateFn={(dateParam, newDate) => {
                  setDates((prevDates) => ({ ...prevDates, [dateParam]: newDate.format('YYYY-MM-DD') }));
                }}
              />
            </div>
          </div>
          <div className="bg-gray-100">
            <div className="mx-auto max-w-8xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
              <h3 className="text-sm font-medium text-gray-500">Active Cards</h3>

              <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

              <div className="mt-2 sm:ml-4 sm:mt-0">
                <div className="-m-1 flex flex-wrap items-center">
                  {activeCards.map((card) => (
                    <span
                      key={card.cardName}
                      className={classNames(
                        card.isParent ? 'border-yellow-500 animate-pulse' : 'border-gray-500',
                        'm-1 inline-flex items-center rounded-full border bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900',
                      )}
                      style={{ backgroundColor: hslStringToRgbString(stringToColor(card.cardSetId), 0.2) }}
                    >
                      <span>{card.cardName}</span>
                      <button
                        type="button"
                        onClick={
                          card.isParent && activeCards.filter((c) => c.cardSetId === card.cardSetId).length > 1
                            ? (event) => handleShowRemoveSetPopper(event, card)
                            : () => handleRemoveSingleCard(card)
                        }
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-500 hover:bg-gray-200 hover:text-gray-500"
                      >
                        <span className="sr-only">Remove card: {card.cardName}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              </div>
              {activeCards.length > 0 && <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />}
              <AddCardModal activeCards={activeCards} setActiveCards={setActiveCards} allUsers={allUsers} allTeams={allTeams} />
            </div>
          </div>
        </div>
        <Popper open={showRemoveSetPopper} anchorEl={popperAnchor} placement="top-start" transition sx={{ zIndex: 1 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={{ enter: 300 }}>
              <Box>
                <div className="p-2 bg-white border rounded-sm shadow-md">
                  <div className="flex flex-col">
                    <p className="font-medium mb-1">Remove all cards from this set?</p>
                    <span className="text-sm italic">
                      This will remove all cards with{' '}
                      <span className="font-bold" style={{ color: hslStringToRgbString(stringToColor(selectedCard.cardSetId), 0.8) }}>
                        this color
                      </span>{' '}
                      from the current view.
                    </span>
                  </div>
                  <span className="flex">
                    <Button onClick={() => handleRemoveEntireSet(selectedCard)}>Yes, remove all</Button>
                    <Button onClick={() => handleRemoveSingleCard(selectedCard)}>No, remove one</Button>
                    <Button onClick={() => setShowRemoveSetPopper(false)}>Cancel</Button>
                  </span>
                </div>
              </Box>
            </Fade>
          )}
        </Popper>
      </section>
    </div>
  );
}
