import { classNames } from '../../InvestigationBoard/helpers';

export default function UtilizationWidget({ team }) {
  const statuses = {
    'On Track': 'text-green-700 bg-green-50 ring-green-600/20',
    'At Budget': 'text-green-700 bg-green-50 ring-green-600/20',
    Behind: 'text-red-700 bg-red-50 ring-red-600/10',
    'Over Budget': 'text-red-700 bg-red-50 ring-red-600/10',
  };

  return (
    <div className="col-span-4 p-2 w-full">
      <h3 className="text-center font-medium">Onboarding Progress and Budget Utilization</h3>
      <ul role="list" className="mt-2 flex gap-x-6">
        <li key="outlines" className="overflow-hidden rounded-xl border border-gray-200">
          <dl className="divide-y divide-gray-100 px-6 text-sm leading-6">
            <div className="flex justify-center gap-x-4 py-3">
              <div className="flex">
                <div className="font-medium text-gray-900" title={`${team.outlines.current}/${team.stepLimit}`}>
                  Outlines: {(100 * (team.outlines.current / team.stepLimit)).toFixed(0)}%
                </div>
                {!team.outlines.overBudget && (
                  <div className={classNames(statuses[team.outlines.status], 'ml-2 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset')}>
                    {team.outlines.status}
                  </div>
                )}
                {team.outlines.overBudget && (
                  <div className={classNames(statuses['Over Budget'], 'ml-2 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset')}>
                    Over Budget
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-center gap-x-4 text-gray-700">
              <div>
                <table className="divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                        Expected
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Current
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Delta
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white text-center">
                    <tr>
                      <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">{team.outlines.expected.toFixed(0)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{team.outlines.current.toFixed(0)}</td>
                      <td className="whitespace-nowrap px-3 py-4 font-semibold stext-sm text-gray-900">{team.outlines.delta.toFixed(0)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </dl>
        </li>

        <li key="tests" className="overflow-hidden rounded-xl border border-gray-200">
          <dl className="divide-y divide-gray-100 px-6 text-sm leading-6">
            <div className="flex justify-center gap-x-4 py-3">
              <div className="flex">
                <div className="font-medium text-gray-900" title={`${team.tests.current}/${team.stepLimit}`}>
                  Tests: {(100 * (team.tests.current / team.stepLimit)).toFixed(0)}%
                </div>
                {!team.tests.overBudget && (
                  <div className={classNames(statuses[team.tests.status], 'ml-2 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset')}>
                    {team.tests.status}
                  </div>
                )}
                {team.tests.overBudget && (
                  <div className={classNames(statuses['Over Budget'], 'ml-2 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset')}>
                    Over Budget
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-center gap-x-4 text-gray-700">
              <div>
                <table className="divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                        Expected
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Current
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Delta
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white text-center">
                    <tr>
                      <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">{team.tests.expected.toFixed(0)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{team.tests.current.toFixed(0)}</td>
                      <td className="whitespace-nowrap px-3 py-4 font-semibold stext-sm text-gray-900">{team.tests.delta.toFixed(0)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </dl>
        </li>
      </ul>
    </div>
  );
}
