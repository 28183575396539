import _ from 'lodash';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useState } from 'react';
import { classNames } from '../../InvestigationBoard/helpers';
import { buildPackChartDataFromMetrics, buildQAEChartDataFromMetrics, buildTeamChartDataFromMetrics } from '../helpers';

export default function WeeklyCreationChart({ creationMetrics, comparisonMetrics = null, isTeam = false, isPack = false }) {
  const storageMap = {
    qae: 'creationChartVisibility',
    team: 'teamCreationChartVisibility',
    pack: 'packCreationChartVisibility',
  };

  const [opacity, setOpacity] = useState({
    'Creation - Tests': 1,
    'Creation - Workflows': 1,
    'Outlining - Tests': 1,
    'Outlining - Tasks': 1,
    'Team Creation - Tests': 1,
    'Team Creation - Workflows': 1,
    'Team Outlining - Tests': 1,
    'Team Outlining - Tasks': 1,
    'Pack Creation - Tests': 1,
    'Pack Creation - Workflows': 1,
    'Pack Outlining - Tests': 1,
    'Pack Outlining - Tasks': 1,
    'Team Average Creation - Tests': 1,
    'Team Average Outlining - Tests': 1,
    'Team Average Outlining - Tasks': 1,
    'Team Average Creation - Workflows': 1,
    'Pack Average Creation - Tests': 1,
    'Pack Average Creation - Workflows': 1,
    'Pack Average Outlining - Tests': 1,
    'Pack Average Outlining - Tasks': 1,
  });

  const [visibility, setVisibility] = useState(
    JSON.parse(sessionStorage.getItem(isPack ? storageMap.pack : isTeam ? storageMap.team : storageMap.qae)) ?? {
      'Creation - Tests': !isTeam && !isPack,
      'Creation - Workflows': !isTeam && !isPack,
      'Outlining - Tests': true,
      'Outlining - Tasks': true,
      'Team Creation - Tests': true,
      'Team Creation - Workflows': true,
      'Team Outlining - Tests': true,
      'Team Outlining - Tasks': true,
      'Pack Creation - Tests': true,
      'Pack Creation - Workflows': true,
      'Pack Outlining - Tests': true,
      'Pack Outlining - Tasks': true,
      'Team Average Creation - Tests': true,
      'Team Average Outlining - Tests': true,
      'Team Average Outlining - Tasks': true,
      'Team Average Creation - Workflows': true,
      'Pack Average Creation - Tests': true,
      'Pack Average Creation - Workflows': true,
      'Pack Average Outlining - Tests': true,
      'Pack Average Outlining - Tasks': true,
    },
  );

  /* LOCAL HELPERS */

  const addWeeklyAveragesToChartData = (chartData) => {
    const d = isTeam ? creationMetrics?.team.members.length : isPack ? creationMetrics.packTeams.length : creationMetrics?.qae.team.members.length;
    for (const week of chartData) {
      week[`Team Average Outlining - Tasks`] = week['Outlining - Tasks'] / d || 0;

      week[`Team Average Outlining - Tests`] = week['Outlining - Tests'] / d || 0;

      week[`${isTeam ? 'Team' : 'Pack'} Average Creation - Workflows`] = isTeam
        ? week['Team Creation - Workflows'] / d
        : isPack
        ? week['Pack Creation - Workflows'] / d
        : week['Creation - Workflows'] / d;

      week[`${isTeam ? 'Team' : 'Pack'} Average Creation - Tests`] = isTeam
        ? week['Team Creation - Tests'] / d
        : isPack
        ? week['Pack Creation - Tests'] / d
        : week['Creation - Tests'] / d;
    }
  };

  const handleLegendEnter = (o) => {
    const { dataKey } = o;

    // if the dataKey is not visible, do not change opacity
    if (!visibility[dataKey]) return;

    // @ts-ignore
    setOpacity((op) => {
      const newOpacity = {};
      Object.keys(op).forEach((key) => {
        newOpacity[key] = key === dataKey ? 1 : 0.3;
      });
      return newOpacity;
    });
  };

  const handleLegendLeave = (o) => {
    const { dataKey } = o;

    // if the dataKey is not visible, do not change opacity
    if (!visibility[dataKey]) return;

    // @ts-ignore
    setOpacity((op) => {
      const newOpacity = {};
      Object.keys(op).forEach((key) => {
        newOpacity[key] = 1;
      });
      return newOpacity;
    });
  };

  const handleLegendClick = (o) => {
    const { dataKey } = o;

    setVisibility((vis) => {
      const newVisibility = { ...vis, [dataKey]: !vis[dataKey] };
      sessionStorage.setItem(isPack ? storageMap.pack : isTeam ? storageMap.team : storageMap.qae, JSON.stringify(newVisibility));
      return newVisibility;
    });
  };

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <ul className={classNames('flex flex-wrap items-center gap-x-5 list-none p-0 ml-4 w-full', isPack || isTeam ? 'text-xs' : 'text-base')}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            className={classNames('flex justify-center flex-1 my-1', !visibility[entry.value] && 'text-gray-400')}
            onClick={() => handleLegendClick(entry)}
            onMouseEnter={() => handleLegendEnter(entry)}
            onMouseLeave={() => handleLegendLeave(entry)}
          >
            <button className="flex items-center">
              {comparisonMetrics && ((isTeam && !entry.value?.includes('Team')) || (isPack && !entry.value?.includes('Pack'))) ? (
                <img
                  className={classNames('h-6 w-6 mr-2 rounded-full', !visibility[entry.value] && 'grayscale')}
                  src={isTeam ? comparisonMetrics.qae.avatar48 : comparisonMetrics.team.imageUrl}
                  alt="Avatar"
                />
              ) : (
                <span className="h-3.5 w-3.5 mr-2 rounded-full" style={{ backgroundColor: visibility[entry.value] ? entry.color : '#ccc' }}></span>
              )}
              <span className="truncate">{entry.value?.replace(/\b(Team|Pack)\b/g, '').replace(/\bAverage\b/g, 'Avg')}</span>
            </button>
          </li>
        ))}
      </ul>
    );
  };

  /* DATA ACTIONS */
  const primaryCreationGroupedByQAE = creationMetrics.teamCreationByWeekAndType ? _.groupBy(creationMetrics.teamCreationByWeekAndType, 'name') : [];
  for (const qae of Object.keys(primaryCreationGroupedByQAE)) {
    primaryCreationGroupedByQAE[qae] = _.groupBy(primaryCreationGroupedByQAE[qae], 'Week Number');
    primaryCreationGroupedByQAE[qae] = buildQAEChartDataFromMetrics(primaryCreationGroupedByQAE[qae]);
  }

  const primaryCreationGroupedByWeek = _.groupBy(
    creationMetrics?.qaeCreationByWeekAndType || creationMetrics?.teamCreationByWeekAndType || creationMetrics?.packCreationByWeekAndType,
    'Week Number',
  );

  // build chart data from metrics depending on qae | team | pack
  let chartData = isTeam
    ? buildTeamChartDataFromMetrics(primaryCreationGroupedByWeek)
    : isPack
    ? buildPackChartDataFromMetrics(primaryCreationGroupedByWeek)
    : buildQAEChartDataFromMetrics(primaryCreationGroupedByWeek);

  let comparisonChartData = comparisonMetrics
    ? isTeam
      ? buildQAEChartDataFromMetrics(_.groupBy(comparisonMetrics.qaeCreationByWeekAndType, 'Week Number'))
      : buildTeamChartDataFromMetrics(_.groupBy(comparisonMetrics.teamCreationByWeekAndType, 'Week Number'))
    : null;

  if (isTeam || isPack) {
    addWeeklyAveragesToChartData(chartData);
  }

  if (comparisonMetrics) {
    chartData = _.values(_.merge(_.keyBy(chartData, 'name'), _.keyBy(comparisonChartData, 'name')));
  }

  const sortedChartData = chartData.sort((a, b) => {
    const [yearA, weekA] = a.name.split(' ').map(Number);
    const [yearB, weekB] = b.name.split(' ').map(Number);

    if (yearA === yearB) {
      return weekB - weekA;
    } else {
      return yearB - yearA;
    }
  });

  return (
    <div className="flex-col items-center justify-center border">
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={sortedChartData.reverse()} margin={{ right: 40, top: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tickMargin={5} />
          <YAxis />
          <Tooltip />
          <Legend wrapperStyle={{ textAlign: 'center' }} content={renderLegend} />
          <Line
            type="monotone"
            dataKey={isTeam ? 'Team Creation - Tests' : isPack ? 'Pack Creation - Tests' : 'Creation - Tests'}
            hide={!visibility[isTeam ? 'Team Creation - Tests' : isPack ? 'Pack Creation - Tests' : 'Creation - Tests']}
            strokeOpacity={opacity[isTeam ? 'Team Creation - Tests' : isPack ? 'Pack Creation - Tests' : 'Creation - Tests']}
            stroke="#d8d384"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={isTeam ? 'Team Creation - Workflows' : isPack ? 'Pack Creation - Workflows' : 'Creation - Workflows'}
            hide={!visibility[isTeam ? 'Team Creation - Workflows' : isPack ? 'Pack Creation - Workflows' : 'Creation - Workflows']}
            strokeOpacity={opacity[isTeam ? 'Team Creation - Workflows' : isPack ? 'Pack Creation - Workflows' : 'Creation - Workflows']}
            stroke="#d88488"
            activeDot={{ r: 8 }}
          />
          {!isTeam && !isPack && (
            <>
              <Line
                type="monotone"
                dataKey="Outlining - Tests"
                hide={!visibility['Outlining - Tests']}
                strokeOpacity={opacity['Outlining - Tests']}
                stroke="#82ca9d"
                activeDot={{ r: 4 }}
              />
              <Line
                type="monotone"
                dataKey="Outlining - Tasks"
                hide={!visibility['Outlining - Tasks']}
                strokeOpacity={opacity['Outlining - Tasks']}
                stroke="#8884d8"
                activeDot={{ r: 4 }}
              />
            </>
          )}
          {isTeam && (
            <>
              <Line
                type="monotone"
                dataKey="Team Average Creation - Tests"
                hide={!visibility['Team Average Creation - Tests']}
                strokeOpacity={opacity['Team Average Creation - Tests']}
                stroke="#88d884"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="Team Average Creation - Workflows"
                hide={!visibility['Team Average Creation - Workflows']}
                strokeOpacity={opacity['Team Average Creation - Workflows']}
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </>
          )}
          {isPack && (
            <>
              <Line
                type="monotone"
                dataKey="Pack Average Creation - Tests"
                hide={!visibility['Pack Average Creation - Tests']}
                strokeOpacity={opacity['Pack Average Creation - Tests']}
                stroke="#88d884"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="Pack Average Creation - Workflows"
                hide={!visibility['Pack Average Creation - Workflows']}
                strokeOpacity={opacity['Pack Average Creation - Workflows']}
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </>
          )}
          {comparisonMetrics && (
            <>
              <Line
                type="monotone"
                dataKey={isTeam ? 'Creation - Tests' : 'Team Creation - Tests'}
                hide={!visibility[isTeam ? 'Creation - Tests' : 'Team Creation - Tests']}
                strokeOpacity={opacity[isTeam ? 'Creation - Tests' : 'Team Creation - Tests']}
                stroke="#FF8C00"
                strokeWidth={3}
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey={isTeam ? 'Creation - Workflows' : 'Team Creation - Workflows'}
                hide={!visibility[isTeam ? 'Creation - Workflows' : 'Team Creation - Workflows']}
                strokeOpacity={opacity[isTeam ? 'Creation - Workflows' : 'Team Creation - Workflows']}
                stroke="#4682B4"
                strokeWidth={3}
                activeDot={{ r: 8 }}
              />
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
