import { useState } from 'react';

import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Switch, FormGroup, FormControlLabel, TextField } from '@mui/material';

import { getDisplayName } from '../../MetricsExplorer/helpers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedItems) {
  return {
    fontWeight: selectedItems.indexOf(name) === -1 ? 400 : 500,
  };
}

/**
 * Base card form component - extend to packs, teams, qaes
 * @param {object} props
 * @param {import('../../MetricsExplorer/types').QAEFromLocalStorage[] | import('../../MetricsExplorer/types').QATeamFromLocalStorage[]} props.items
 * @param {string} props.label
 * @param {string} props.inputLabel
 * @param {string} [props.switchLabel = null]
 * @param {string|null} [props.secondarySwitchLabel = null]
 * @param {string} props.itemName
 * @param {string} [props.subSelectName = null]
 * @param {string|null} [props.secondarySubSelectName = null]
 * @param {string} [props.keyName = 'name']
 * @returns {import('react').JSX.Element}
 */
export default function CardFormBase({
  items,
  label,
  inputLabel,
  switchLabel = null,
  secondarySwitchLabel = null,
  itemName,
  subSelectName = null,
  secondarySubSelectName = null,
  keyName = 'name',
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [cardName, setCardName] = useState('');

  // handle selection updates
  const handleUpdateItems = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedItems(typeof value === 'string' ? value.split(', ') : value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id={`${label}-select-label`}>{items && items.length ? inputLabel : `Error loading ${label}...`}</InputLabel>
        {items && items.length ? (
          <FormGroup>
            <Select
              required
              labelId={`${label}-select-label`}
              id={`${label}-select`}
              name={itemName}
              multiple
              value={selectedItems}
              onChange={handleUpdateItems}
              input={<OutlinedInput label={inputLabel} />}
              MenuProps={MenuProps}
              renderValue={(selected) => selected.map((item) => getDisplayName(item)).join(' | ')}
            >
              {items.map((item) => (
                <MenuItem key={item[keyName]} value={item[keyName]} style={getStyles(item[keyName], selectedItems)}>
                  <img src={item.avatar48 || item.imageUrl} alt="avatar" className="h-7 w-7 rounded-full mr-4" />
                  <span>{getDisplayName(item[keyName])}</span>
                </MenuItem>
              ))}
            </Select>
            <div className="flex justify-start items-center">
              {switchLabel && subSelectName && (
                <FormControlLabel
                  control={<Switch disabled={selectedItems.length < 2} name={`includeIndividual${subSelectName}`} value={true} />}
                  label={switchLabel}
                />
              )}
              {secondarySwitchLabel && secondarySubSelectName && (
                <FormControlLabel
                  control={<Switch disabled={selectedItems.length === 0} name={`includeIndividual${secondarySubSelectName}`} value={true} />}
                  label={secondarySwitchLabel}
                />
              )}
            </div>
            <TextField
              required
              margin="dense"
              id="name"
              name="cardName"
              label="Card Name"
              type="text"
              fullWidth
              variant="standard"
              value={cardName ? cardName : selectedItems.map((item) => getDisplayName(item)).join(' | ')}
              onChange={(event) => setCardName(event.target.value)}
            />
          </FormGroup>
        ) : null}
      </FormControl>
    </div>
  );
}
