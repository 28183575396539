import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Switch } from '@headlessui/react';
import WolfLoader from '../../WolfLoader/WolfLoader';
import { WeeklyInvestigationChart } from '../charts';
import { InvestigationTasksTable, WeeklyInvestigationComparisonTable } from '../tables';

import {
  computeAverageAndMedianAssistedInvestigationTasksPerWeek,
  computeAverageAndMedianInvestigationTasksPerWeek,
  computeInvestigationAggregatesByWeek,
  getInvestigationMetrics,
  groupInvestigationTasksByWeek,
  protectFilters,
} from '../helpers';
import { classNames } from '../../InvestigationBoard/helpers';

import { WOLF_LOGO } from '../../../constants';

export default function TeamInvestigationView({ filters, setFilters }) {
  const [includePack, setIncludePack] = useState(JSON.parse(sessionStorage.getItem('investigationIncludePack')) ?? false);

  // protect team filters from potential stale state
  filters = protectFilters(filters);

  const {
    data: investigationData,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['investigationData', filters],
    queryFn: () => getInvestigationMetrics(filters),
  });

  const {
    data: packInvestigationData,
    fetchStatus: packFetchStatus,
    isError: isPackError,
    error: packError,
  } = useQuery({
    queryKey: ['packInvestigationData', filters],
    queryFn: () =>
      getInvestigationMetrics({
        entity: {
          type: 'pack',
          data: {
            id: investigationData.team.packId,
          },
        },
        types: filters.types,
        investigationOptions: filters.investigationOptions,
        gte: filters.gte,
        lte: filters.lte,
      }),
    enabled: includePack && investigationData?.team?.packId !== undefined,
  });

  const handleIncludePack = () => {
    sessionStorage.setItem('creationIncludePack', JSON.stringify(!includePack));
    setIncludePack(!includePack);
  };

  const handleIncludeAssisted = () => {
    sessionStorage.setItem('includeTeamAssistedSuites', JSON.stringify(!filters.investigationOptions.includeAssistedSuites));
    const newOptions = { ...filters.investigationOptions, includeAssistedSuites: !filters.investigationOptions.includeAssistedSuites };

    const newTeamFilter = { ...filters, investigationOptions: newOptions };
    setFilters((prevFilters) => ({
      ...prevFilters,
      team: newTeamFilter,
    }));
  };

  if (isPending) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  // get team data (primary and assisted)
  const teamInvestigationDataGroupedByWeek = groupInvestigationTasksByWeek(investigationData.teamPrimarySuites);
  const aggregatedTeamInvestigationDataGroupedByWeek = computeInvestigationAggregatesByWeek(teamInvestigationDataGroupedByWeek, true);
  const teamAverageInvestigationDataGroupedByWeek = computeAverageAndMedianInvestigationTasksPerWeek(
    teamInvestigationDataGroupedByWeek,
    investigationData.team.members,
  );
  const teamAssistedInvestigationDataGroupedByWeek = groupInvestigationTasksByWeek(investigationData.teamAssistedSuites);
  const teamAverageAssistedInvestigationDataGroupedByWeek = computeAverageAndMedianAssistedInvestigationTasksPerWeek(
    teamAssistedInvestigationDataGroupedByWeek,
    investigationData.team.members,
  );

  return (
    <div className="min-h-screen px-4 py-2 flex-col rounded-lg shadow">
      <div className="flex space-x-4 py-4 items-center justify-start">
        <div className="flex flex-col justify-center items-center pl-4">
          <img src={investigationData.team.imageUrl} alt="avatar" className="h-14 w-14 rounded-full" />
          <span className="font-semibold">{investigationData.team.name}</span>
        </div>
        <div className="flex flex-col flex-1 justify-center items-start">
          <span className="text-sm text-gray-600">Members of this team:</span>
          <div className="flex gap-x-2">
            {investigationData.team &&
              investigationData.team.members.map((m, mIdx) => (
                <span key={mIdx} className="text-xs text-gray-400">
                  <NavLink to={`/qae-metrics?activeTab=Investigation&qae=${m.qawId}&gte=${filters.gte}&lte=${filters.lte}`}>
                    {m.name.split(' ')[0]}
                  </NavLink>
                </span>
              ))}
          </div>
        </div>
        <Switch.Group as="div" className="flex flex-1 justify-end items-center pr-4 gap-x-4">
          <div className="flex justify-center items-center">
            {/* // TODO: Implement separate load/query for assisted suites
             {packFetchStatus === 'fetching' && (
              <Switch.Label as="span" className="flex items-center justify-center h-10 mr-2 text-sm">
                <img src={WOLF_LOGO} alt="loadingAssistedSuites" className="h-6 w-auto animate-spin-slow" />
              </Switch.Label>
            )}
            {isPackError && (
              <Switch.Label as="span" className="h=10 ml-3 text-sm">
                Error: {packError.message}
              </Switch.Label>
            )} */}
            <Switch
              checked={filters.investigationOptions.includeAssistedSuites}
              onClick={handleIncludeAssisted}
              className={classNames(
                filters.investigationOptions.includeAssistedSuites ? 'bg-blue-400' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  filters.investigationOptions.includeAssistedSuites ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3 text-sm">
              <span className="font-medium text-gray-900">Include Assisted Suites</span>
            </Switch.Label>
          </div>
          <div className="flex justify-center items-center">
            {packFetchStatus === 'fetching' && (
              <Switch.Label as="span" className="flex items-center justify-center h-10 mr-2 text-sm">
                <img src={WOLF_LOGO} alt="loadingPack" className="h-6 w-auto animate-spin-slow" />
              </Switch.Label>
            )}
            {isPackError && (
              <Switch.Label as="span" className="h=10 ml-3 text-sm">
                Error: {packError.message}
              </Switch.Label>
            )}
            <Switch
              checked={includePack}
              onClick={handleIncludePack}
              className={classNames(
                includePack ? 'bg-blue-400' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  includePack ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3 text-sm">
              <span className="font-medium text-gray-900">Include Pack</span>
            </Switch.Label>
          </div>
        </Switch.Group>
      </div>
      <div className="isolate flex-col border">
        {/* Team "At A Glance" Header */}
        <div className="isolate grid grid-cols-2">
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b border-r">
            Team Suites Investigated: {_.sumBy(_.values(teamInvestigationDataGroupedByWeek), 'length')}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b">
            Team Average Suites Investigated: {_.meanBy(_.values(teamInvestigationDataGroupedByWeek), 'length').toFixed(2)}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b border-r">
            Team Failures Investigated: {_.sumBy(_.values(aggregatedTeamInvestigationDataGroupedByWeek), 'sumFailures')}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b">
            Team Average Failures Investigated: {_.meanBy(_.values(aggregatedTeamInvestigationDataGroupedByWeek), 'sumFailures').toFixed(2)}
          </span>
          <span
            className={classNames(
              filters.investigationOptions.includeAssistedSuites ? 'border-b' : '',
              'group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-r',
            )}
          >
            Team Total Bug Failures: {_.sumBy(_.values(aggregatedTeamInvestigationDataGroupedByWeek), 'sumInvestigatedAsBug')}
          </span>
          <span
            className={classNames(
              filters.investigationOptions.includeAssistedSuites ? 'border-b' : '',
              'group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium',
            )}
          >
            Team Average Investigation Time: {_.meanBy(_.values(aggregatedTeamInvestigationDataGroupedByWeek), 'avgInvestigationTime').toFixed(2)}{' '}
            minutes
          </span>

          {/* Team Assisted "At A Glance" Header Row */}
          {filters.investigationOptions.includeAssistedSuites && !isPending && (
            <>
              <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-r">
                Team Suites Assisted: {_.sumBy(_.values(teamAssistedInvestigationDataGroupedByWeek), 'length')}
              </span>
              <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
                Team Average Suites Assisted: {_.meanBy(_.values(teamAverageAssistedInvestigationDataGroupedByWeek), 'mean').toFixed(2)}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="py-4">
        {investigationData && (
          <>
            <div className="py-4">
              <WeeklyInvestigationChart
                data={{
                  team: investigationData.team,
                  teamInvestigationDataGroupedByWeek,
                  aggregatedTeamInvestigationDataGroupedByWeek,
                  teamAverageInvestigationDataGroupedByWeek,
                  teamAssistedInvestigationDataGroupedByWeek,
                  teamAverageAssistedInvestigationDataGroupedByWeek,
                }}
                filters={filters}
              />
            </div>
          </>
        )}
      </div>
      <div className="border mb-8 p-4 grid 3xl:grid-cols-2 6xl:grid-cols-3 grid-flow-row gap-4">
        {investigationData.team &&
          investigationData.team.members
            .filter((m) => !m.isManager)
            .map((m, mIdx) => {
              const qaeInvestigationData = investigationData.teamPrimarySuites.filter((s) => s.taskClaimedBy === m.email);
              const qaeAssistedInvestigationData = investigationData.teamAssistedSuites.filter((s) => s.supportedBy.includes(m.email));
              const qaeInvestigationDataGroupedByWeek = groupInvestigationTasksByWeek(qaeInvestigationData);
              const aggregatedQAEInvestigationDataGroupedByWeek = computeInvestigationAggregatesByWeek(qaeInvestigationDataGroupedByWeek);
              const qaeAssistedInvestigationDataGroupedByWeek = groupInvestigationTasksByWeek(qaeAssistedInvestigationData);

              return (
                <div key={mIdx} className="shadow">
                  <WeeklyInvestigationComparisonTable
                    qaeData={{
                      qaeInvestigationData,
                      qaeAssistedInvestigationData,
                      qaeInvestigationDataGroupedByWeek,
                      qaeAssistedInvestigationDataGroupedByWeek,
                      aggregatedQAEInvestigationDataGroupedByWeek,
                    }}
                    teamData={{
                      teamInvestigationDataGroupedByWeek,
                      teamAssistedInvestigationDataGroupedByWeek,
                      teamAverageInvestigationDataGroupedByWeek,
                      teamAverageAssistedInvestigationDataGroupedByWeek,
                      aggregatedTeamInvestigationDataGroupedByWeek,
                    }}
                    targetQAE={m}
                    targetTeam={investigationData.team}
                  />
                </div>
              );
            })}
      </div>
      {includePack && packInvestigationData && (
        <div>
          <div className="border mb-8 p-4 flex space-x-4 py-4 items-center justify-start">Pack Investigation Comparison Coming Soon</div>
        </div>
      )}
      <div className="border mb-8 p-4 grid">
        <InvestigationTasksTable investigationTasks={investigationData.teamPrimarySuites} team={investigationData.team} />
      </div>
    </div>
  );
}
