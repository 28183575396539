/**
 * Renders the custom tooltip with opacity & custom wording/descriptions
 * @param {import('recharts').TooltipProps<number | number[], string>} props
 * @returns {import('react').JSX.Element}
 */
export default function CustomTooltip(props) {
  const { active, payload, label } = props;

  if (active && payload && payload.length) {
    const [year, week] = label.split(' ');
    const updatedLabel = `Week ${week}, ${year}`;

    return (
      <div className="bg-white p-1 bg-opacity-80 border">
        <p className="font-semibold text-black mb-1 text-center">{updatedLabel}</p>
        {payload.map((entry) => {
          let { name, value, color } = entry;

          // handle custom wording for min/max and avg
          name = name === 'mm' ? 'Min ~ Max' : name === 'a' ? 'Average' : name === 'p' ? '25% ~ 75%' : name;

          // handle cardIds from playground chart
          if (name.includes(':')) {
            const splitName = name.split(':');
            name = `${splitName.at(-2)} - ${splitName.at(-1)}`;
          }

          return (
            <p style={{ color }} key={name}>
              {name}: {Array.isArray(value) ? `[${value.join(' ~ ')}]` : value}
            </p>
          );
        })}
      </div>
    );
  }

  return null;
}
