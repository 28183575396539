import { Typography, Card, CardHeader, CardContent, Button } from '@mui/material';


export const NavigationCardBookOfBusiness = ({ visibleCharts }) => {
    const scrollToElement = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    return (
      <Card raised sx={{ display: 'flex', flexDirection: 'column', minHeight: 300 }}>
        <CardHeader title="Navigation" sx={{ bgcolor: '#2f2fc1', color: 'common.white' }} />
        <CardContent>
          <Typography variant="body1" gutterBottom>
            Navigation:
          </Typography>
          <ul>
            {visibleCharts.map((chart, index) => (
              <li key={index}>
                <Button
                  onClick={() => scrollToElement(chart.replace(/\s+/g, '-').toLowerCase())}
                  sx={{ textTransform: 'none' }}
                >
                  {index + 1}. {chart}
                </Button>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>
    );
};
