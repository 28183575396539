import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';


export default function TeamOverviewTable({ data: qaTeams }) {
  
  const columns = useMemo(() => {
    return [
      {
        header: 'Team Name',        
        accessorKey: 'teamName',
      },
      {
        header: 'Pack Manager',
        accessorKey: 'packManager.name',
      },
      {
        header: 'Customer ARR',
        accessorKey: 'teamArr',
        Cell: ({ cell }) => cell.getValue()
          .toLocaleString('en-US', {
            style: 'currency', 
            currency: 'USD',
            maximumFractionDigits: 0,
          },
        ),
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamArr, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredTotal / filteredRowCount).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Costs',
        accessorKey: 'teamCost',
        Cell: ({ cell }) => cell.getValue()
          .toLocaleString('en-US', {
            style: 'currency', 
            currency: 'USD',
            maximumFractionDigits: 0,
          },
        ),
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamCost, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredTotal / filteredRowCount).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Margin',
        id: 'teamMargin',
        accessorFn: (row) => row.teamArr - row.teamCost,
        Cell: ({ cell }) => cell.getValue()
          .toLocaleString('en-US', {
            style: 'currency', 
            currency: 'USD',
            maximumFractionDigits: 0,
          },
        ),
        Footer: ({ table }) => {
          const filteredTotalCost = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamCost, 0);
          const filteredTotalArr = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamArr, 0);
          const filteredMargin = filteredTotalArr - filteredTotalCost;
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
              {(filteredMargin / filteredRowCount).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: '% Margin',
        id: 'teamMarginPercentage',
        accessorFn: (row) => Math.round((row.teamArr - row.teamCost) / row.teamArr * 100) + "%",
        Footer: ({ table }) => {
          const filteredTotalCost = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamCost, 0);
          const filteredTotalArr = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamArr, 0);
          const filteredMargin = filteredTotalArr - filteredTotalCost;
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
              </Box>
              {Math.round((filteredMargin / filteredRowCount) / (filteredTotalArr / filteredRowCount) * 100) + "%"}
            </Stack>
          );
        },
      },
      {
        header: 'Tests Under Contract',
        accessorKey: 'teamTestsUnderContract',
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamTestsUnderContract, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {(filteredTotal / filteredRowCount).toLocaleString()}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Active Tests',
        id: 'teamActiveTests',
        accessorFn: (row) => row.teamActiveTests - row.teamTestsBugged,
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        Footer: ({ table }) => {
          const filteredTotalActive = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamActiveTests, 0);
          const filteredTotalBugged = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamTestsBugged, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {((filteredTotalActive - filteredTotalBugged) / filteredRowCount).toLocaleString('en-US', { maximumFractionDigits: 0 })}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: '% Tests in Maintenance',
        id: 'teamTestsInMaintenance',
        accessorFn: (row) => Math.round(row.teamTestsInMaintenance / row.teamActiveTests * 100) + "%",
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        Footer: ({ table }) => {
          const filteredTotalMaintenance = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamTestsInMaintenance, 0);
          const filteredTotalActive = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamActiveTests, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {Math.round((filteredTotalMaintenance / filteredRowCount) / (filteredTotalActive / filteredRowCount) * 100) + "%"}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: '% Tests Bugged',
        id: 'teamTestsBugged',
        accessorFn: (row) => Math.round(row.teamTestsBugged / (row.teamActiveTests + row.teamTestsInMaintenance) * 100) + "%",
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        Footer: ({ table }) => {
          const filteredTotalActive = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamActiveTests, 0);
          const filteredTotalMaintenance = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamTestsInMaintenance, 0);
          const filteredTotalBugged = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamTestsBugged, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {Math.round((filteredTotalBugged / filteredRowCount) / ((filteredTotalActive + filteredTotalMaintenance) / filteredRowCount) * 100) + "%"}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'QA Team Size (Incl. Lead)',
        accessorKey: 'teamSize',
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamSize, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {Math.round(filteredTotal / filteredRowCount)}
              </Box>
            </Stack>
          );
        },
      },
      {
        header: 'Avg. Active Tests per QAE',
        id: 'teamAverageActiveTestsPerQae',
        accessorFn: (row) => row.teamAverageActiveTestsPerQae,
        Cell: ({ cell }) => cell.getValue().toLocaleString(),
        Footer: ({ table }) => {
          const filteredTotal = table.getFilteredRowModel().rows.reduce((acc, row) => acc + row.original.teamAverageActiveTestsPerQae, 0);
          const filteredRowCount = table.getFilteredRowModel().rows.length;
          return (
            <Stack>
              Average:
              <Box>
                {Math.round((filteredTotal / filteredRowCount))}
              </Box>
            </Stack>
          );
        },
      },
    ];
  }, [qaTeams]);

  const table = useMaterialReactTable({
    columns,
    data: qaTeams,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
    },
    enableColumnActions: true,
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
  });

  return <MaterialReactTable table={table} />;
}
