import { Typography, Grid, Card, CardHeader, CardContent, Box, Button } from '@mui/material';
import { stringToColor, hslStringToRgbString } from '../../../utils/colorUtils';



export const BarChartLegendBookOfBusiness = ({ customerNames, selectedCustomers, onLegendClick, onToggleAll }) => {
    const sortedCustomerNames = [...customerNames].sort();
  
    return (
      <Card raised sx={{ display: 'flex', flexDirection: 'column', minHeight: 300 }}>
        <CardHeader title="Legend" sx={{ bgcolor: '#2f2fc1', color: 'common.white' }} />
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            <Button variant="outlined" size="small" onClick={() => onToggleAll(true)}>
              Select All
            </Button>
            <Button variant="outlined" size="small" onClick={() => onToggleAll(false)}>
              Deselect All
            </Button>
          </Box>
          <Grid container spacing={1}>
            {sortedCustomerNames.map((name, index) => (
              <Grid item key={index} xs={6} sm={4} md={3}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    opacity: selectedCustomers.has(name) ? 1 : 0.5,
                  }}
                  onClick={() => onLegendClick(name)}
                >
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      backgroundColor: hslStringToRgbString(stringToColor(name)),
                      marginRight: '8px',
                      borderRadius: '50%',
                    }}
                  />
                  <Typography variant="body2">{name}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
};
