import { XCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { classNames } from './helpers';
import Toast from '../Layout/Toast';
import { sendPostRequest } from '../../utils/network';
import moment from 'moment';
import UserSelector from '../Layout/UserSelector';

export function NewTaskModal({ showNewTaskModal, setShowNewTaskModal, newTaskModalData, setNewTaskModalData, suiteAction, setToast }) {
  const [draftDetails, setDraftDetails] = useState({});
  const [dismissedOnce, setDismissedOnce] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const users = JSON.parse(localStorage.getItem('qawUsers'));
  const sortedUsers = users.sort((a, b) => {
    let nameA = a.name ? a.name.split(' ')[0].toLowerCase() : a.email.split('@')[0].toLowerCase();
    let nameB = b.name ? b.name.split(' ')[0].toLowerCase() : b.email.split('@')[0].toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  useEffect(() => {
    setDraftDetails(newTaskModalData);
  }, [newTaskModalData]);

  useEffect(() => {
    setDraftDetails({ ...draftDetails, assignedTo: selectedPerson });
  }, [selectedPerson]);

  const handleChanges = (e) => {
    setDraftDetails({ ...draftDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { team, user, taskId, message } = newTaskModalData;
    const taskDetails = {
      ...draftDetails,
      dueAt: moment().add(1, 'days').format('YYYY-MM-DD'),
      messageUrl: message.url,
      assignedTo: selectedPerson,
      taskId,
      user,
    };

    try {
      // Create the generic task
      await sendPostRequest('/create-generic-task', { team, taskDetails });

      // complete the message task
      await suiteAction(taskId, '/done');

      // success toast and clear modal data
      setToast(<Toast title={'Success!'} message={`Created Follow Up Task`} key={new Date().toISOString()} isSuccess={true} />);
      setNewTaskModalData('');
      setShowNewTaskModal(!showNewTaskModal);
      setSelectedPerson(null);
    } catch (e) {
      setToast(<Toast title={'Ruh-roh'} message={`Something went wrong: ${e.message}`} key={new Date().toISOString()} isSuccess={false} />);
    } finally {
      setDismissedOnce(false);
    }
  };

  return (
    <div className={`fixed inset-0 flex justify-center items-center ${showNewTaskModal ? 'visible bg-black/40' : 'invisible'}`}>
      <div className={`flex flex-col min-w-[20%] bg-white rounded-xl shadow p-6 ${showNewTaskModal ? 'opacity-100' : 'opacity-0'}`}>
        <div className="hover:cursor-pointer flex justify-end">
          {dismissedOnce ? (
            <div
              className="min-w-max h-6 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-red-700 bg-red-50 ring-red-600/20 hover:ring-2 hover:bg-red-100"
              onClick={() => {
                setShowNewTaskModal(!showNewTaskModal);
                setDismissedOnce(false);
              }}
            >
              Discard changes?
            </div>
          ) : (
            <XCircleIcon
              className="w-11 h-6 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-red-700 bg-red-50 ring-red-600/20 hover:ring-2 hover:bg-red-100"
              onClick={() => {
                setDismissedOnce(true);
                setTimeout(() => {
                  setDismissedOnce(false);
                }, 3000);
              }}
            />
          )}
        </div>
        <section className={classNames('flex row justify-between', showNewTaskModal ? 'visible' : 'invisible')}>
          <div className="pb-10 w-full">
            <div className="py-2">
              <label className="block text-m font-medium leading-6 text-gray-900">Assign To</label>
              <div className="mt-2">
                <UserSelector users={sortedUsers} selectedPerson={selectedPerson} setSelectedPerson={setSelectedPerson} />
              </div>
            </div>
            <div className="py-2">
              <label htmlFor="email" className="block text-m font-medium leading-6 text-gray-900">
                Title
              </label>
              <div className="mt-2 w-full">
                <input
                  type="text"
                  name="name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Do the thing"
                  value={draftDetails?.name || ''}
                  onChange={handleChanges}
                />
              </div>
            </div>
            <div className="py-2">
              <label htmlFor="email" className="block text-m font-medium leading-6 text-gray-900">
                Notes
              </label>
              <div className="mt-2 w-full">
                <textarea
                  name="notes"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="The Slack message will be added automatically."
                  value={draftDetails?.notes || ''}
                  onChange={handleChanges}
                />
              </div>
            </div>
            <button
              type="button"
              className="inline-flex justify-center gap-x-2 rounded-md bg-indigo-600 mt-5 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSubmit}
            >
              Create Task
              <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}
