import { Navigate, useLocation, useParams } from 'react-router-dom';

export default function RedirectRoute() {
  const url = useLocation();

  if (url.pathname.includes('/teamTasks')) {
    const { teamId } = useParams();
    return <Navigate to={`/client-summary/${teamId}`} replace />;
  }
  
  if (url.pathname.includes('/sniffer')) {
    const { suiteId } = useParams();
    return <Navigate to={`/flow-explorer/${suiteId}`} replace />;
  }
}
