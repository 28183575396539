import { NavigateNextRounded } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ProjectPlanStatusCell({ row }) {
  const navigate = useNavigate();
  const isInProgress = !row.original.isProjectPlanComplete;
  const status = isInProgress ? 'In Progress' : 'Done';
  return (
    <Box sx={{ display: 'flex', justifyContent: 'left', width: '100%', alignItems: 'center' }}>
      <Button
        variant="contained"
        color={isInProgress ? 'warning' : 'success'}
        onClick={() => {
          navigate(`/client-summary/${row.original.id}`);
        }}
        fullWidth
        endIcon={<NavigateNextRounded />}
      >
        {status}
      </Button>
    </Box>
  );
}
