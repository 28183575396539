export default function StalenessWidget({ team }) {
  return (
    <div className="col-span-2 p-2 mr-2 w-full">
      <h3 className="text-center font-medium pb-2">Staleness</h3>
      <div className="rounded-xl border border-gray-200 p-4">
        <div className="flex justify-between pb-2">
          <div className="text-gray-500">Oldest CR Age</div>
          <div className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">{team.staleness.maxCrAge}</div>
          </div>
        </div>
        <div className="flex justify-between pb-2 ">
          <div className="text-gray-500">Avg. CR Age</div>
          <div className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">{team.staleness.avgCrAge}</div>
          </div>
        </div>
        <div className="flex justify-between pb-2 ">
          <div className="text-gray-500">Oldest Maint. Age</div>
          <div className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">{team.staleness.maxMaintAge}</div>
          </div>
        </div>
        <div className="flex justify-between gap-x-4">
          <div className="text-gray-500">Avg. Maint. Age</div>
          <div className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">{team.staleness.avgMaintAge}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
