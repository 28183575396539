class PageError extends Error {
  constructor(message, ...params) {
    super(message);

    // Maintains proper stack trace (where our error was thrown)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PageError);
    }

    // Custom properties
    Object.assign(this, ...params);
  }
}

export default PageError;
