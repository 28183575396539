import { useMemo } from 'react';

import { roundToTwo } from '../../MetricsPlayground/helpers';

/**
 *
 * @param {object} props
 * @param {{investigation: import('../types').InvestigationTrackedTimeAggregate[], creation: import('../types').TaskSnapshotTrackedTimeAggregate[]}} props.chartData
 * @returns {import('react').JSX.Element}
 */
export default function ComplexImpactAnalysisCard({ chartData }) {
  const investigatedSuites = chartData.investigation.flatMap((data) => data.suites);

  // calculate impact score contributions
  const impactScoreContributions = useMemo(
    () => ({
      bugFailures: investigatedSuites.reduce((acc, suite) => acc + suite.number_of_runs_triaged_as_bug, 0),
      fixedFailures: investigatedSuites.reduce((acc, suite) => acc + suite.number_of_runs_passing_on_qae_fix, 0),
      maintenanceFailures: investigatedSuites.reduce((acc, suite) => acc + suite.number_of_runs_triaged_as_maintenance, 0),
      retriedFailures: investigatedSuites.reduce((acc, suite) => acc + suite.number_of_runs_passing_on_qae_flake, 0),
    }),
    [investigatedSuites.length],
  );

  return (
    <div className="flex flex-col items-start justify-normal h-full">
      <br />
      <pre className="bg-gray-300 p-3 rounded-md overflow-x-auto text-left w-full">
        <code className="text-sm font-mono">
          <span className="font-bold text-blue-500">baselineImpactScore</span> = <span className="font-bold">investigationVelocity</span> +
          <span className="font-bold"> creationVelocity </span>
          <br />
          <br />
          <span className="font-bold italic text-yellow-500">complexImpactScore</span> =
          <span className="font-bold text-blue-500"> baselineImpactScore</span>
          {'\n'}
          {'\t'}+ <span className="font-bold text-green-500">failuresInvestigatedAsBug</span> +
          <span className="font-bold text-green-500"> failuresPassingOnFix</span> {'\n'}
          {'\t'}- (<span className="font-bold text-red-400">failuresInvestigatedAsMaintenance</span> *{' '}
          <span className="font-bold text-red-400">2</span>){'\n'}
          {'\t'}- (<span className="font-bold text-red-400"> failuresPassingOnFlake </span>/ <span className="font-bold text-red-400">10</span>)
        </code>
      </pre>
      <br />
      <div className="flex w-full">
        <ul className="flex-1">
          <li>
            <span className="italic">Bug Failures: </span>
            <span className="text-green-500">{impactScoreContributions.bugFailures}</span>
          </li>
          <li>
            <span className="italic">Fixed Failures: </span>
            <span className="text-green-500">{impactScoreContributions.fixedFailures}</span>
          </li>
          <li>
            <span className="italic">Maintenance Failures: </span>
            <span className="text-red-400">{impactScoreContributions.maintenanceFailures}</span>
          </li>
          <li>
            <span className="italic">Retried Failures: </span>
            <span className="text-red-400">{impactScoreContributions.retriedFailures}</span>
          </li>
          <li className="h-4"></li>
          <li>
            <span className="font-medium">Total Score Additions: </span>
            <span className="font-medium text-green-400">+{impactScoreContributions.bugFailures + impactScoreContributions.fixedFailures}</span>
          </li>
          <li>
            <span className="font-medium">Total Score Subtractions: </span>
            <span className="font-medium text-red-400">
              -{roundToTwo(impactScoreContributions.maintenanceFailures * 2 + impactScoreContributions.retriedFailures / 10)}
            </span>
          </li>
        </ul>
        <div className="italic text-sm font-medium text-red-600 w-full flex flex-col justify-end items-end flex-1">
          <span>Complex impact scores are highly experimental.</span>
          <span>Take them with a grain of salt.</span>
        </div>
      </div>
    </div>
  );
}
