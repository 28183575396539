import dayjs from 'dayjs';
import { sendPostRequest, sendPutRequest } from '../../../../utils/tanstackNetwork';

/**
 * Calculate the total number of tests completed for the given subMilestones.
 * @param {import('../types').MilestoneProgress[]} subMilestones - An array of subMilestones.
 * @returns {number} The total number of tests completed.
 */
export function getTotalTestsCompleted(subMilestones) {
  return subMilestones.map((subMilestone) => subMilestone.actualTests).reduce((acc, actualTests) => acc + actualTests, 0);
}

/**
 * Calculate the required test velocity to meet the milestone's target test count.
 * @param {import('../types').Milestone} milestone - The milestone object.
 * @param {number} totalTestsCompleted - The current number of active tests.
 * @returns {number} The required test velocity.
 */
export function getRequiredVelocity(milestone, totalTestsCompleted) {
  const remainingWeeks = milestone.subMilestones.filter((sm) => dayjs(sm.dueDate).isAfter(dayjs().startOf('day'))).length;
  const remainingTests = milestone.targetTestCount - totalTestsCompleted;

  return +(remainingTests / remainingWeeks).toFixed(2);
}

/**
 * Calculate the current average test velocity based on past subMilestones.
 * @param {import('../types').Milestone} milestone - The milestone object.
 * @returns {number} The current average test velocity.
 */
export function getCurrentAvgVelocity(milestone) {
  // Filter for subMilestones that have already passed their due date
  const validSubMilestones = milestone.subMilestones.filter((subMilestone) => dayjs(subMilestone.dueDate).isBefore(dayjs()));
  const totalTestsCompleted = getTotalTestsCompleted(validSubMilestones);
  const numberOfWeeks = validSubMilestones.length;

  if (numberOfWeeks === 0) return 0;

  return +(totalTestsCompleted / numberOfWeeks).toFixed(2);
}

/**
 * Get the last incomplete milestone from an array of milestones.
 * @param {import('../types').Milestone[]} milestones - An array of milestones.
 * @returns {import('../types').Milestone|undefined} The last incomplete milestone or undefined if all milestones are complete.
 */
export function getLastIncompleteMilestone(milestones) {
  // Filter out completed milestones and keep only the incomplete ones
  const incompleteMilestones = milestones
    .filter((milestone) => !milestone.completedAt)
    // Sort the incomplete milestones by their externalDueDate in ascending order
    .sort((a, b) => dayjs(a.externalDueDate).diff(dayjs(b.externalDueDate)));

  // If there are no incomplete milestones, return undefined
  if (incompleteMilestones.length === 0) {
    return;
  }

  // Return the last incomplete milestone
  return incompleteMilestones.at(-1);
}

/**
 * Validate the milestone data and return an errors object if there are validation errors.
 * @param {object} data - The milestone data.
 * @param {string} data.name - The name of the milestone.
 * @param {string} data.notes - The notes for the milestone.
 * @param {string} data.targetTestCount - The target test count for the milestone.
 * @param {dayjs.Dayjs} data.startDate - The start date for the milestone.
 * @param {dayjs.Dayjs} data.externalDueDate - The external due date for the milestone.
 * @returns {object|null} An object containing validation errors or null if there are no errors.
 */
export function validateMilestone(data) {
  const { name, notes, targetTestCount, startDate, externalDueDate } = data;

  const errors = {
    name: '',
    targetTestCount: '',
    startDate: '',
    externalDueDate: '',
  };

  // Validate the name field
  if (!name) {
    errors.name = 'Name is required';
  }

  if (!notes) {
    errors.notes = 'Please add details to the notes field';
  }

  // Validate the target test count field
  if (!targetTestCount) {
    errors.targetTestCount = 'Target Test Count is required';
  }

  // Validate the start date field
  if (!startDate) {
    errors.startDate = 'Start Date is required';
  }

  // Validate the external due date field
  if (!externalDueDate) {
    errors.externalDueDate = 'External Due Date is required';
  }

  // Check if the external due date is before the start date
  if (externalDueDate && dayjs(externalDueDate).isBefore(startDate)) {
    errors.externalDueDate = 'External Due Date must be after Start Date';
  }

  // Return the errors object if there are any errors, otherwise return null
  return errors.name || errors.notes || errors.targetTestCount || errors.startDate || errors.externalDueDate ? errors : null;
}

/**
 * Check if a milestone is current based on its external due date and start date.
 * @param {import('../types').Milestone} milestone - The milestone object.
 * @returns {boolean} Whether the milestone is current.
 */
export function getIsCurrent(milestone) {
  return dayjs(milestone.externalDueDate).isSameOrAfter(dayjs()) && dayjs(dayjs()).isSameOrAfter(milestone.startDate);
}

/**
 * Get the styles for a subMilestone based on its required velocity.
 * @param {import('../types').MilestoneProgress} subMilestone - The subMilestone object.
 * @returns {object} The styles for the subMilestone.
 */
export function getSubmilestoneStyles(subMilestone) {
  // #2DAD55 = Green/At or above required velocity
  // #D06461 = Red/Below required velocity
  const isAtOrAboveTarget = subMilestone.actualTests >= subMilestone.targetTests;
  const colorMap = {
    present: {
      rowBgColor: 'white',
      testsBgColor: isAtOrAboveTarget ? '#2DAD55' : ' #D06461',
      weekFontWt: 'medium',
      weekFontClr: 'black',
    },
    past: {
      rowBgColor: 'unset',
      testsBgColor: isAtOrAboveTarget ? '#2DAD55' : ' #D06461',
      weekFontWt: 'unset',
      weekFontClr: 'DarkSlateGray',
    },
    future: {
      rowBgColor: 'unset',
      testsBgColor: 'gray',
      weekFontWt: 'unset',
      weekFontClr: 'gray',
    },
  };

  const thisWeek = dayjs().startOf('week');
  const isPresentWeek = dayjs(subMilestone.dueDate).isSame(thisWeek, 'week');
  const isPastWeek = dayjs(subMilestone.dueDate).isBefore(thisWeek, 'week');

  const key = isPresentWeek ? 'present' : isPastWeek ? 'past' : 'future';

  return colorMap[key];
}

/**
 * For overriding various MUI form styles.
 */
export const styles = {
  // Removes the blue outline on focus
  '& .MuiOutlinedInput-input ': { boxShadow: 'none' },

  // Removes the arrows from number inputs
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
};

/**
 * Send a request to create a milestone.
 * @param {object} reqBody - The request body for creating a milestone.
 * @returns {Promise<void>} A promise that resolves when the request is complete.
 */
export async function milestoneCreateReq(reqBody) {
  await sendPostRequest('/milestones/create', reqBody);
}

/**
 * Send a request to create a milestone.
 * @param {object} reqBody - The request body for creating a milestone.
 * @returns {Promise<void>} A promise that resolves when the request is complete.
 */
export async function milestoneUpdateReq(reqBody) {
  await sendPutRequest('/milestones/:milestoneId/create', reqBody);
}
