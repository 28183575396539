import { redirect } from 'react-router-dom';
import { sendGetRequest } from '../../utils/network';

export function logout() {
  localStorage.clear();
  return redirect('/login');
}

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export async function tokenVerification({ params }) {
  let token = params.token;

  try {
    // localStorage.removeItem('token'); // Forget about previous sessions

    if (!token) {
      throw new Error('Missing login token.');
    }

    let loginResponse = await sendGetRequest(`/login?token=${token}`);
    let sessionToken = loginResponse?.data?.sessionToken;
    if (!sessionToken) {
      throw new Error('Invalid login token - cannot obtain session token.');
    }

    // save token
    localStorage.setItem('token', sessionToken);

    // save user info
    let userEmail = loginResponse?.data?.userEmail;
    let userId = loginResponse?.data?.qawId;
    let userInfo = loginResponse?.data?.user;
    localStorage.setItem('userEmail', userEmail);
    localStorage.setItem('userId', userId);
    localStorage.setItem('user', JSON.stringify(userInfo));

    // save teams
    const formatedTeams = loginResponse?.data?.teams.map((team) => ({
      name: team.lead.name,
      qawId: team.lead.qawId,
      email: team.lead.email,
      teamName: team.name,
      packId: team.pack.lead.teamId,
      imageUrl: team.imageUrl,
      active: true,
      selectedInSettings: false,
    }));
    if (formatedTeams) localStorage.setItem('teams', JSON.stringify(formatedTeams));

    // save qaes and qawUsers
    localStorage.setItem('qaes', JSON.stringify(loginResponse?.data?.qaes));
    localStorage.setItem('qawUsers', JSON.stringify(loginResponse?.data?.qawUsers));

    // wait for 1 second to ensure everything was saved
    await timeout(1000);

    let verifyLoginResponse = await sendGetRequest(`/checklogin`);
    if (!verifyLoginResponse?.data?.logged_in) {
      throw new Error('Invalid login token - failed to verify login.');
    }

    return redirect('/triage');
  } catch (err) {
    // Wait for a few seconds
    let token = localStorage.getItem('token');
    while (!token) {
      await timeout(500);
      token = localStorage.getItem('token');
    }

    return redirect('/triage');
  }
}
