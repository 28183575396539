import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';
import MainDrawer from '../ClientMilestones/MainDrawer';
import ClientSummaryTableToolBar from './ToolBar';
import ProjectPlanStatusCell from './ProjectPlanStatusCell';

const styles = {
  endColumn: {
    sx: {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
};

export default function ClientSummaryTable({ data }) {
  const initialState = {
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
  };

  const columns = useMemo(
    /** @returns {import('material-react-table').MRT_ColumnDef<import('../types').ClientSummaryTableRow>[]} */
    () => {
      return [
        {
          id: 'qawTeam',
          header: 'QAW Team',
          columns: [
            {
              header: 'Lead',
              accessorKey: 'qaLead.name',
              filterVariant: 'multi-select',
              enableResizing: false,
              enableSorting: false,
            },
            {
              header: 'Manager',
              accessorKey: 'qaManager.name',
              filterVariant: 'multi-select',
              enableResizing: false,
              enableSorting: false,
            },
            {
              header: 'CSM',
              accessorKey: 'csm.name',
              filterVariant: 'multi-select',
              enableResizing: false,
              enableSorting: false,
              muiTableBodyCellProps: styles.endColumn,
            },
          ],
        },
        {
          id: 'projectPlan',
          header: 'Project Plan',
          columns: [
            {
              header: 'Client Name',
              accessorKey: 'name',
              filterVariant: 'multi-select',
              enableResizing: false,
            },
            {
              header: 'Status',
              accessorKey: 'isProjectPlanComplete',
              filterVariant: 'checkbox',
              enableResizing: false,
              muiFilterCheckboxProps: {
                title: 'Done?',
              },
              muiTableBodyCellProps: styles.endColumn,
              Cell: ProjectPlanStatusCell,
              minSize: 200,
            },
          ],
        },
        {
          id: 'milestones',
          header: 'Milestones',
          enableColumnActions: true,
          columns: [
            {
              accessorKey: 'isOnTrack',
              header: 'Milestone Status',
              filterVariant: 'checkbox',
              enableResizing: false,
              muiFilterCheckboxProps: {
                title: 'On Track?',
              },
              sortUndefined: 1,
              Cell: ({ row }) =>
                row.original.isOnTrack && row.original.activeMilestone ? 'On Track' : row.original.activeMilestone ? 'Behind' : 'N/A',
            },
            {
              accessorKey: 'weeksRemaining',
              header: 'Weeks Remaining',
              enableColumnFilter: false,
              enableResizing: false,
              sortUndefined: 1,
              Cell: ({ row }) => (row.original.activeMilestone ? row.original.weeksRemaining : 'N/A'),
            },
            {
              accessorKey: 'activeMilestone',
              header: 'Active Milestone',
              accessorFn: (row) => !!row.activeMilestone,
              filterVariant: 'checkbox',
              enableResizing: false,
              muiFilterCheckboxProps: {
                title: 'Has Active Milestone?',
              },
              sortUndefined: 1,
              Cell: ({ row }) => <MainDrawer client={row.original} />,
              minSize: 200,
            },
          ],
        },
      ];
    },
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState,
    columnFilterDisplayMode: 'popover',
    enableColumnResizing: true,
    enableColumnActions: true,
    layoutMode: 'grid',
    // Automatically de-duplicates column filter options
    enableFacetedValues: true,
    renderTopToolbar: () => <ClientSummaryTableToolBar table={table} />,
  });

  return <MaterialReactTable table={table} />;
}
