import Plot from 'react-plotly.js';

const SuiteStatusPieChart = ({ suiteData }) => {
    // Initialize counters
    let completed = 0;
    let superseded = 0;
    let movedToNewerSuite = 0;
    let canceled = 0;
    let incomplete = 0;

    // Count each property
    suiteData.forEach((suite) => {
        if (suite.isCompleted) {
            completed++;
        }
        if (suite.isSuperseded) {
            superseded++;
        }
        if (suite.isMovedToNewerSuite) {
            movedToNewerSuite++;
        }
        if (suite.isCanceled) {
            canceled++;
        }
    });

    // Calculate incomplete (assuming a suite must be either complete or incomplete)
    // Right now we have a lot of null values since these fields were added in later in the week
    incomplete = suiteData.length - (completed + superseded + movedToNewerSuite + canceled);

    // Prepare data for the pie chart
    const data = [{
        values: [completed, superseded, movedToNewerSuite, canceled, incomplete],
        labels: ['Completed', 'Superseded', 'Moved to Newer Suite', 'Canceled', 'Incomplete'],
        type: 'pie',
        textinfo: 'label+percent',
        insidetextorientation: 'radial',
    }];

    return (
        <Plot
            // @ts-ignore
            data={data}
            layout={{
                title: 'Suite Status Distribution',
                height: 400,
                width: 500,
            }}
            useResizeHandler={true} // Ensures the plot resizes on container resize
            style={{ width: "100%", height: "100%" }} // Makes the plot responsive within its container
        />
    );
};

export default SuiteStatusPieChart;
