import { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';

import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Spinner } from 'reactstrap';

import Header from '../../Layout/Header';

export default function TeamInvestigationTaskListFilters({ setAppliedFilters, clients, team }) {
  // get qaes and normalize as filter options
  let qaes = _.sortBy(JSON.parse(localStorage.getItem('qaes')), ['name']);

  const assigneeOptions = qaes
    .filter((qae) => qae.teamId === team.id)
    .map((qae) => ({
      value: qae.qawId,
      label: qae.name,
      checked: false,
      data: { teamId: qae.teamId, avatar: qae.avatar48 },
      param: 'assigneeId',
    }));

  // normalize clients as filter options
  const clientOptions = clients.map((c) => ({
    value: c.name,
    label: c.name,
    checked: false,
    data: c,
    param: 'teamName',
  }));

  const [teamFilterOptions, setTeamFilterFilterOptions] = useState([]);
  const [assigneeFilterOptions, setAssigneeFilterOptions] = useState([]);
  const [clientFilterOptions, setClientFilterOptions] = useState([]);

  useEffect(() => {
    setAssigneeFilterOptions(assigneeOptions);
    setClientFilterOptions(clientOptions);
  }, [clients]);

  useEffect(() => {
    updateAppliedFilters();
  }, [teamFilterOptions, assigneeFilterOptions, clientFilterOptions]);

  /**
   * Handles filter changes for each section
   * @param {string} sectionId
   * @param {string} optionValue
   */
  function handleFilterChange(sectionId, optionValue) {
    // handle select all/deselect all
    const isDeselectAll = optionValue === 'deselectAll';
    const isSelectAll = optionValue === 'selectAll';

    const updateAllOptions = (options, setOptions, checked) => {
      const newOptions = options.map((option) => ({ ...option, checked }));
      setOptions(newOptions);
    };

    const updateOptions = (options, setOptions) => {
      const newOptions = options.map((option) => (option.value === optionValue ? { ...option, checked: !option.checked } : option));
      setOptions(newOptions);
    };

    switch (sectionId) {
      case 'team':
        isDeselectAll || isSelectAll
          ? updateAllOptions(teamFilterOptions, setTeamFilterFilterOptions, isSelectAll ? true : false)
          : updateOptions(teamFilterOptions, setTeamFilterFilterOptions);
        break;
      case 'customer':
        isDeselectAll || isSelectAll
          ? updateAllOptions(clientFilterOptions, setClientFilterOptions, isSelectAll ? true : false)
          : updateOptions(clientFilterOptions, setClientFilterOptions);
        break;
      case 'assignee':
        isDeselectAll || isSelectAll
          ? updateAllOptions(assigneeFilterOptions, setAssigneeFilterOptions, isSelectAll ? true : false)
          : updateOptions(assigneeFilterOptions, setAssigneeFilterOptions);
        break;
      default:
        break;
    }
  }

  const filters = [
    {
      id: 'customer',
      name: 'Customer',
      options: clientFilterOptions,
    },
    {
      id: 'assignee',
      name: 'Assignee',
      options: assigneeFilterOptions,
    },
  ];

  function updateAppliedFilters() {
    const filters = [
      ...teamFilterOptions.filter((option) => option.checked),
      ...clientFilterOptions.filter((option) => option.checked),
      ...assigneeFilterOptions.filter((option) => option.checked),
    ];

    setAppliedFilters(filters);
  }

  // get only the filter options that are checked
  const activeFilters = filters.reduce((acc, filter) => {
    const checkedOptions = filter.options.filter((option) => option.checked);
    return acc.concat(checkedOptions.map((option) => ({ ...option, sectionId: filter.id })));
  }, []);

  return (
    <div className="bg-white">
      <Header text={`Investigation Tasks`} />
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Investigation Filters
        </h2>

        {/* Filters */}
        <div className="border-b border-gray-200 bg-white pb-4">
          <div className="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
            <div className="relative inline-block text-left"></div>

            <div className="hidden sm:block">
              <div className="flow-root">
                <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
                  {/* Team, Customer, & Assignee Filters */}
                  {filters.map((section) => (
                    <Popover key={section.name} className="relative z-20 inline-block px-4 text-left">
                      <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        <span>{section.name}</span>
                        {section.options.filter((option) => option.checked).length > 0 ? (
                          <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                            {section.options.filter((option) => option.checked).length}
                          </span>
                        ) : null}
                        <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-5 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none max-h-[75vh] overflow-auto">
                          <form className="flex-col space-y-4">
                            {section.options.length > 0 ? (
                              <>
                                <div className="flex justify-start items-center">
                                  <input
                                    id={`filter-${section.id}-SelectAll`}
                                    name={`${section.id}[]`}
                                    value={`filter-${section.id}-SelectAll`}
                                    type="checkbox"
                                    checked={section.options.every((option) => option.checked)}
                                    onChange={() =>
                                      handleFilterChange(section.id, section.options.every((option) => option.checked) ? 'deselectAll' : 'selectAll')
                                    }
                                    className="h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-${section.id}-SelectAll`}
                                    className="flex space-x-2 whitespace-nowrap pr-3 text-sm font-medium text-gray-900"
                                  >
                                    <span className="ml-2">{section.options.every((option) => option.checked) ? `Deselect All` : `Select All`}</span>
                                  </label>
                                </div>
                                {section.options.map((option, optionIdx) => (
                                  <div key={option.value} className="flex justify-start items-center">
                                    <input
                                      id={`filter-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      value={option.value}
                                      type="checkbox"
                                      checked={option.checked}
                                      onChange={() => handleFilterChange(section.id, option.value)}
                                      className="h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-${section.id}-${optionIdx}`}
                                      className="flex space-x-2 whitespace-nowrap pr-3 text-sm font-medium text-gray-900"
                                    >
                                      <span>
                                        {option.data.avatar && (
                                          <img
                                            className="ml-2 rounded-full h-5 w-5"
                                            src={option.data.avatar}
                                            alt={`${option.label}`}
                                            title={`${option.value}`}
                                          />
                                        )}
                                      </span>
                                      <span>{option.label}</span>
                                    </label>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <Spinner />
                            )}
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ))}
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>

        {/* Active filters */}
        <div className="bg-gray-100">
          <div className="mx-auto max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
            <h3 className="text-sm font-medium text-gray-500">
              Filters
              <span className="sr-only">, active</span>
            </h3>

            <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

            <div className="mt-2 sm:ml-4 sm:mt-0">
              <div className="-m-1 flex flex-wrap items-center">
                {activeFilters.map((activeFilter) => (
                  <span
                    key={activeFilter.value}
                    className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                  >
                    <span>{activeFilter.label}</span>
                    <button
                      type="button"
                      onClick={() => handleFilterChange(activeFilter.sectionId, activeFilter.value)}
                      className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    >
                      <span className="sr-only">Remove filter for {activeFilter.label}</span>
                      <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
