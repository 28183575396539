import { Box, Grid, Typography } from '@mui/material';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';

const ScatterPlotLegend = ({ customerNames, selectedCustomers, onLegendClick }) => {
  const sortedCustomerNames = [...customerNames].sort();

  return (
    <Box sx={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', margin: '10px' }}>
      <Grid container spacing={1}>
        {sortedCustomerNames.map((name, index) => (
          <Grid item key={index} xs={6} sm={4} md={3}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                opacity: selectedCustomers.has(name) ? 1 : 0.5,
              }}
              onClick={() => onLegendClick(name)}
            >
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  backgroundColor: hslStringToRgbString(stringToColor(name)),
                  marginRight: '8px',
                  borderRadius: '50%',
                }}
              />
              <Typography variant="body2">{name}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ScatterPlotLegend;
