import { Disclosure, Menu, Transition } from '@headlessui/react';
import { parseISO, differenceInMinutes } from 'date-fns';
import { Fragment } from 'react';
import { classNames, getBackgroundColor, getElapsed } from '../helpers';
import moment from 'moment';
import _ from 'lodash';
import {
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  BellAlertIcon,
  BugAntIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
  HandRaisedIcon,
  InboxArrowDownIcon,
  MinusSmallIcon,
  NoSymbolIcon,
  PlayCircleIcon,
  PlusSmallIcon,
  RocketLaunchIcon,
  TrashIcon,
  UserGroupIcon,
  UsersIcon,
  WrenchIcon,
  XMarkIcon,
  PencilSquareIcon,
  BoltIcon,
  BoltSlashIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/outline';

function computeInvestigationProgress(investigationMetrics) {
  const {
    workflowsDiagnosedAsBugCount,
    workflowsDiagnosedAsMaintenanceCount,
    workflowsOptedOutOfInvestigationCount,
    workflowsWithInvestigationSkippedCount,
    workflowsWithInvestigationSupersededCount,
    workflowsWithInvestigationSubsequentlyCanceledCount,
    workflowsToInvestigateCount,
    workflowsWithInvestigationMovedCount,
  } = investigationMetrics;

  const reviewedInThisSuiteCount = workflowsDiagnosedAsBugCount + workflowsDiagnosedAsMaintenanceCount + workflowsOptedOutOfInvestigationCount;

  const reviewedOrPassedInSubsequentSuiteCount =
    workflowsWithInvestigationSkippedCount + workflowsWithInvestigationSupersededCount + workflowsWithInvestigationSubsequentlyCanceledCount;

  const toBeReviewedCount = workflowsToInvestigateCount + workflowsWithInvestigationMovedCount;

  const totalCount = reviewedInThisSuiteCount + reviewedOrPassedInSubsequentSuiteCount + toBeReviewedCount;

  const investigationProgressPercent = totalCount === 0 ? 1 : (reviewedInThisSuiteCount + reviewedOrPassedInSubsequentSuiteCount) / totalCount;

  return { investigationProgressPercent, totalCount };
}

// Gets Border Color
function getBorderColor(bucket) {
  switch (bucket) {
    case 1:
      return 'border-red-500'; // Red for bucket 1
    case 2:
      return 'border-pink-300'; // Pink for bucket 2
    case 3:
      return 'border-yellow-300'; // Yellow for bucket 3
    case 4:
      return 'border-green-500'; // Green for bucket 4
    default:
      return ''; // No additional border color if no bucket or undefined
  }
}

/**
 * Renders an InvestigationCard component.
 * @param {object} props - The component props.
 * @param {import('../../../types').QAWTask} props.task - The task object.
 * @param {Function} props.toggleClaimed - The function to toggle the claimed status.
 * @param {string} props.userEmail - The user's email.
 * @param {boolean} [props.nested] - Indicates if the card is nested.
 * @param {Function} props.suiteAction - The function to perform an action on the suite.
 * @param {Function} props.setShowNotesModal - The function to set the visibility of the notes modal.
 * @param {boolean} [props.showNotesModal] - Indicates if the notes modal is visible.
 * @param {Function} props.setNotesModalData - The function to set the data for the notes modal.
 * @param {Function} props.setEditingNotes - The function to set the editing state of the notes.
 * @param {boolean} props.hasUserClaimedMaxTasks - Indicates if the user has claimed the max number of tasks.
 * @returns {import('react').JSX.Element} The rendered InvestigationCard component.
 */
export function InvestigationCard({
  task,
  toggleClaimed,
  userEmail,
  nested = false,
  suiteAction,
  setShowNotesModal,
  showNotesModal,
  setNotesModalData,
  setEditingNotes,
  hasUserClaimedMaxTasks,
}) {
  const failuresUrl = `https://app.qawolf.com/suites/${task.suite.id}?investigationStatus=investigating&tab=failed`;

  const { totalCount: originalFailures, investigationProgressPercent } = computeInvestigationProgress(task.suite.investigationMetrics);
  const running = task.suite.statusCounts.created ?? 0;
  const canceled = task.suite.statusCounts.canceled ?? 0;
  const investigationProgress = `${Math.min(investigationProgressPercent * 100, 100).toFixed()}%`;
  const thisSuiteAge = getElapsed(parseISO(task.createdAt));
  const isDeployRun = Boolean(task.suite.trigger?.deploymentProvider);
  const isTimedRun = Boolean(task.suite.trigger?.nextAt);
  const isManualRun = Boolean(task.suite.trigger?.manuallyCreatedBy);
  const isCurrentUser = userEmail === task.assignedTo.email;
  const envNotes = task.suite.environment?.notes ? JSON.parse(task.suite.environment.notes) : {};
  const envNotesLastUpdatedAt = task.suite.environment?.notesUpdatedAt;
  const backgroundColor = getBackgroundColor({
    priority: task.priority,
    type: task.type,
    subType: task.subType,
    isCurrentUser,
  });
  const suiteAge =
    nested || !task.childTasks?.length
      ? getElapsed(parseISO(task.createdAt))
      : getElapsed(parseISO(_.orderBy(task.childTasks, 'createdAt', 'asc')[0].createdAt));
  const failuresTitle =
    `Original failures: ${originalFailures} | Todo: ${
      task.suite.statusCounts.fail - task.suite.statusCounts.bug - task.suite.statusCounts.maintenance
    } | Running: ${running}` + ` | Bug: ${task.suite.statusCounts.bug} | Maintenance: ${task.suite.statusCounts.maintenance}`;

  const hasOlderSuites = task.childTasks?.length > 0;
  const numberOfHandoffs = task.handovers;
  const claimedForOverAnHour = !nested && task.lastAssignedAt && differenceInMinutes(new Date(), parseISO(task.lastAssignedAt)) > 60;
  const timeSinceClaimed = !nested && task.lastAssignedAt && getElapsed(parseISO(task.lastAssignedAt));

  const deployTypeIcon = isDeployRun ? (
    <BellAlertIcon className="w-5 h-5 text-red-500 mr-1" title="Deploy Trigger" />
  ) : isManualRun ? (
    <HandRaisedIcon className="w-5 h-5 text-yellow-500 mr-1" title={`Manual run deployed by ${task.suite.trigger?.manuallyCreatedBy}`} />
  ) : isTimedRun ? (
    <ClockIcon className="w-5 h-5 text-gray-900 mr-1" title="Timer Trigger" />
  ) : null;

  const slaIcon = task.team.slaReady ? (
    <BoltIcon
      className="w-5 h-5 text-green-500 mr-1"
      title={`SLA Ready${!nested ? `\n\nSort Info:\nScore - ${task.sortingScore.score}\n${task.sortingScore.reasoning.join(`\n`)}` : ''}`}
    />
  ) : (
    <BoltSlashIcon
      className="w-5 h-5 text-red-500 mr-1"
      title={`Not SLA Ready${!nested ? `\n\nSort Info:\nScore - ${task.sortingScore.score}\n${task.sortingScore.reasoning.join(`\n`)}` : ''}`}
    />
  );

  // only show unclaim button if assigned to current user
  const unclaimButton = isCurrentUser && (
    <button
      className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20"
      onClick={() => toggleClaimed(task.id, false)}
    >
      Unclaim
    </button>
  );

  // Get border color
  const borderColor = getBorderColor(task.sortingScore.bucket); // Get the border color based on the bucket

  // if assigned to someone other than current user, show claim button. else show urgent button
  const middleOption =
    task.assignedTo.name && userEmail !== task.assignedTo.email ? (
      <Menu.Item>
        {({ active }) => (
          <button
            type="button"
            className={classNames(
              'w-full flex px-3 py-1 text-sm leading-6',
              active ? 'bg-gray-100' : '',
              hasUserClaimedMaxTasks ? 'cursor-not-allowed text-gray-300' : 'text-yellow-900',
            )}
            title={hasUserClaimedMaxTasks ? 'Please complete claimed tasks first before claiming more' : 'Claim Task'}
            onClick={() => toggleClaimed(task.id, true, failuresUrl)}
          >
            <InboxArrowDownIcon className="h-5 w-5 pt-1 mr-1" /> Claim
          </button>
        )}
      </Menu.Item>
    ) : (
      <Menu.Item>
        {({ active }) => (
          <button
            type="button"
            className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-yellow-900')}
            onClick={() => suiteAction(task.id, '/urgent')}
          >
            <RocketLaunchIcon className="h-5 w-5 pt-1 mr-1" /> Urgent
          </button>
        )}
      </Menu.Item>
    );

  return (
    <div
      className={`rounded-xl ${borderColor} ${
        task.sortingScore.isUrgentTeam === 1 || task.sortingScore.isUrgentTrigger === 1 ? 'border-2' : 'border'
      } bg-white`}
    >
      <div className={classNames('rounded-t-xl flex items-center gap-x-4 border-b border-gray-900/5 p-4', backgroundColor)}>
        <div className="w-full grid grid-cols-5 ">
          <div className="overflow-hidden flex justify-start text-sm font-medium col-span-3">
            <img
              className="h-6 w-6 rounded-full mr-2"
              src={task.primaryQaTeam?.imageUrl || 'https://cdn-icons-png.flaticon.com/512/3524/3524335.png'}
              alt={`${task.primaryQaTeam?.teamName} Team - ${task.primaryQaTeam?.name}`}
              title={`${task.primaryQaTeam?.teamName} Team - ${task.primaryQaTeam?.name}`}
            />
            {slaIcon}
            {deployTypeIcon}
            <p className="truncate flex ext-gray-900 max-w-2/5" title={`${task.team.name} | ID ${task.id}`}>
              {task.team.name}
            </p>

            <p className="truncate max-w-1/5 ml-1 text-gray-500" title={task.suite.environment?.name}>
              {task.suite.environment?.name}
            </p>
          </div>

          <div className="flex justify-end col-span-2 xl:gap-1 2xl:gap-2">
            {!task.assignedTo.name && (
              <button
                className={classNames(
                  'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset',
                  !hasUserClaimedMaxTasks
                    ? 'text-blue-700 bg-blue-50 ring-blue-600/20 hover:ring-2 hover:bg-blue-100'
                    : 'cursor-not-allowed text-gray-400 bg-gray-50 ring-gray-600/20',
                )}
                disabled={hasUserClaimedMaxTasks}
                title={hasUserClaimedMaxTasks ? 'Please complete claimed tasks first before claiming more' : 'Claim Task'}
                onClick={() => {
                  console.log();
                  toggleClaimed(task.id, true, failuresUrl);
                }}
              >
                Claim
              </button>
            )}
            {task.assignedTo.name && (
              <div className="flex justify-right xl:gap-1 2xl:gap-2">
                <img className="h-6 w-6 rounded-full" src={task.assignedTo.avatarUrl} alt={task.assignedTo.name} title={task.assignedTo.name} />
                <h3 className={classNames('truncate text-sm', 'font-medium text-gray-900')}>{task.assignedTo.name}</h3>
              </div>
            )}
            {(envNotes?.triageNotes || envNotes?.bugNotes) && (
              <PencilSquareIcon
                className={classNames(
                  'w-11 h-6 rounded-md px-1 text-xs font-medium text-blue-700 bg-blue-50 hover:cursor-pointer hover:bg-blue-100',
                  moment(envNotesLastUpdatedAt).isAfter(moment().subtract(7, 'days'))
                    ? 'py-0.5 border-2 border-red-500 hover:border-red-400'
                    : 'py-1 ring-blue-600/20 ring-1 ring-inset hover:ring-2 hover:bg-blue-100',
                )}
                onClick={() => {
                  setNotesModalData({
                    qawId: task.team.id,
                    taskId: task.id,
                    env: { id: task.suite.environment.id, name: task.suite.environment?.name },
                    notes: envNotes,
                  });
                  setShowNotesModal(!showNotesModal);
                }}
              ></PencilSquareIcon>
            )}
            {unclaimButton}
            <a
              href={failuresUrl}
              target="_blank"
              rel="noreferrer"
              className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
            >
              See
            </a>
            <Menu
              as="div"
              className="relative rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
            >
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <EllipsisHorizontalIcon className="h-4 w-4" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-gray-900')}
                        onClick={() => suiteAction(task.id, '/check')}
                      >
                        <ArrowPathIcon className="h-5 w-5 pt-1 mr-1" /> Check
                      </button>
                    )}
                  </Menu.Item>
                  {middleOption}
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-blue-500')}
                        onClick={() => {
                          setNotesModalData({
                            qawId: task.team.id,
                            taskId: task.id,
                            env: { id: task.suite.environment.id, name: task.suite.environment?.name },
                            notes: envNotes,
                          });
                          setEditingNotes(true);
                          setShowNotesModal(!showNotesModal);
                        }}
                      >
                        <PencilSquareIcon className="h-5 w-5 pt-1 mr-1" /> Edit Notes
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-red-600')}
                        onClick={() => suiteAction(task.id, '/ignore')}
                      >
                        <TrashIcon className="h-5 w-5 pt-1 mr-1" /> Delete
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 p-4 text-sm leading-6">
        <div className="py-2">
          <div className="flex justify-between w-full pb-2" title={failuresTitle}>
            <div className="flex w-full">
              <div className="h-2 w-1/2 rounded-full bg-gray-200 mt-2">
                <div className="h-2 rounded-full bg-indigo-600" style={{ width: investigationProgress }} />
              </div>
              <p className="ml-2">{investigationProgress} Complete.</p>
            </div>
            {numberOfHandoffs > 1 && (
              <div className={classNames('flex ml-4 text-yellow-500')}>
                {numberOfHandoffs < 3 ? (
                  <UsersIcon
                    className={classNames('w-5 h-5  mt-0.5 mr-1 text-yellow-500')}
                    title={`This task was claimed and unclaimed ${numberOfHandoffs} times. Users: ${task.usersList}`}
                  />
                ) : (
                  <UserGroupIcon
                    className={classNames('w-5 h-5  mt-0.5 mr-1 text-yellow-500')}
                    title={`This task was claimed and unclaimed ${numberOfHandoffs} times. Users: ${task.usersList}`}
                  />
                )}
                <p title={`This task was claimed and unclaimed ${numberOfHandoffs} times. Users: ${task.usersList}`}>{numberOfHandoffs}</p>
              </div>
            )}
          </div>
          <dt className="flex justify-between gap-x-3 text-gray-500 -ml-1">
            <div className="flex w-1/2" title={failuresTitle}>
              <XMarkIcon className="w-6 h-6 text-red-500" />
              <p className="-ml-0.5">{task.suite.statusCounts.fail - task.suite.statusCounts.bug - task.suite.statusCounts.maintenance}</p>
              {task.suite.statusCounts.bug > 0 && (
                <>
                  <BugAntIcon className="w-5 h-5 mt-0.5 mx-1 text-red-800" />
                  <p className="-ml-0.5">{task.suite.statusCounts.bug}</p>
                </>
              )}
              {task.suite.statusCounts.maintenance > 0 && (
                <>
                  <WrenchIcon className="w-5 h-5 mt-0.5 mx-1 text-blue-500" />
                  <p className="-ml-0.5">{task.suite.statusCounts.maintenance}</p>
                </>
              )}
              {canceled > 0 && (
                <>
                  <NoSymbolIcon className="w-5 h-5 text-red-500 mt-0.5 ml-1" />
                  <p className="ml-0.5">{canceled}</p>
                </>
              )}
              {running > 0 && (
                <>
                  <PlayCircleIcon className="w-6 h-6 text-yellow-500 ml-1" />
                  <p className="ml-0.5">{running}</p>
                </>
              )}
              <p className="truncate ml-2 font-medium text-gray-500" title={task.suite.trigger?.name}>
                {task.suite.trigger?.name}
              </p>
            </div>
            <div className={classNames('flex')}>
              <ClockIcon className={classNames('w-5 h-5  mt-0.5 mr-1')} title={`Most recent deploy failure: ${thisSuiteAge}`} />
              <p title={`Most recent failures: ${thisSuiteAge}`}>{thisSuiteAge}</p>

              {hasOlderSuites && (
                <>
                  <Square3Stack3DIcon
                    className={classNames('w-5 h-5  mt-0.5 ml-2 mr-1 text-red-500', hasOlderSuites ? 'text-red-500' : 'text-gray-500')}
                  />
                  <p className={classNames(hasOlderSuites ? 'text-red-500' : 'text-gray-500')} title={`Oldest failures: ${thisSuiteAge}`}>
                    {suiteAge}
                  </p>
                </>
              )}
              {timeSinceClaimed && (
                <div className={classNames('flex ml-4', claimedForOverAnHour ? 'text-yellow-500' : 'text-gray-500')}>
                  <ArrowPathRoundedSquareIcon
                    className={classNames('w-5 h-5  mt-0.5 mr-1', claimedForOverAnHour ? 'text-yellow-500' : 'text-gray-500')}
                    title={`Time since claimed: ${timeSinceClaimed}`}
                  />
                  <p title={`Time since claimed: ${timeSinceClaimed}`}>{timeSinceClaimed}</p>
                </div>
              )}
            </div>
          </dt>
        </div>
      </dl>
      {hasOlderSuites && (
        <div className="rounded-b-xl bg-gray-200 text-center">
          <div className="text-sm">
            <Disclosure as="div" key={`${task.suite.id}-disclosure`}>
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="inline-flex items-center font-medium text-indigo-600 hover:text-indigo-500">
                      <span>{task.childTasks.length} Overriden Suites</span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? <MinusSmallIcon className="h-6 w-6" aria-hidden="true" /> : <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2">
                    {task.childTasks.map((oldSuite) => (
                      <div key={oldSuite.id} className="p-2">
                        <InvestigationCard
                          task={oldSuite}
                          nested={true}
                          toggleClaimed={toggleClaimed}
                          userEmail={userEmail}
                          suiteAction={suiteAction}
                          setShowNotesModal={setShowNotesModal}
                          setNotesModalData={setNotesModalData}
                          setEditingNotes={setEditingNotes}
                          hasUserClaimedMaxTasks={hasUserClaimedMaxTasks}
                        />
                      </div>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      )}
    </div>
  );
}
