import { useMemo } from 'react';

import CardBase from './CardBase';

import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';

import { WOLF_LOGO } from '../../../constants';

/**
 * Pack card for metrics playground legend
 * @param {object} props
 * @param {import('../types').ActiveCard} props.card
 * @param {{[key: string]: boolean}} props.visibilityProps
 * @param {import('react').Dispatch<import('react').SetStateAction<{[key: string]: boolean}>>} props.visibilitySetter
 * @param {{hover: string | null}} props.hoverProps
 * @param {import('react').Dispatch<import('react').SetStateAction<{hover: string | null}>>} props.hoverSetter
 * @returns {import('react').JSX.Element}
 */
export default function AggregateQAECard({ card, visibilityProps, visibilitySetter, hoverProps, hoverSetter }) {
  const noNameProvidedPlaceholder = 'Un-Named Team/QAEs Aggregate Card';
  const cardId = `${card.cardSetId}:${card.cardName || noNameProvidedPlaceholder}`;

  // handle no image url
  if (!card.imageUrl) {
    card.imageUrl = WOLF_LOGO;
  }

  // build team toggles from data
  const teamToggles = useMemo(
    /** @returns {import('../types').CustomLegendToggle[]} */
    () => [
      // impact score
      {
        value: 'Aggregated Average Impact Score',
        color: hslStringToRgbString(stringToColor(`${cardId}:Aggregated Average Impact Score`)),
        type: 'pack',
        identifier: `${cardId}:Aggregated Average Impact Score`,
      },

      // investigation
      {
        value: 'Aggregated Total Tests Investigated', // across all QAEs in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Aggregated Total Tests Investigated`)),
        type: 'pack',
        identifier: `${cardId}:Aggregated Total Tests Investigated`,
      },
      {
        value: 'Aggregated Average Tests Investigated', // per QAE in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Aggregated Average Tests Investigated`)),
        type: 'pack',
        identifier: `${cardId}:Aggregated Average Tests Investigated`,
      },
      {
        value: 'Aggregated Average Tests Investigated Per Hour', // per QAE in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Aggregated Average Tests Investigated Per Hour`)),
        type: 'pack',
        identifier: `${cardId}:Aggregated Average Tests Investigated Per Hour`,
      },

      // creation
      {
        value: 'Aggregated Total Tests Created', // across all QAEs in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Aggregated Total Tests Created`)),
        type: 'pack',
        identifier: `${cardId}:Aggregated Total Tests Created`,
      },
      {
        value: 'Aggregated Total Tests Planned', // across all QAEs in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Aggregated Total Tests Planned`)),
        type: 'pack',
        identifier: `${cardId}:Aggregated Total Tests Planned`,
      },
      {
        value: 'Aggregated Average Tests Created', // per QAE in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Aggregated Average Tests Created`)),
        type: 'pack',
        identifier: `${cardId}:Aggregated Average Tests Created`,
      },
      {
        value: 'Aggregated Average Tests Created Per Hour', // per QAE in group
        color: hslStringToRgbString(stringToColor(`${cardId}:Aggregated Average Tests Created Per Hour`)),
        type: 'pack',
        identifier: `${cardId}:Aggregated Average Tests Created Per Hour`,
      },
    ],
    [card.cardName],
  );

  return (
    <CardBase
      card={card}
      toggles={teamToggles}
      visibilityProps={visibilityProps}
      visibilitySetter={visibilitySetter}
      hoverProps={hoverProps}
      hoverSetter={hoverSetter}
    />
  );
}
