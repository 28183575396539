import dayjs, { Dayjs } from 'dayjs';
import { sendGetRequest } from '../../../../utils/network';

/**
 * @typedef {object} CountsObject
 * @property {number} totalTasks
 * @property {object} assignees
 * @property {object} teams
 * @property {object} qaTeams
 */

/**
 * @typedef {object} AveragesObject
 * @property {number} allTasks
 * @property {object} assigneeTasks
 * @property {object} teamTasks
 * @property {object} qaTeamTasks
 */

/**
 * Fetch paginated investigation metrics from API
 * @param {*} filters
 * @param {{start: Dayjs, end: Dayjs}} dateRange
 * @param {number} page
 * @param {number} limit
 * @returns {Promise<{ tasks: Array, counts: CountsObject, avgDurations: AveragesObject, currentPage: number, totalPages: number }>} investigationMetrics.data
 */
export async function getInvestigationMetrics(filters, dateRange, page, limit) {
  let { start, end } = dateRange;
  start = start.startOf('day');
  end = end.endOf('day');

  // define query based on filters & page/limit
  const query = filters.length
    ? `${filters.map((filter) => `${filter.param}=${filter.value}`).join('&')}&gte=${start}&lte=${end}&page=${page}&limit=${limit}`
    : `gte=${start}&lte=${end}&page=${page}&limit=${limit}`;

  const investigationMetricsResponse = await sendGetRequest(`/investigation-metrics?${query}`);

  return investigationMetricsResponse.success ? investigationMetricsResponse.data : { tasks: [], totalPages: 1 };
}

/**
 * Calculate and format task duration in hours and minutes
 * @param {*} totalMinutes
 * @returns {string} formatted duration
 */
export function formatDuration(totalMinutes) {
  const duration = dayjs.duration(totalMinutes, 'minutes');
  const hours = duration.hours();
  const minutes = duration.minutes();

  return hours > 0 || hours < 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
}
