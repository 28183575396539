import _ from 'lodash';
import { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';

import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';

import { computeInvestigationAggregatesByWeek, getDisplayName, storageMap } from '../helpers';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import { classNames } from '../../InvestigationBoard/helpers';

export default function WeeklyInvestigationChart({ data, filters, handleChangeQAEOnTeam = null, targetQAE = null }) {
  if (!data) {
    return <div>No data provided for investigation chart</div>;
  }

  const dataType = 'qae';

  // get and parse search params
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);

  const buildChartDataFromInvestigationMetrics = (metrics) => {
    const investigationTypeMap = {
      // -- QAE mappings -- //

      // easy numbers
      qaeAssistedInvestigationDataGroupedByWeek: `${targetQAE?.name} | Assisted Investigation | Suites` || null,
      qaeInvestigationDataGroupedByWeek: `${targetQAE?.name} | Primary Investigation | Suites` || null,

      // aggregated numbers
      aggregatedQAEInvestigationDataGroupedByWeek: {
        avgMinsUntilFirstQaeAttempt: (name) => `${name} | Minutes to First QAE Attempt | Average` || 0,
        avgMinsUntilInvestigationStart: (name) => `${name} | Minutes to Investigation Start | Average` || 0,
        avgInvestigationTime: (name) => `${name} | Investigation Minutes | Average` || 0,
        sumFailures: (name) => `${name} | Total Failures Investigated` || 0,
        sumTests: (name) => `${name} | Total Tests Investigated` || 0,
        sumPassedOnFix: (name) => `${name} | Total Passed with Fixes` || 0,
        sumPassedOnFlake: (name) => `${name} | Total Passed on Retry` || 0,
        sumInvestigatedAsBug: (name) => `${name} | Total Investigated as Bug` || 0,
        sumInvestigatedAsMaint: (name) => `${name} | Total Investigated as Maintenance` || 0,
      },

      // -- Team mappings -- //

      // easy numbers
      teamInvestigationDataGroupedByWeek: `${data.team.name} | Primary Investigation | Suites`,
      teamAssistedInvestigationDataGroupedByWeek: `${data.team.name} | Assisted Investigation`,

      // aggregated numbers
      aggregatedTeamInvestigationDataGroupedByWeek: {
        avgMinsUntilFirstQaeAttempt: `${data.team.name} | Minutes to First QAE Attempt | Average` || 0,
        avgMinsUntilInvestigationStart: `${data.team.name} | Minutes to Investigation Start | Average` || 0,
        avgInvestigationTime: `${data.team.name} | Investigation Minutes | Average` || 0,
        avgFailuresPerQAE: `${data.team.name} | Failures Per QAE | Average` || 0,
        avgTestsPerQAE: `${data.team.name} | Tests Per QAE | Average` || 0,
        avgInvestigationAsBugPerQAE: `${data.team.name} | Investigated as Bug | Average` || 0,
        avgInvestigationAsMaintPerQAE: `${data.team.name} | Investigated as Maintenance | Average` || 0,
        avgPassedOnFlakePerQAE: `${data.team.name} | Passed on Retry | Average` || 0,
        avgPassedOnFixPerQAE: `${data.team.name} | Passed with Fixes | Average` || 0,
        sumFailures: `${data.team.name} | Total Failures Investigated` || 0,
        sumTests: `${data.team.name} | Total Tests Investigated` || 0,
        sumPassedOnFix: `${data.team.name} | Total Passed with Fixes` || 0,
        sumPassedOnFlake: `${data.team.name} | Total Passed on Retry` || 0,
        sumInvestigatedAsBug: `${data.team.name} | Total Investigated as Bug` || 0,
        sumInvestigatedAsMaint: `${data.team.name} | Total Investigated as Maintenance` || 0,
      },
      teamAverageInvestigationDataGroupedByWeek: {
        mean: `${data.team.name} | Primary Investigation | Suites | Average`,
        median: `${data.team.name} | Primary Investigation | Suites | Median`,
      },
      teamAverageAssistedInvestigationDataGroupedByWeek: {
        mean: `${data.team.name} | Assisted Investigation | Suites | Average`,
        median: `${data.team.name} | Assisted Investigation | Suites | Median`,
      },
    };

    const chartData = [];
    const allWeeks = _.flatMap(_.omit(metrics, 'team'), (category) => Object.keys(category));
    const allUniqueWeeks = _.uniq(allWeeks);

    for (const week of allUniqueWeeks) {
      const weekObj = { name: week };

      for (const category of Object.keys(metrics)) {
        if (category !== 'team' && metrics[category] && Object.keys(metrics[category]).length > 0) {
          if (category.toLowerCase().includes('average')) {
            weekObj[investigationTypeMap[category].mean] = metrics[category]?.[week]?.mean || 0;
            weekObj[investigationTypeMap[category].median] = metrics[category]?.[week]?.median || 0;
          } else {
            if (targetQAE) {
              // get number of suites for the week
              weekObj[investigationTypeMap[category]] = metrics[category]?.[week]?.length || 0;
            }

            if (category === 'aggregatedQAEInvestigationDataGroupedByWeek') {
              // get all aggregates for the week
              if (targetQAE) {
                for (const k of Object.keys(investigationTypeMap[category])) {
                  weekObj[investigationTypeMap[category][k](targetQAE?.name)] = metrics[category]?.[week]?.[k] || 0;
                }
              } else {
                for (const qae of data.team.members) {
                  for (const k of Object.keys(investigationTypeMap[category])) {
                    weekObj[investigationTypeMap[category][k](qae.name)] = metrics[category]?.[week]?.[k] || 0;
                  }
                }
              }
            }

            // if the category is aggregatedTeamInvestigationDataGroupedByWeek, we need to add the teams aggregate numbers
            if (category === 'aggregatedTeamInvestigationDataGroupedByWeek') {
              // get all aggregates for the week
              for (const k of Object.keys(investigationTypeMap[category])) {
                weekObj[investigationTypeMap[category][k]] = metrics[category]?.[week]?.[k] || 0;
              }
            }

            // if the category is teamInvestigationDataGroupedByWeek and we are not targeting a specific QAE, we need to parse each QAEs contribution
            if (category === 'teamInvestigationDataGroupedByWeek' && !targetQAE) {
              for (const qae of data.team.members) {
                // first handle the QAEs primary investigation suites
                const qaeLabel = `${qae.name} | Primary Investigation | Suites`;
                const qaeInvestigationForWeek = metrics[category]?.[week]?.filter((s) => s.taskClaimedBy === qae.email);
                weekObj[qaeLabel] = qaeInvestigationForWeek?.length || 0;

                // then handle their aggregated data for the week
                const aggregatedQAEInvestigationDataForWeek = computeInvestigationAggregatesByWeek({ [week]: qaeInvestigationForWeek });
                for (const k of Object.keys(aggregatedQAEInvestigationDataForWeek[week])) {
                  weekObj[investigationTypeMap.aggregatedQAEInvestigationDataGroupedByWeek[k](qae.name)] =
                    aggregatedQAEInvestigationDataForWeek[week][k] || 0;
                }
              }
            }

            // if the category is teamAssistedInvestigationDataGroupedByWeek and we are not targeting a specific QAE, we need to parse each QAEs contribution
            if (category === 'teamAssistedInvestigationDataGroupedByWeek' && !targetQAE) {
              for (const qae of data.team.members) {
                const qaeLabel = `${qae.name} | Assisted Investigation`;
                const qaeAssistedInvestigationForWeek = metrics[category]?.[week]?.filter((s) => s.supportedBy.includes(qae.email));
                weekObj[qaeLabel] = qaeAssistedInvestigationForWeek?.length || 0;
              }
            }
          }
        }
      }

      chartData.push(weekObj);
    }

    return chartData;
  };

  const handleLegendEnter = (o) => {
    const { dataKey } = o;

    // if the dataKey is not visible, do not change opacity
    if (!visibility[dataKey]) return;

    // @ts-ignore
    setOpacity((op) => {
      const newOpacity = {};
      Object.keys(op).forEach((key) => {
        newOpacity[key] = key === dataKey ? 1.5 : 0.3;
      });
      return newOpacity;
    });
  };

  const handleLegendLeave = (o) => {
    const { dataKey } = o;

    // if the dataKey is not visible, do not change opacity
    if (!visibility[dataKey]) return;

    // @ts-ignore
    setOpacity((op) => {
      const newOpacity = {};
      Object.keys(op).forEach((key) => {
        newOpacity[key] = 1;
      });
      return newOpacity;
    });
  };

  const handleLegendClick = (o) => {
    const { dataKey } = o;

    setVisibility((vis) => {
      const newVisibility = { ...vis, [dataKey]: !vis[dataKey] };
      sessionStorage.setItem(storageMap[dataType].investigation, JSON.stringify(newVisibility));
      return newVisibility;
    });
  };

  const renderLegend = (props) => {
    const { payload } = props;
    let legendGroups = _.groupBy(payload, (entry) => entry.value.split(' | ')[0]);

    // sort legend groups data
    Object.keys(legendGroups).forEach((g) => {
      legendGroups[g].sort((a, b) => {
        const keyA = a.dataKey.split(' | ')[1].trim();
        const keyB = b.dataKey.split(' | ')[1].trim();
        return keyA.localeCompare(keyB);
      });
    });

    // only show legend for target QAE if specified
    if (targetQAE) {
      legendGroups = _.pickBy(legendGroups, (g) => g.some((e) => e.value.includes(targetQAE.name) || e.value.includes(data.team.name)));
    }

    return (
      <div className="flex">
        <div className={classNames(!targetQAE && 'w-full', 'flex flex-wrap justify-start gap-2 p-2 text-sm')}>
          {Object.keys(legendGroups).map((group, groupIdx) => {
            const groupEntries = legendGroups[group];
            const displayName = getDisplayName(group);
            // determine image based on data type
            return (
              <div className="flex flex-col shadow p-2 min-w-max max-w-max" key={groupIdx}>
                <p className="flex items-center gap-x-4">
                  <img className="rounded-full h-10 w-auto" src={images[group]} alt="avatar" />
                  <span className="font-semibold">{displayName}</span>
                </p>
                <ul>
                  {groupEntries.map((entry, entryIdx) => {
                    return (
                      <li key={entryIdx} className={classNames('flex justify-start my-1 truncate', !visibility[entry.value] && 'text-gray-400')}>
                        <button
                          className="flex items-center"
                          onClick={() => handleLegendClick(entry)}
                          onMouseEnter={() => handleLegendEnter(entry)}
                          onMouseLeave={() => handleLegendLeave(entry)}
                        >
                          <span
                            className="h-3.5 w-3.5 mr-2 rounded-full"
                            style={{ backgroundColor: visibility[entry.value] ? entry.color : '#ccc' }}
                          />
                          <span className="truncate">{entry.value.match(/^[^|]*\| (.*)/)[1]}</span>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        {targetQAE && (
          <div className="flex flex-1 flex-col items-center justify-center space-x-4 p-4">
            <NavLink
              to={`/team-metrics?activeTab=Investigation&teamName=${data.team.name}&gte=${filters.gte}&lte=${filters.lte}`}
              className="flex flex-col justify-center items-center"
            >
              <img src={data.team.imageUrl} alt="avatar" className="h-14 w-auto rounded-full" />
              <span className="font-semibold">{data.team.name}</span>
            </NavLink>
            <div className="flex flex-col justify-center items-center">
              <span className="text-sm text-gray-600">Other members of this team:</span>
              <div className="flex gap-x-2">
                {data.team.members
                  .filter((m) => m.qawId !== targetQAE.qawId)
                  .map((m, mIdx) => (
                    <span key={mIdx} className="text-xs text-gray-400">
                      <button onClick={() => handleChangeQAEOnTeam(m.qawId)}>{m.name.split(' ')[0]}</button>
                    </span>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const chartData = buildChartDataFromInvestigationMetrics(data);

  const sortedChartData = chartData.sort((a, b) => {
    const [yearA, weekA] = a.name.split(' ').map(Number);
    const [yearB, weekB] = b.name.split(' ').map(Number);

    if (yearA === yearB) {
      return weekB - weekA;
    } else {
      return yearB - yearA;
    }
  });

  const images = {};
  for (const member of data.team.members) {
    images[member.name] = member.avatar48;
  }
  images[data.team.name] = data.team.imageUrl;

  // set state with already manipulated data
  const [visibility, setVisibility] = useState(
    paramsMap.visibleLines && paramsMap.visibleLines.split(',').length
      ? _.mapValues(sortedChartData[0], (_, k) => paramsMap.visibleLines.split(',').includes(k))
      : JSON.parse(sessionStorage.getItem(storageMap[dataType].investigation)) ??
          _.mapValues(sortedChartData[0], (_, k) => k.includes('Creation - Tests')),
  );

  const [opacity, setOpacity] = useState(_.mapValues(sortedChartData[0], () => 1));

  useEffect(() => {
    const visibleLines = Object.keys(visibility)
      .filter((key) => visibility[key])
      .join(',');
    searchParams.set('visibleLines', visibleLines);
    setSearchParams(searchParams);
  }, [visibility]);

  return (
    <div className="flex-col items-center justify-center border">
      <ResponsiveContainer width="100%" height={data.team.members.length > 6 ? 1500 : 1200}>
        <LineChart height={600} data={sortedChartData.reverse()} margin={{ right: 40, top: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tickMargin={5} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ textAlign: 'center', width: '100%' }} content={renderLegend} />
          {Object.keys(sortedChartData).length &&
            Object.keys(sortedChartData[0]).map((line, lineIdx) => {
              if (line === 'name') return null;
              if (targetQAE) {
                if (!line.includes(targetQAE.name) && !line.includes(data.team.name)) return null;
              }
              return (
                <Line
                  key={lineIdx}
                  type="monotone"
                  dataKey={line}
                  stroke={hslStringToRgbString(stringToColor(line))}
                  strokeOpacity={line.includes(data.team.name) ? 1 : opacity[line]}
                  strokeWidth={line.includes(data.team.name) ? 4 : opacity[line] === 1.5 ? 3 : 1}
                  hide={!visibility[line]}
                />
              );
            })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
