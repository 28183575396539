import { useQuery } from '@tanstack/react-query';
import '../../../css/gantt.css';
import { sendGetRequest } from '../../../utils/tanstackNetwork';
import ClientSummaryTable from './ClientSummaryTable/ClientSummaryTable';
import WolfLoader from '../../WolfLoader/WolfLoader';

export default function SummaryView() {
  // we don't need to useState for filtered clients. The select function handles filtering clients without a new request each time teamsToShow changes.
  const {
    data: clientData,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ['clientSummaries'],
    queryFn: () => sendGetRequest('/client-summaries'),
    placeholderData: [],
  });

  if (isFetching || isLoading) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  return <ClientSummaryTable data={clientData} />;
}
