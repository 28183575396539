import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';

export default function ExportQaeCostButton({ qaeData }) {
  const headers = ["Team", "QAE", "Position", "Salary"];

  // Helper function to escape double quotes and wrap in double quotes
  const escapeForCsv = (value) => {
    if (value === null || value === undefined) return '';
    const escapedValue = value.toString().replace(/"/g, '""');
    return `"${escapedValue}"`;
  };

  const downloadCsv = () => {
    let csvContent = headers.join(",") + "\n";
    qaeData.forEach((user) => {
      const teamName = escapeForCsv(user.team.name);
      const qaeName = escapeForCsv(user.name);
      const qaePosition = escapeForCsv(user.position);
      const salary = escapeForCsv(user.salary.toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
      const row = [
        teamName,
        qaeName,
        qaePosition,
        salary,
      ];
      csvContent += row.join(",") + "\n";
    });

    // Trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `QAE_Cost_Data.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="absolute top-0 right-0 pt-1 w-10">
        <Button
          startIcon={<FileDownload />}
          onClick={downloadCsv}
        >
        </Button>
    </div>
  );
}
