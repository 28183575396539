import { redirect, useRouteError } from 'react-router-dom';

/**
 * Renders an error page with the provided error information.
 * @returns {import('react').JSX.Element} The error page component.
 */
export default function ErrorPage() {
  const error = useRouteError();
  let status = error?.status || 'Error';
  let statusText = error?.statusText || 'An unexpected error occured.';
  let message = error?.message || "Sorry, an error has occured or we couldn't find the page you're looking for.";

  if (error?.response?.status === 403 || error?.response?.status === 401) {
    localStorage.clear();
    localStorage.setItem('loginSessionError', 'Session Expired. Please log in again.');
    return redirect('/login');
  }

  if (error?.message === 'Network Error') {
    localStorage.clear();
    localStorage.setItem('loginSessionError', 'Server connection error. Please log in again.');
    return redirect('/login');
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <main className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">{status}</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{statusText}</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">{message}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </a>
          </div>
        </div>
      </main>
    </>
  );
}
