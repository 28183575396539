import CardFormBase from './CardFormBase';

/**
 *
 * @param {object} props
 * @param {import('../../MetricsExplorer/types').QATeamFromLocalStorage[]} props.teams
 * @returns {import('react').JSX.Element}
 */
export default function TeamCardForm({ teams }) {
  return (
    <CardFormBase
      items={teams}
      label="teams"
      inputLabel="Teams"
      switchLabel="Include Individual Team Cards"
      secondarySwitchLabel="Include Individual QAEs"
      itemName="teams"
      subSelectName="Teams"
      secondarySubSelectName="QAEs"
      keyName="teamName"
    />
  );
}
