import _ from 'lodash';

import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts';

import { renderPolarAngleAxis } from '../helpers';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';

/**
 * Formats aggregate data into radar chart format
 * @param {import('../types').InvestigationTrackedTimeAggregate[]} investigationData
 * @param {import('../types').TaskSnapshotTrackedTimeAggregate[]} creationData
 * @returns {Array} dataArray
 */
const formatChartData = (investigationData, creationData) => {
  // TODO: Break this down into test step level
  const dataArray = [];

  // get per customer investigation data
  const suitesInvestigated = investigationData.map((d) => d.suites).flat();
  const suitesByCustomer = _.groupBy(suitesInvestigated, 'customer_name');

  // get per customer creation data
  const workflowsCreated = creationData.map((d) => d.workflows).flat();
  const workflowsByCustomer = _.groupBy(workflowsCreated, 'customer_name');

  // get all unique customers
  const allCustomers = _.uniq([...Object.keys(suitesByCustomer), ...Object.keys(workflowsByCustomer)]);

  for (const customerName of allCustomers) {
    dataArray.push({
      name: customerName.replace('Viking Sasquatch', 'VS'),
      investigationValue: _.sumBy(suitesByCustomer[customerName], (s) => s.runs?.length) || 0,
      creationValue: _.sumBy(workflowsByCustomer[customerName], (wf) => wf.details?.workflow.stepCount) * 10 || 0,
    });
  }

  return dataArray;
};

/**
 * Renders a radar chart with the spread of tasks by type
 * @param {object} props
 * @param {{investigation: import('../types').InvestigationTrackedTimeAggregate[], creation: import('../types').TaskSnapshotTrackedTimeAggregate[]}} props.chartData
 * @param {string} props.qaeName
 * @returns {import('react').JSX.Element}
 */
export default function CustomerSpreadRadarChart({ chartData, qaeName }) {
  const { investigation, creation } = chartData;
  const data = formatChartData(investigation, creation);

  return (
    <div className="flex items-center justify-end">
      <RadarChart outerRadius={110} width={750} height={300} data={data} margin={{ top: 5 }}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" tick={(props) => renderPolarAngleAxis(props)} offset={50} />
        <PolarRadiusAxis tick={false} domain={[0, 'dataMax']} />
        <Radar
          name="Investigation"
          dataKey="investigationValue"
          stroke={hslStringToRgbString(stringToColor(qaeName + 'investigation'))}
          fill={hslStringToRgbString(stringToColor(qaeName + 'investigation'))}
          fillOpacity={0.45}
        />
        <Radar
          name="Creation"
          dataKey="creationValue"
          stroke={hslStringToRgbString(stringToColor(qaeName + 'creation'))}
          fill={hslStringToRgbString(stringToColor(qaeName + 'creation'))}
          fillOpacity={0.45}
        />
        <Legend iconType="circle" align="right" verticalAlign="top" layout="vertical" />
      </RadarChart>
    </div>
  );
}
