import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import { formatCamelCaseToTitle } from './ChartHelpers';


const PercentageTrendLine = ({ suiteData, metrics, title }) => {
    const colors = ['#FFA500','#70CF11', '#6A5ACD', '#4682B4', '#FF5733', '#FFD700', '#FF69B4', '#20B2AA'];

    // Sort suiteData by startTime using dayjs
    const sortedSuiteData = suiteData.sort((a, b) => dayjs(a.startTime).valueOf() - dayjs(b.startTime).valueOf());

    // Calculate the number of unique days
    const uniqueDays = new Set(
        sortedSuiteData.map((suite) => dayjs(suite.startTime).format('YYYY-MM-DD')),
    ).size;

    const plotData = metrics.map((metric, index) => {
        let dataPoints = sortedSuiteData.map((suite) => {
            let value = suite[metric];
            // Calculate changeRate dynamically if not present
            if (metric === 'changeRate' && (value === undefined || value === null)) {
                if (suite.numberOfRuns > 0) {
                    value = ((suite.numberOfRunsTriagedAsBug +
                             suite.numberOfRunsTriagedAsMaintenance +
                             suite.numberOfRunsPassingOnQaeFix) /
                             suite.numberOfRuns) * 100;
                    value = parseFloat(value.toFixed(2)); // Ensuring a fixed number of decimal places
                } else {
                    value = 0; // Default to 0 if numberOfRuns is zero to avoid division by zero
                }
            }
            return {
                x: dayjs(suite.startTime).toISOString(),
                y: value,
            };
        }).filter((point) => point.y !== undefined && point.y !== null); // Filter out undefined or null points

        return {
            x: dataPoints.map((point) => point.x),
            y: dataPoints.map((point) => point.y),
            type: 'scatter',
            mode: 'lines+markers',
            line: {
                shape: 'spline',
                smoothing: 1, // Adjust for desired smoothness
                color: colors[index % colors.length],
            },
            marker: {
                color: colors[index % colors.length],
            },
            name: formatCamelCaseToTitle(metric),
        };
    });


    return (
        <Plot
            data={plotData}
            layout={{
                title: title,
                xaxis: {
                    title: 'Date',
                    type: 'date',
                    automargin: true,
                    tickformat: '%d-%m-%Y',
                    nticks: uniqueDays,
                },
                yaxis: {
                    title: 'Percentage',
                    automargin: true,
                    tickformat: ',.2f%',
                    rangemode: 'tozero',
                },
                hovermode: 'closest',
                legend: {
                    orientation: 'h',
                    y: -0.5,
                    x: 0.5,
                    xanchor: 'center',
                },
            }}
            useResizeHandler={true} // Ensures the plot resizes on container resize
            style={{ width: "100%", height: "100%" }} // Makes the plot responsive within its container
        />
    );
};

export default PercentageTrendLine;
