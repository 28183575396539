import { InformationCircleIcon, XCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { classNames } from '../../InvestigationBoard/helpers';
import { useState } from 'react';
import { useTaskFilterCriteria } from '../../context/TaskFilterCriteriaContext';
import { generateWarnings } from './generateWarnings';
import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../../utils/network';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

export default function WarningWidgets({ team, setTableState }) {
  // Get date for the the beginning of the week 8 weeks ago
  const beginningOfThisWeek = dayjs().startOf('week');
  const eightWeeksAgo = beginningOfThisWeek.subtract(8, 'weeks').startOf('week');

  const {
    data: warningsData,
    isFetching,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['clientSummary', eightWeeksAgo, beginningOfThisWeek, team.id],
    queryFn: () => sendGetRequest(`/client-summaries/${team.id}?startDate=${eightWeeksAgo}&endDate=${beginningOfThisWeek}`),
    select: ({ data }) => {
      // Get the last 8 weeks of tasks
      const lastEightWeeksOfTasks = data.TaskSnapshot.filter((task) => dayjs(task.completedAt).startOf('week').isSameOrAfter(eightWeeksAgo));

      // Get the total number of tests created in the last 8 weeks
      const totalTestCount = lastEightWeeksOfTasks
        .filter((task) => task.type === 'testCreation' && task.details.workflow.stepCount)
        .reduce((prev, curr) => prev + curr.details.workflow.stepCount, 0);

      // Calculate the average number of tests created per week
      const eightWeekAvgTestCreation = (totalTestCount / 8).toFixed(2);

      const warnings = generateWarnings({ ...team, crsWithUpdatedDueDates: data.crsWithUpdatedDueDates, eightWeekAvgTestCreation });
      return warnings;
    },
  });

  if (isFetching && isLoading) {
    return <div className="m-2 flex flex-col"></div>;
  }

  if (isError) {
    return <div className="m-2 flex flex-col">{error.message}</div>;
  }

  return (
    <div className="m-2 flex flex-col">
      {_.orderBy(warningsData, 'warning.severity', 'asc').map((w) => (
        <WarningWidget key={w.warning.warningKey} title={w.warning.title} detailsArr={w.warning.detailsArr} severity={w.warning.severity} setTableState={setTableState} />
      ))}
    </div>
  );
}

function WarningWidget({ title, detailsArr, severity, setTableState }) {
  const { updateFilterCriteria } = useTaskFilterCriteria();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleFilterForTask = (d) => {
    if (d.name) {
      // Remove emojis from name
      // eslint-disable-next-line no-useless-escape
      let formattedName = d.name.replace(/[^a-zA-Z0-9.,!?;:'"()\[\]{}<>@#%&*\-_\s]/g, '');

      // Remove leading and trailing whitespace
      formattedName = formattedName.trim();

      // Allow for Coverage Requests and Maintenance Report grouping
      setTableState((tableState) => ({
        ...tableState,
        groupCRandMRs: true,
      }));

      // Update filter criteria for name column
      updateFilterCriteria([{ id: 'name', value: formattedName }]);
    }
  };

  // Severity is low - gray warning
  let bgClass = 'bg-gray-100';
  let iconForWarning = <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
  let titleColorClass = 'text-gray-800';
  let pColorClass = 'text-gray-700';

  // Severity is high - red warning
  if (severity === 'high') {
    bgClass = 'bg-red-50';
    iconForWarning = <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />;
    titleColorClass = 'text-red-800';
    pColorClass = 'text-red-700';
    // Severity is medium - yellow warning
  } else if (severity === 'medium') {
    bgClass = 'bg-yellow-50';
    iconForWarning = <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />;
    titleColorClass = 'text-yellow-800';
    pColorClass = 'text-yellow-700';
  }

  return (
    <div className={classNames('rounded-md m-1 p-2', bgClass)}>
      <div className="flex">
        <div className="flex-shrink-0">{iconForWarning}</div>
        <div className="ml-3">
          <h3 className={classNames('text-sm font-medium', titleColorClass)}>{title}</h3>
          {detailsArr && detailsArr.length > 0 && (
            <div className={classNames('mt-2 text-sm', pColorClass)}>
              <ul role="list" className="list-disc space-y-1 pl-5">
                {_.take(detailsArr, 3).map((d) => (
                  <li key={d.key} onClick={() => handleFilterForTask(d)} className={`${d.name && 'cursor-pointer'}`}>
                    {d.value}
                  </li>
                ))}
                {detailsArr.length > 3 && (
                  <p onClick={handleExpand} className="cursor-pointer text-blue-400">
                    {isExpanded ? 'Hide' : 'Show'} {detailsArr.length - 3} other issues...
                  </p>
                )}
                {detailsArr.length > 3 &&
                  isExpanded &&
                  _.drop(detailsArr, 3).map((d) => (
                    <li key={d.key} onClick={() => handleFilterForTask(d)} className={`${d.name && 'cursor-pointer'}`}>
                      {d.value}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
