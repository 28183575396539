import Plot from 'react-plotly.js';
import { formatCamelCaseToTitle } from './ChartHelpers';


const calculateQuartiles = (data) => {
  const sortedData = [...data].sort((a, b) => a - b);
  const lowerQuartile = sortedData[Math.floor(sortedData.length * 0.33)];
  const upperQuartile = sortedData[Math.floor(sortedData.length * 0.66)];
  const min = sortedData[0];
  const max = sortedData[sortedData.length - 1];
  const median = sortedData[Math.floor(sortedData.length * 0.5)];
  return { min, lowerQuartile, upperQuartile, max, median };
};

const SuiteMetricsBoxPlot = ({ suiteData, metrics, title }) => {
  const colors = ['#FFD700', '#70CF11', '#6A5ACD', '#FF5733', '#4682B4', '#FF69B4', '#20B2AA', '#FFA500'];

  const plotData = metrics.map((metric, index) => {
    const values = suiteData
      .map((suite) => suite[metric])
      .filter((value) => !(metric.toLowerCase().includes('minutes') && value === 0));
    const { min, lowerQuartile, upperQuartile, max, median } = calculateQuartiles(values);

    return {
      y: values,
      type: 'box',
      name: formatCamelCaseToTitle(metric),
      boxpoints: false,
      jitter: 0.5,
      whiskerwidth: 0.2,
      fillcolor: colors[index % colors.length],
      marker: {
        size: 2,
      },
      line: {
        width: 1,
      },
      boxmean: true,
      q1: lowerQuartile,
      median,
      q3: upperQuartile,
      lowerfence: min,
      upperfence: max,
    };
  });

  return (
    <Plot
      data={plotData}
      layout={{
        title: title,
        yaxis: { title: 'Minutes' },
        xaxis: { automargin: true },
        boxmode: 'group',
      }}
      useResizeHandler={true} // Ensures the plot resizes on container resize
      style={{ width: "100%", height: "100%" }} // Makes the plot responsive within its container
    />
  );
};

export default SuiteMetricsBoxPlot;
