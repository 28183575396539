import { useEffect, useRef, useState } from 'react';

import { Bar, Brush, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';

import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import CustomizedLabel from './CustomizedLabel';
import CustomTooltip from './CustomTooltip';

/**
 * Formats aggregate data into bar chart format (suite snapshots)
 * @param {import('../types').InvestigationTrackedTimeAggregate[]} investigationData
 * @param {number} teamSize
 * @returns {{week: string, 'Suites Per Hour': number, 'Average Suites Per Hour': number, 'Failures Per Hour': number, 'Average Failures Per Hour': number, 'Tests Per Hour': number, 'Average Tests Per Hour': number}[]} dataArray
 */
const formatChartData = (investigationData, teamSize) => {
  const dataArray = [];

  // for each week, calculate the number of [suites, failures, tests] per tracked hour
  for (const week of investigationData) {
    const hoursTrackedThisWeek = week.hours_tracked;
    const averageHoursTrackedThisWeek = week.group_total_hours_tracked / teamSize;

    // get qae velocities
    const qaeWeekData = {
      week: week.week,
      'Suites Per Hour': Math.round((week.completed_snapshots / hoursTrackedThisWeek) * 100) / 100,
      'Average Suites Per Hour': Math.round((week.group_total_completed_snapshots / teamSize / averageHoursTrackedThisWeek) * 100) / 100,
      'Failures Per Hour': Math.round((week.total_failures_investigated_this_week / hoursTrackedThisWeek) * 100) / 100,
      'Average Failures Per Hour':
        Math.round((week.group_total_failures_investigated_this_week / teamSize / averageHoursTrackedThisWeek) * 100) / 100,
      'Tests Per Hour': Math.round((week.total_tests_investigated_this_week / hoursTrackedThisWeek) * 100) / 100,
      'Average Tests Per Hour': Math.round((week.group_total_tests_investigated_this_week / teamSize / averageHoursTrackedThisWeek) * 100) / 100,
    };

    // push week data into dataArray
    dataArray.push(qaeWeekData);
  }

  // sort dataArray by week
  dataArray.sort((a, b) => a.week.localeCompare(b.week));

  return dataArray;
};

/**
 *
 * @param {object} props
 * @param {import('../types').InvestigationTrackedTimeAggregate[]} props.chartData
 * @param {import('../types').QATeam} props.team
 * @returns {import('react').JSX.Element}
 */
export default function InvestigationVelocityChart({ chartData, team }) {
  const parentRef = useRef(null);
  const [width, setWidth] = useState(775);

  const qaeData = formatChartData(chartData, team.members.filter((m) => !m.isLead).length);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setWidth(parentRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, []);

  return (
    <div className="flex items-center justify-center" ref={parentRef}>
      <ComposedChart
        width={width}
        height={400}
        data={qaeData}
        margin={{
          top: 20,
          right: 10,
          left: -10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="week" />
        <YAxis domain={[0, 'dataMax']} />
        <Tooltip content={<CustomTooltip />} />
        <Bar stackId="suites" dataKey="Suites Per Hour" fill={hslStringToRgbString(stringToColor('suites'))} opacity={0.65} />
        <Bar stackId="failures" dataKey="Failures Per Hour" fill={hslStringToRgbString(stringToColor('failures'))} opacity={0.65} />
        <Bar stackId="tests" dataKey="Tests Per Hour" fill={hslStringToRgbString(stringToColor('tests'))} opacity={0.65} />
        <Line label={<CustomizedLabel />} dataKey="Average Suites Per Hour" dot={false} stroke={hslStringToRgbString(stringToColor('suites'))} />
        <Line label={<CustomizedLabel />} dataKey="Average Failures Per Hour" dot={false} stroke={hslStringToRgbString(stringToColor('failures'))} />
        <Line label={<CustomizedLabel />} dataKey="Average Tests Per Hour" dot={false} stroke={hslStringToRgbString(stringToColor('tests'))} />
        <Legend
          payload={[
            { value: 'Suites Per Hour', type: 'circle', color: hslStringToRgbString(stringToColor('suites')) },
            { value: 'Failures Per Hour', type: 'circle', color: hslStringToRgbString(stringToColor('failures')) },
            { value: 'Tests Per Hour', type: 'circle', color: hslStringToRgbString(stringToColor('tests')) },
          ]}
        />
        <Brush dataKey={'week'} height={20} stroke={hslStringToRgbString(stringToColor('brush-component'))} />
      </ComposedChart>
    </div>
  );
}
