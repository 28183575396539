import Plot from 'react-plotly.js';
import { Box, Typography } from '@mui/material';

// Function to calculate quartiles for the box plot
const calculateQuartiles = (data) => {
  const sortedData = [...data].sort((a, b) => a - b);
  const lowerQuartile = sortedData[Math.floor(sortedData.length * 0.25)].toFixed(2);
  const upperQuartile = sortedData[Math.floor(sortedData.length * 0.75)].toFixed(2);
  const min = sortedData[0].toFixed(2);
  const max = sortedData[sortedData.length - 1].toFixed(2);
  const median = sortedData[Math.floor(sortedData.length * 0.5)].toFixed(2);
  return { min, lowerQuartile, upperQuartile, max, median };
};

// Function to calculate the percentage of suites in each time window (non-cumulative)
const calculatePercentageInBins = (data, timeKey, interval = 30, cumulative = false) => {
  data = data.filter((d) => d.triageTimeInMinutes > 5);

  const bins = [];
  const maxTime = Math.max(...data.map((item) => item[timeKey]));

  // Create bins for every 30 minutes
  for (let i = 0; i <= maxTime; i += interval) {
    bins.push({ binStart: i, binEnd: i + interval, count: 0 });
  }

  // Count how many suites fall into each bin
  data.forEach((item) => {
    bins.forEach((bin) => {
      if (item[timeKey] >= bin.binStart && item[timeKey] < bin.binEnd) {
        bin.count += 1;
      }
    });
  });

  // Calculate the percentage of suites in each bin
  const totalSuites = data.length;
  const percentages = bins.map((bin, index) => {
    const cumulativeCount = cumulative
      ? bins.slice(0, index + 1).reduce((sum, b) => sum + b.count, 0)
      : bin.count;
    return {
      binStart: bin.binStart,
      binEnd: bin.binEnd,
      percentage: ((cumulativeCount / totalSuites) *100).toFixed(2),
    };
  });

  return percentages;
};

const OverallMetricsPlotForSuites = ({ suiteData }) => {
  // Extract investigation times (triageTimeInMinutes) for suites
  const investigationTimes = suiteData.map((suite) => suite.triageTimeInMinutes);

  // Calculate the quartiles for the box plot
  const quartiles = calculateQuartiles(investigationTimes);

  // Percentage of suites in bins based on investigation times (non-cumulative)
  const suitePercentages = calculatePercentageInBins(suiteData, 'triageTimeInMinutes',30);

  // Cumulative percentage of suites
  const cumulativeSuitePercentages = calculatePercentageInBins(suiteData, 'triageTimeInMinutes',30, true);

  // Extract x and y values for the percentage line plots
  const suitePercentXValues = suitePercentages.map((bin) => `${bin.binStart}-${bin.binEnd}`);
  const suitePercentYValues = suitePercentages.map((bin) => bin.percentage);

  const cumulativeSuiteXValues = cumulativeSuitePercentages.map((bin) => `${bin.binStart}-${bin.binEnd}`);
  const cumulativeSuiteYValues = cumulativeSuitePercentages.map((bin) => bin.percentage);

  return (
    <Box sx={{ padding: '0 40px', paddingTop: '20px' }}>
      {/* Box Plot for Suite Triage Time */}
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>
        Box Plot of Suite Triage Time
      </Typography>

      <Plot
        data={[
          {
            y: investigationTimes,
            type: 'box',
            name: 'Suite Triage Time',
            boxpoints: false,
            jitter: 0.5,
            // @ts-ignore
            whiskerwidth: 0.2,
            fillcolor: '#8884d8',
            marker: {
              size: 2,
            },
            line: {
              width: 1,
            },
            boxmean: true,
            q1: quartiles.lowerQuartile,
            median: quartiles.median,
            q3: quartiles.upperQuartile,
            lowerfence: quartiles.min,
            upperfence: quartiles.max,
          },
        ]}
        layout={{
          title: 'Box Plot of Suite Triage Time',
          yaxis: { title: 'Triage Time (minutes)' },
          xaxis: { automargin: true },
          boxmode: 'group',
        }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />

      {/* Line Graph for Percentage of Suites (Non-Cumulative) */}
      <Typography variant="h6" sx={{ marginTop: '40px', marginBottom: '20px' }}>
        Percentage of Suites with Triage Times in 30-Minute Intervals
      </Typography>

      <Plot
        data={[
          {
            x: suitePercentXValues,
            y: suitePercentYValues,
            type: 'scatter',
            mode: 'lines+markers',
            name: 'Percentage of Suites (Non-Cumulative)',
            line: { shape: 'spline', color: '#8884d8' },
            marker: { color: '#8884d8' },
            hovertemplate: 'Interval: %{x}<br>Percentage of Suites: %{y}%',
          },
        ]}
        layout={{
          title: 'Percentage of Suites with Triage Times in 30-Minute Intervals',
          xaxis: { title: 'Triage Time Interval (minutes)', automargin: true },
          yaxis: { title: 'Percentage of Suites (%)', range: [0, 100], automargin: true },
          hovermode: 'closest',
          legend: { orientation: 'h', y: -0.2, x: 0.5, xanchor: 'center' },
        }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />

      {/* Cumulative Percentage Graph for Suite Triage Time */}
      <Typography variant="h6" sx={{ marginTop: '40px', marginBottom: '20px' }}>
        Cumulative Percentage of Suites with Triage Time
      </Typography>

      <Plot
        data={[
          {
            x: cumulativeSuiteXValues,
            y: cumulativeSuiteYValues,
            type: 'scatter',
            mode: 'lines+markers',
            name: 'Cumulative Suite Triage Time',
            line: { shape: 'spline', color: '#82ca9d' },
            marker: { color: '#82ca9d' },
            hovertemplate: 'Interval: %{x}<br>Cumulative Percentage of Suites: %{y}%',
          },
        ]}
        layout={{
          title: 'Cumulative Percentage of Suites with Triage Time',
          xaxis: { title: 'Triage Time Interval (minutes)', automargin: true },
          yaxis: { title: 'Cumulative Percentage of Suites (%)', range: [0, 100], automargin: true },
          hovermode: 'closest',
          legend: { orientation: 'h', y: -0.2, x: 0.5, xanchor: 'center' },
        }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />
    </Box>
  );
};

export default OverallMetricsPlotForSuites;
