import { useCallback, useEffect } from 'react';

import { classNames } from '../../InvestigationBoard/helpers';

/**
 * Base card for metrics playground legend
 * @param {object} props
 * @param {import('../types').ActiveCard} props.card
 * @param {import('../types').CustomLegendToggle[]} props.toggles
 * @param {{[key: string]: boolean}} props.visibilityProps
 * @param {import('react').Dispatch<import('react').SetStateAction<{[key: string]: boolean}>>} props.visibilitySetter
 * @param {{hover: string | null}} props.hoverProps
 * @param {import('react').Dispatch<import('react').SetStateAction<{hover: string | null}>>} props.hoverSetter
 * @returns {import('react').JSX.Element}
 */
export default function CardBase({ card, toggles, visibilityProps, visibilitySetter, hoverProps, hoverSetter }) {
  // handle hover states for legend toggles
  const handleMouseEnter = useCallback((toggle) => {
    if (!hoverProps[toggle.identifier]) {
      hoverSetter((prev) => ({ ...prev, hover: toggle.identifier }));
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    hoverSetter((prev) => ({ ...prev, hover: null }));
  }, []);

  // handle click actions for legend toggles
  const handleToggleClick = useCallback((toggle) => {
    // set new visibility state and handle side effects
    visibilitySetter((prevState) => {
      const newVisibilityProps = {
        ...prevState,
        [toggle.identifier]: !prevState[toggle.identifier],
      };

      // remove hover state
      hoverSetter({ hover: null });

      return newVisibilityProps;
    });
  }, []);

  useEffect(() => {
    // set initial visibility state for all toggles of parent cards
    visibilitySetter((prevState) => {
      const newVisibilityProps = { ...prevState };
      toggles.forEach((toggle) => {
        newVisibilityProps[toggle.identifier] = card.isParent || toggle.identifier.includes('Impact Score');
      });
      return newVisibilityProps;
    });
  }, []);

  return (
    <div className="flex flex-col shadow p-2 min-w-max max-w-max">
      <p className="flex items-center gap-x-4">
        <img className="rounded-full h-10 w-auto" src={card.imageUrl} alt="avatar" />
        <span className="font-semibold">{card.cardName}</span>
      </p>
      <ul>
        {toggles.length &&
          toggles.map((toggle) => {
            return (
              <li
                key={toggle.identifier}
                className={classNames('flex justify-start my-1 truncate', !visibilityProps[toggle.identifier] && 'text-gray-400')}
              >
                <button
                  className="flex items-center"
                  onClick={() => handleToggleClick(toggle)}
                  onMouseEnter={() => handleMouseEnter(toggle)}
                  onMouseLeave={handleMouseLeave}
                >
                  <span
                    className="h-3.5 w-3.5 mr-2 rounded-full"
                    style={{ backgroundColor: visibilityProps[toggle.identifier] ? toggle.color : '#ccc' }}
                  />
                  {/* <span className="truncate">{toggle.value.match(/^[^|]*\| (.*)/)[1]}</span> */}
                  <span className="truncate">{toggle.value}</span>
                </button>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
