import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const DATE_FILTER_TYPES = ['Start Date *', 'End Date *'];

/**
 * General Date Filter
 * @param {object} props
 * @param {{gte: string, lte: string}} props.values
 * @param {function("gte" | "lte", dayjs.Dayjs): void} props.updateFn
 * @returns {import('react').JSX.Element}
 */
export default function DateFilters({ values, updateFn }) {
  return (
    <div className="-mx-5 flex items-center divide-x divide-gray-200">
      {/* Date Filters */}
      {DATE_FILTER_TYPES.map((dateType, dateFilterIdx) => {
        const dateParam = dateType === 'Start Date *' ? 'gte' : 'lte';
        return (
          <div key={dateFilterIdx} className="px-4 w-48">
            <DatePicker
              className="px-4"
              value={dayjs(values[dateParam])}
              label={dateType}
              closeOnSelect
              disableFuture
              sx={{
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' },
              }}
              slotProps={{ textField: { size: 'small', disabled: true } }}
              onChange={(newDate) => updateFn(dateParam, newDate)}
            />
          </div>
        );
      })}
    </div>
  );
}
