import _ from 'lodash';

import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts';

import { renderPolarAngleAxis } from '../helpers';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';

/**
 * Formats aggregate data into radar chart format
 * @param {import('../types').InvestigationTrackedTimeAggregate[]} investigationData
 * @param {import('../types').TaskSnapshotTrackedTimeAggregate[]} creationData
 * @returns {Array} dataArray
 */
const formatChartData = (investigationData, creationData) => {
  const dataArray = [
    {
      type: 'Suites [i]',
      investigationValue: _.sumBy(investigationData, 'completed_snapshots'),
      creationValue: 0,
    },
    {
      type: 'Failures [i] x 10',
      investigationValue: _.sumBy(investigationData, 'total_failures_investigated_this_week') / 10,
      creationValue: 0,
    },
    {
      type: 'Tests ([i] x 20)',
      investigationValue: _.sumBy(investigationData, 'total_tests_investigated_this_week') / 20,
      creationValue: _.sumBy(creationData, (d) => _.sumBy(d.workflows, (wf) => wf.details.workflow.stepCount)),
    },
    {
      type: 'Hours Tracked',
      investigationValue: _.sumBy(investigationData, 'hours_tracked'),
      creationValue: _.sumBy(creationData, 'hours_tracked'),
    },
    {
      type: 'Clients',
      investigationValue: _.uniq(_.flatMap(investigationData, (d) => _.map(d.suites, 'customer_name'))).length,
      creationValue: _.uniq(_.flatMap(creationData, (d) => _.map(d.workflows, 'customer_name'))).length,
    },
    {
      type: 'Workflows [c]',
      investigationValue: 0,
      creationValue: _.sumBy(creationData, (d) => d.workflows.length),
    },
  ];

  return dataArray;
};

/**
 * Renders a radar chart with the spread of tasks by type
 * @param {object} props
 * @param {{investigation: import('../types').InvestigationTrackedTimeAggregate[], creation: import('../types').TaskSnapshotTrackedTimeAggregate[]}} props.chartData
 * @param {string} props.qaeName
 * @returns {import('react').JSX.Element}
 */
export default function TypeSpreadRadarChart({ chartData, qaeName }) {
  const { investigation, creation } = chartData;
  const data = formatChartData(investigation, creation);

  return (
    <div className="flex items-center justify-center">
      <RadarChart outerRadius={110} width={750} height={300} data={data} margin={{ top: 5 }}>
        <PolarGrid />
        <PolarAngleAxis dataKey="type" tick={(props) => renderPolarAngleAxis(props)} offset={50} />
        <PolarRadiusAxis tick={false} angle={90} domain={[0, 'dataMax']} />
        <Radar
          name="Investigation"
          dataKey="investigationValue"
          stroke={hslStringToRgbString(stringToColor(qaeName + 'investigation'))}
          fill={hslStringToRgbString(stringToColor(qaeName + 'investigation'))}
          fillOpacity={0.45}
        />
        <Radar
          name="Creation"
          dataKey="creationValue"
          stroke={hslStringToRgbString(stringToColor(qaeName + 'creation'))}
          fill={hslStringToRgbString(stringToColor(qaeName + 'creation'))}
          fillOpacity={0.45}
        />
        <Legend iconType="circle" align="right" verticalAlign="top" layout="vertical" />
      </RadarChart>
    </div>
  );
}
