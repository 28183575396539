import { Button, ButtonGroup } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../../utils/network';

export default function NotionButtons({ teamId }) {
  const { data, isFetching, isLoading, isError, error } = useQuery({
    queryKey: ['clientSummary', teamId],
    queryFn: () => sendGetRequest(`/client-summaries/${teamId}`),
    select: ({ data }) => data,
  });

  // Show load spinner on initial load
  if (isFetching && isLoading)
    return (
      <ButtonGroup>
        <Button disabled={true}>{'Fetching project plans...'}</Button>
        <Button disabled={true}>Generate Draft Project Plan - WIP 😊</Button>
      </ButtonGroup>
    );
  if (isError)
    return (
      <ButtonGroup>
        <Button disabled={true}>{error.message}</Button>
        <Button>Generate Draft Project Plan - WIP 😊</Button>
      </ButtonGroup>
    );

  const ProjectPlanSnapshots = data.ProjectPlanSnapshots;
  let mostRecentUrl = 'https://www.notion.so/qawolf/';
  if (ProjectPlanSnapshots.length) {
    mostRecentUrl += ProjectPlanSnapshots.at(-1).notionPlanDatabaseId.replace(/-/g, '');
  }

  return (
    <ButtonGroup variant="outlined" aria-label="Basic button group">
      <Button href={mostRecentUrl} target="_blank" rel="noreferrer">
        Most Recent Project Plan
      </Button>
      <Button>Generate Draft Project Plan - WIP 😊</Button>
    </ButtonGroup>
  );
}
