import _ from 'lodash';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { NavLink } from 'react-router-dom';
import LZString from 'lz-string';

import { ChevronDownIcon, ChevronDoubleDownIcon, ChevronUpIcon, ChevronDoubleUpIcon } from '@heroicons/react/24/outline';
import { Box, Button, Fade, Popper } from '@mui/material';
import { ResponsiveContainer } from 'recharts';

import WolfLoader from '../../WolfLoader/WolfLoader';
import {
  ComplexImpactAnalysisCard,
  ComplexImpactAnalysisChart,
  CreationVelocityChart,
  // CreationHeatmap, // TODO: Implement heat maps
  CustomerSpreadRadarChart,
  ImpactScoreChart,
  InvestigationVelocityChart,
  // InvestigationHeatmap, // TODO: Implement heat maps
  TypeSpreadRadarChart,
} from '../charts';

import { getDisplayName, getGeneralInvestigationMetrics, getGeneralTaskSnapshotMetrics, isTeamAggregateResponse, protectFilters } from '../helpers';
import { generateCardHash, generateCards } from '../../MetricsPlayground/helpers';

/**
 *
 * @param {object} props
 * @param {import('../types').TeamMetricsFilter} props.filters
 * @returns {import('react').JSX.Element}
 */
export default function TeamImpactView({ filters }) {
  const allUsers = /** @type {import('../types').QAEFromLocalStorage[]} */ (JSON.parse(localStorage.getItem('qaes')));
  const allTeams = /** @type {import ('../types').QATeamFromLocalStorage[]}  */ (JSON.parse(localStorage.getItem('teams')));

  // protect team filters from potential stale state
  filters = protectFilters(filters);

  const [expandedAll, setExpandedAll] = useState(false);

  // execute both queries concurrently
  const queries = useQueries({
    queries: [
      {
        queryKey: ['teamGeneralInvestigationData', filters],
        queryFn: () => getGeneralInvestigationMetrics(filters),
      },
      {
        queryKey: ['teamGeneralTaskSnapshotData', filters],
        queryFn: () => getGeneralTaskSnapshotMetrics(filters),
      },
    ],
  });

  // extract data from results
  const [
    {
      data: teamGeneralInvestigationDataRaw,
      isLoading: isTeamGeneralInvestigationLoading,
      isError: isTeamGeneralInvestigationError,
      error: teamGeneralInvestigationError,
    },
    {
      data: teamGeneralTaskSnapshotDataRaw,
      isLoading: isTeamGeneralTaskSnapshotLoading,
      isError: isTeamGeneralTaskSnapshotError,
      error: teamGeneralTaskSnapshotError,
    },
  ] = queries;

  if (isTeamGeneralInvestigationLoading || isTeamGeneralTaskSnapshotLoading) return <WolfLoader />;

  if (isTeamGeneralInvestigationError || isTeamGeneralTaskSnapshotError) {
    return (
      <div className="flex flex-col gap-y-6">
        <p className="text-red-500 text-center">Error loading data, please notify @dragons</p>
        <pre className="bg-gray-200 p-2 rounded-lg">
          <code>{JSON.stringify(teamGeneralInvestigationError, null, 2) || JSON.stringify(teamGeneralTaskSnapshotError, null, 2)}</code>
        </pre>
      </div>
    );
  }

  // we have a union type, so we have to guard here
  // This shouldn't happen, its just here to satisfy the case where something breaks and we get the wrong type back
  const bothQueriesLoaded = !isTeamGeneralInvestigationLoading && !isTeamGeneralTaskSnapshotLoading;
  const isInvalidData = !isTeamAggregateResponse(teamGeneralInvestigationDataRaw) || !isTeamAggregateResponse(teamGeneralTaskSnapshotDataRaw);
  if (bothQueriesLoaded && isInvalidData) {
    return (
      <div className="flex flex-col gap-y-6">
        <p className="text-red-500 text-center">Invalid data, please notify @dragons</p>
        <pre className="bg-gray-200 p-2 rounded-lg">
          <code>{JSON.stringify(teamGeneralInvestigationDataRaw, null, 2)}</code>
        </pre>
      </div>
    );
  }

  // define team general investigation data specifically as `TeamInvestigationAggregateResponse`
  const teamGeneralInvestigationData = /** @type {import('../types').TeamInvestigationAggregateResponse} */ (teamGeneralInvestigationDataRaw);
  const teamGeneralInvestigationDataGroupedByQAE = _.groupBy(teamGeneralInvestigationData.teamInvestigationAggregatedWithTrackedTime, 'user_id');

  // define team general task snapshot data specifically as `TeamTaskSnapshotAggregateResponse`
  const teamGeneralTaskSnapshotData = /** @type {import('../types').TeamTaskSnapshotAggregateResponse} */ (teamGeneralTaskSnapshotDataRaw);
  const teamGeneralTaskSnapshotDataGroupedByQAE = _.groupBy(teamGeneralTaskSnapshotData.teamTaskSnapshotsAggregatedWithTrackedTime, 'user_id');

  // get team members, sort by name and filter out lead
  const teamMembers = teamGeneralInvestigationData.team.members.sort((a, b) => a.name.localeCompare(b.name)).filter((m) => !m.isLead);

  return (
    <div className="min-h-screen px-4 py-2 flex-col rounded-lg shadow">
      <section className="flex space-x-4 py-4 items-center justify-start">
        <div className="flex flex-col justify-center items-center pl-4">
          <img src={teamGeneralInvestigationData.team.imageUrl} alt="avatar" className="h-14 w-14 rounded-full" />
          <span className="font-semibold">{teamGeneralInvestigationData.team.name}</span>
        </div>
        <div className="flex flex-1 justify-end items-center pr-4 gap-x-4">
          <button onClick={() => setExpandedAll(!expandedAll)}>
            {expandedAll ? (
              <ChevronDoubleUpIcon className="text-gray-600 mr-3 flex-shrink-0 h-6 w-6" />
            ) : (
              <ChevronDoubleDownIcon className="text-gray-600 mr-3 flex-shrink-0 h-6 w-6" />
            )}
          </button>
        </div>
      </section>
      <section className="flex items-center justify-stretch mb-4 shadow">
        <ResponsiveContainer width="100%" height="100%">
          <ImpactScoreChart chartData={{ investigation: teamGeneralInvestigationData, creation: teamGeneralTaskSnapshotData }} />
        </ResponsiveContainer>
      </section>
      <section className="flex flex-col gap-y-4 items-spread justify-center">
        {teamMembers.map((m, mIdx) => (
          <div key={mIdx}>
            <QAEGeneralCard
              qae={m}
              team={teamGeneralInvestigationData.team}
              allUsers={allUsers}
              allTeams={allTeams}
              dateRange={{ gte: filters.gte, lte: filters.lte }}
              qaeData={{
                investigation: teamGeneralInvestigationDataGroupedByQAE[m.qawId] || [],
                creation: teamGeneralTaskSnapshotDataGroupedByQAE[m.qawId] || [],
              }}
              expandedAll={expandedAll}
              isLoading={{
                isTeamGeneralInvestigationLoading,
                isTeamGeneralTaskSnapshotLoading,
              }}
            />
          </div>
        ))}
      </section>
    </div>
  );
}

/**
 * Allows us to dynamically render a different chart component for each iteration in the card
 * @param {{isTeamGeneralInvestigationLoading: boolean, isTeamGeneralTaskSnapshotLoading: boolean}} loadingStates
 * @returns {object}
 */
const chartMapGenerator = (loadingStates) => ({
  // charts
  investigation: {
    title: 'Investigation Per Hour',
    dataType: 'investigation',
    component: loadingStates.isTeamGeneralInvestigationLoading ? WolfLoader : InvestigationVelocityChart,
  },
  creation: { title: 'Creation Per Hour', dataType: 'creation', component: CreationVelocityChart },

  // complex impact analysis
  complexityAnalysis: {
    title: 'Complex Impact Analysis',
    dataType: 'union',
    component:
      loadingStates.isTeamGeneralInvestigationLoading || loadingStates.isTeamGeneralTaskSnapshotLoading ? WolfLoader : ComplexImpactAnalysisChart,
  },
  complexityAnalysisNumbers: {
    title: "Complex Impact Analysis Cont'd.",
    dataType: 'union',
    component:
      loadingStates.isTeamGeneralInvestigationLoading || loadingStates.isTeamGeneralTaskSnapshotLoading ? WolfLoader : ComplexImpactAnalysisCard,
  },

  // // heat maps
  // TODO: Implement heat maps
  // investigationHeat: {
  //   title: 'Investigation Heatmap',
  //   dataType: 'investigation',
  //   component: loadingStates.isTeamGeneralInvestigationLoading ? WolfLoader : InvestigationHeatmap,
  // },
  // creationHeat: { title: 'Creation Heatmap', dataType: 'creation', component: CreationHeatmap },

  // spreads
  typeSpreadRadar: {
    title: 'Task Type Spread',
    dataType: 'union',
    component: loadingStates.isTeamGeneralInvestigationLoading || loadingStates.isTeamGeneralTaskSnapshotLoading ? WolfLoader : TypeSpreadRadarChart,
  },
  customerSpreadRadar: {
    title: 'Customer Spread',
    dataType: 'union',
    component:
      loadingStates.isTeamGeneralInvestigationLoading || loadingStates.isTeamGeneralTaskSnapshotLoading ? WolfLoader : CustomerSpreadRadarChart,
  },
});

/**
 *
 * @param {object} props
 * @param {import('../types').User} props.qae
 * @param {import('../types').QATeam} props.team
 * @param {import('../types').QAEFromLocalStorage[]} props.allUsers
 * @param {import('../types').QATeamFromLocalStorage[]} props.allTeams
 * @param {{gte: string, lte: string}} props.dateRange
 * @param {{investigation: import('../types').InvestigationTrackedTimeAggregate[], creation: import('../types').TaskSnapshotTrackedTimeAggregate[]}} props.qaeData
 * @param {boolean} props.expandedAll
 * @param {{isTeamGeneralInvestigationLoading: boolean, isTeamGeneralTaskSnapshotLoading: boolean}} props.isLoading
 * @returns {import('react').JSX.Element}
 */
function QAEGeneralCard({ qae, team, allUsers, allTeams, dateRange, qaeData, expandedAll, isLoading }) {
  const { investigation, creation } = qaeData;
  const tenure = Math.ceil(dayjs().diff(dayjs(qae.startDate), 'months', true));

  const dataMap = {
    investigation: investigation,
    creation: creation,
    union: { investigation, creation },
  };

  const [expanded, setExpanded] = useState(false);
  const [urlWithIndividualCards, setUrlWithIndividualCards] = useState(/** @type {string | null} */ (null));
  const [urlWithoutIndividualCards, setUrlWithoutIndividualCards] = useState(/** @type {string | null} */ (null));
  const [showTierNavPopper, setShowTierNavPopper] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState(/** @type {HTMLElement | null} */ (null));

  /**
   *
   * @param {import('react').MouseEvent<HTMLElement>} event
   */
  const handleShowPopper = (event) => {
    setPopperAnchor(event.currentTarget);
    setShowTierNavPopper((previousShow) => !previousShow);
  };

  /**
   *
   * @param {import('../types').User} qae
   * @param {boolean} includeIndividualQAECards
   * @returns {Promise<string>} url
   */
  const buildLinkToTierInPlayground = async (qae, includeIndividualQAECards) => {
    /**
     * @typedef {string | File} FormDataEntryValue
     */

    // generate mock form data
    /** @type {{[k: string]:FormDataEntryValue}} */
    const cardData = {
      cardName: `0 to ${tenure} Months`,
      minMonths: '0',
      maxMonths: tenure.toString(),
      positions: 'QA Engineer 1, QA Engineer 2, QA Engineer 3',
      type: 'Tiers',
      includeIndividualQAEs: includeIndividualQAECards ? 'true' : null,
    };

    // generate cardSetId
    const cardSetId = await generateCardHash(cardData);

    // create new card(s)
    const newCards = generateCards(cardData, cardSetId, allUsers, allTeams);

    return `/metrics-playground?gte=${dateRange.gte}&lte=${dateRange.lte}&activeCards=${LZString.compressToEncodedURIComponent(
      JSON.stringify(newCards),
    )}`;
  };

  // set default UI element expansion
  useEffect(() => {
    setExpanded(expandedAll);
  }, [expandedAll]);

  // generate both URLs to use later in nav links
  useEffect(() => {
    const generateBothURLs = async () => {
      setUrlWithIndividualCards(await buildLinkToTierInPlayground(qae, true));
      setUrlWithoutIndividualCards(await buildLinkToTierInPlayground(qae, false));
    };

    generateBothURLs();
  }, []);

  const chartMap = chartMapGenerator(isLoading);

  return (
    <div className="overflow-hidden bg-white border shadow">
      <div className="flex justify-between items-center px-4 py-4">
        <div className="flex flex-col">
          <div className="flex gap-x-4">
            <div className="flex flex-col items-center justify-center w-28">
              <img src={qae.avatar48} alt="avatar" className="w-10 h-auto rounded-full" />
              <span className="font-semibold">{getDisplayName(qae.name)}</span>
            </div>
            <div className="flex flex-col justify-start items-start">
              <div className="font-medium">{qae.position}</div>
              <div className="text-sm">Started: {dayjs(qae.startDate).format('MMM D, YYYY')}</div>
              <div className="text-sm">Tenure: {tenure} month(s)</div>
            </div>
          </div>
          <Button onClick={(event) => handleShowPopper(event)}>View Tier in Playground</Button>
          <Popper open={showTierNavPopper} anchorEl={popperAnchor} placement="top-start" transition sx={{ zIndex: 1 }}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={{ enter: 300 }}>
                <Box>
                  <div className="p-2 bg-white border rounded-sm shadow-md">
                    <div className="flex flex-col">
                      <p className="font-medium mb-1">Include all cards?</p>
                      <span className="text-sm italic">This will add an individual card to the playground chart for each QAE in the tier.</span>
                    </div>
                    <span className="flex">
                      <Button>
                        <NavLink to={urlWithIndividualCards}>Yes, include all cards</NavLink>
                      </Button>
                      <Button>
                        <NavLink to={urlWithoutIndividualCards}>No, don't include all cards</NavLink>
                      </Button>
                      <Button onClick={() => setShowTierNavPopper(false)}>Cancel</Button>
                    </span>
                  </div>
                </Box>
              </Fade>
            )}
          </Popper>
        </div>
        <button onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <ChevronUpIcon className="text-gray-600 mr-3 flex-shrink-0 h-6 w-6" />
          ) : (
            <ChevronDownIcon className="text-gray-600 mr-3 flex-shrink-0 h-6 w-6" />
          )}
        </button>
      </div>
      {expanded && (
        <>
          <div className="grid grid-cols-2 gap-2 px-4">
            {Object.values(chartMap).map((chart, cIdx) => (
              <div key={cIdx} className="border p-2">
                <p className="font-semibold border-b">{chart.title}</p>
                <ResponsiveContainer width="100%" height="100%">
                  <chart.component chartData={dataMap[chart.dataType]} qaeName={qae.name} team={team} />
                </ResponsiveContainer>
              </div>
            ))}
          </div>
          <div className="px-4 py-4 text-center">
            <QAEGeneralCardAnalytics qae={qae} qaeData={qaeData} />
          </div>
        </>
      )}
    </div>
  );
}

/**
 *
 * @param {object} props
 * @param {import('../types').User} props.qae
 * @param {{investigation: import('../types').InvestigationTrackedTimeAggregate[], creation: import('../types').TaskSnapshotTrackedTimeAggregate[]}} props.qaeData
 * @returns {import('react').JSX.Element}
 */
// eslint-disable-next-line no-unused-vars
function QAEGeneralCardAnalytics({ qae, qaeData }) {
  const { investigation, creation } = qaeData;
  /**
   * Analyzes QAE data into hours tracked, investigation numbers, creation numbers
   * @param {import('../types').InvestigationTrackedTimeAggregate[]} investigationData
   * @param {import('../types').TaskSnapshotTrackedTimeAggregate[]} creationData
   * @returns {object} analysis
   */
  const analyzeQAEData = (investigationData, creationData) => {
    // compute actual investigation time based on tasks
    const actualHoursSpentOnInvestigation = 0;

    // get number of fixes & flakes
    const totalFailures = _.sumBy(investigationData, 'total_failures_investigated_this_week');
    const totalFixes = _.sumBy(investigationData, (d) => d.suites.reduce((acc, suite) => acc + suite.number_of_runs_passing_on_qae_fix, 0));
    const totalFlakes = _.sumBy(investigationData, (d) => d.suites.reduce((acc, suite) => acc + suite.number_of_runs_passing_on_qae_flake, 0));

    const analysis = {
      // do hours
      hoursTracked: {
        creation: { selfReported: _.sumBy(creationData, 'hours_tracked') },
        investigation: { selfReported: _.sumBy(investigationData, 'hours_tracked'), actual: actualHoursSpentOnInvestigation },
      },

      // do investigation rates (% of total failures for each)
      investigationRates: {
        fixRate: Math.round((totalFixes / totalFailures) * 100) / 100,
        flakeRate: Math.round((totalFlakes / totalFailures) * 100) / 100,
      },
    };

    return analysis;
  };

  // eslint-disable-next-line no-unused-vars
  const analyzedQAEData = analyzeQAEData(investigation, creation);
  return <div>{/* Numbers data */}</div>; // TODO: Implement general numbers data representation
}
