import Plot from 'react-plotly.js';
import { formatCamelCaseToTitle } from './ChartHelpers';

const calculateQuartiles = (data) => {
    const sortedData = [...data].sort((a, b) => a - b);
    const lowerQuartile = sortedData[Math.floor(sortedData.length * 0.33)];
    const upperQuartile = sortedData[Math.floor(sortedData.length * 0.66)];
    const min = sortedData[0];
    const max = sortedData[sortedData.length - 1];
    const median = sortedData[Math.floor(sortedData.length * 0.5)];
    return { min, lowerQuartile, upperQuartile, max, median };
};

const PercentageBoxPlot = ({ suiteData, metrics, title }) => {
    let colors = ['#38761d', '#6A5ACD', '#4682B4', '#FFD700', '#FF5733', '#FF69B4', '#20B2AA', '#FFA500'];

    const plotData = metrics.map((metric, index) => {
        let values = suiteData.map((suite) => suite[metric]);

        // For those suites where changeRate was not added into the database
        if (metric === 'changeRate') {
            values = suiteData.map((suite) => {
                // Check if the metric exists and is not null.
                if (suite[metric] !== undefined && suite[metric] !== null) {
                    return suite[metric];
                } else {
                    const changeRate = (
                        (suite.numberOfRunsTriagedAsBug +
                         suite.numberOfRunsTriagedAsMaintenance +
                         suite.numberOfRunsPassingOnQaeFix) /
                        suite.numberOfRuns) * 100;
        
                    return parseFloat(changeRate.toFixed(2));
                }
            });
        }

        // For breakdown of changeRate
        if (metric === 'numberOfRunsTriagedAsBug' ||
            metric === 'numberOfRunsTriagedAsMaintenance' ||
            metric === 'numberOfRunsPassingOnQaeFix') {
            values = values.map((value) => ((value / suiteData[index].numberOfRuns) * 100).toFixed(2));
            colors = ['#ff6666', '#FF69B4', '#20B2AA', '#6A5ACD', '#4682B4', '#FF5733', '#FFD700', '#FFA500'];
        }
        const { min, lowerQuartile, upperQuartile, max, median } = calculateQuartiles(values);

        return {
            y: values,
            type: 'box',
            name: formatCamelCaseToTitle(metric),
            boxpoints: false,
            jitter: 0.5,
            whiskerwidth: 0.2,
            fillcolor: colors[index % colors.length],
            marker: {
                size: 2,
            },
            line: {
                width: 1,
            },
            boxmean: true,
            q1: lowerQuartile,
            median,
            q3: upperQuartile,
            lowerfence: min,
            upperfence: max,
        };
    });

    return (
        <Plot
            data={plotData}
            layout={{
                title: title,
                yaxis: { title: 'Percent' },
                xaxis: { automargin: true },
                boxmode: 'group',
            }}
            useResizeHandler={true} // Ensures the plot resizes on container resize
            style={{ width: "100%", height: "100%" }} // Makes the plot responsive within its container
        />
    );
};

export default PercentageBoxPlot;
