// Utility function to convert a string to a pastel color in HSL
export const stringToColor = (string, minLightness = 50, maxLightness = 70) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  const hue = hash % 360;
  const lightnessRange = maxLightness - minLightness;
  const lightness = minLightness + (hash % lightnessRange);
  const saturation = (hash % 20) + 80;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

// Utility function to convert HSL to RGB
const hsl2rgb = (h, s, l) => {
  const a = s * Math.min(l, 1 - l);
  const f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  return [f(0), f(8), f(4)];
};

// Convert HSL string to RGB string
export const hslStringToRgbString = (hslString, opacity = 1) => {
  const [h, s, l] = hslString.match(/\d+/g).map(Number);
  const [r, g, b] = hsl2rgb(h, s / 100, l / 100).map((v) => Math.round(v * 255));
  return `rgb(${r}, ${g}, ${b}, ${opacity})`;
};
