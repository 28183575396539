import { sendGetRequest, sendPostRequest } from '../../utils/network';

export async function getOpenTasks() {
  let response = await sendGetRequest(`/open-tasks`);

  return response.data;
}

export async function claimTask({ request }) {
  let data = Object.fromEntries(await request.formData());

  let resultData = await sendPostRequest(`/claim`, data);

  let tasks = resultData?.data?.tasks;

  if (tasks) {
    return tasks;
  }

  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}

export async function subscribe({ request }) {
  let data = Object.fromEntries(await request.formData());
  console.log(request, data);

  let resultData = await sendPostRequest(`/subscribe`, data);

  let tasks = resultData?.data?.tasks;

  if (tasks) {
    return tasks;
  }

  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}

export async function unclaimTask({ request }) {
  let data = Object.fromEntries(await request.formData());

  let resultData = await sendPostRequest(`/unclaim`, data);

  let tasks = resultData?.data?.tasks;

  if (tasks) {
    return tasks;
  }

  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}

export async function checkTask({ request }) {
  let data = Object.fromEntries(await request.formData());

  let resultData = await sendPostRequest(`/check`, data);

  let tasks = resultData?.data?.tasks;

  if (tasks) {
    return tasks;
  }

  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}

export async function ignoreTask({ request }) {
  let data = Object.fromEntries(await request.formData());

  let resultData = await sendPostRequest(`/ignore`, data);

  let tasks = resultData?.data?.tasks;

  if (tasks) {
    return tasks;
  }

  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}

export async function urgentTask({ request }) {
  let data = Object.fromEntries(await request.formData());

  let resultData = await sendPostRequest(`/urgent`, data);

  let tasks = resultData?.data?.tasks;

  if (tasks) {
    return tasks;
  }

  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}

export async function doneTask({ request }) {
  let data = Object.fromEntries(await request.formData());

  let resultData = await sendPostRequest(`/done`, data);

  let tasks = resultData?.data?.tasks;

  if (tasks) {
    return tasks;
  }

  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}
