import axios from 'axios';

// eslint-disable-next-line no-undef
let baseUrl = `${process.env.REACT_APP_URL}/apis/task-wolf`;

export async function sendPostRequest(url, jsonData) {
  try {
    const targetUrl = `${baseUrl}${url}`;
    let stringifiedData = JSON.stringify(jsonData);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: targetUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      data: stringifiedData,
    };

    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    let result = await axios.request(config);

    return {
      success: true,
      data: result.data,
      jsonData: jsonData, // For identifying tasks to re-enable if needed
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err,
      jsonData: jsonData, // For identifying tasks to re-enable if needed
    };
  }
}

export async function sendGetRequest(url) {
  try {
    const targetUrl = `${baseUrl}${url}`;
    let stringiedData = JSON.stringify({});

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: targetUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      data: stringiedData,
    };

    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    let result = await axios.request(config);

    return {
      success: true,
      data: result.data,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err,
    };
  }
}

export async function sendPutRequest(url, jsonData) {
  try {
    const targetUrl = `${baseUrl}${url}`;
    let stringifiedData = JSON.stringify(jsonData);

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: targetUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      data: stringifiedData,
    };

    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    let result = await axios.request(config);

    return {
      success: true,
      data: result.data,
      jsonData: jsonData, // For identifying tasks to re-enable if needed
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err,
      jsonData: jsonData, // For identifying tasks to re-enable if needed
    };
  }
}
