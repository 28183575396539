import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

/**
 * Polls a queryClient key for its status.
 * @param {*[]} queryKey
 * @param {number} [interval=1000]
 * @returns {'idle' | 'pending' | 'success' | 'error'} status
 */
export default function usePollQueryStatus(queryKey, interval = 1000) {
  const queryClient = useQueryClient();
  const [status, setStatus] = useState(/** @type {'idle' | 'pending' | 'success' | 'error'} */ ('idle'));

  useEffect(() => {
    const poll = setInterval(() => {
      const queryState = queryClient.getQueryState(queryKey);
      if (queryState) {
        setStatus(queryState.status);
      } else {
        setStatus('idle');
      }
    }, interval);

    return () => clearInterval(poll);
  }, [queryClient, queryKey, interval]);

  return status;
}
