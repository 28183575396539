import { useEffect, useState } from 'react';
import { getTeamInfo } from '../../utils/helpers';
import { sendGetRequest } from '../../utils/network';
import { Link } from 'react-router-dom';
import { classNames } from '../InvestigationBoard/helpers';



export default function StreamExplorerNav() {
  let teamsData = getTeamInfo();
  const [allClients, setAllClients] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(teamsData.find((team) => team.selectedInSettings));
  const [selectedTeamClients, setSelectedTeamClients] = useState([]);

  if (!selectedTeam) {
    handleSwitchTeams(teamsData[0]);
  }

  // Get list of team's clients on page load
  useEffect(() => {
    (async () => {
      // get all clients
      const rawClients = await sendGetRequest('/clients').catch((e) => {
        throw new Error(e);
      });
      const flattenedClients = Object.entries(rawClients.data).map(([key, value]) => ({ ...value, key }));
      setAllClients(flattenedClients);
    })();
  }, []);

  // Get list of team's clients on team change
  useEffect(() => {
    const teamClients = allClients.filter((client) => client.qaLead.email === selectedTeam.email);
    setSelectedTeamClients(teamClients);
  }, [selectedTeam, allClients]);

  // switch teams
  function handleSwitchTeams(clickedTeam) {
    const newTeams = teamsData.map((team) => {
      if (team.name === clickedTeam.name) {
        return { ...team, selectedInSettings: true };
      } else {
        return { ...team, selectedInSettings: false };
      }
    });

    if (newTeams) localStorage.setItem('teams', JSON.stringify(newTeams));
    setSelectedTeam(clickedTeam);
  }

  return (
    <>
      <div className="pb-5 ml-4 mr-4 sm:pb-0">
        <h1 className="text-base font-semibold leading-6 text-gray-900">Stream Explorer</h1>
        {/* Team Nav */}
        <div className="mt-3 sm:mt-4">
          <nav className="mb-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {teamsData.map((team) => (
              <a
                style={{ cursor: 'pointer' }}
                key={team.qawId}
                id={team.teamName}
                onClick={() => {
                  handleSwitchTeams(team);
                }}
                className={classNames(
                  team.selectedInSettings
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                )}
                aria-current={team.selectedInSettings ? 'page' : undefined}
              >
                {team.teamName}
              </a>
            ))}
          </nav>
        </div>
      </div>

      {/* Clients table */}
      <div className={`px-4 mt-10`}>
        <h2 className="ml-2 text-base font-semibold leading-6 text-gray-900">Team Clients</h2>
        <div className="relative">
          <table className="min-w-full table-fixed divide-y divide-gray-300 border-t border-gray-300">
            <tbody className="divide-y divide-gray-200 bg-white">
              {selectedTeamClients?.map((client) => (
                <tr key={client.id}>
                  {/* Name column */}
                  <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium">
                    <Link to={`/stream-explorer/${client.id}`} className="text-blue-600 hover:text-blue-800 visited:text-purple-600">
                      {client.name}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
