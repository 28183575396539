function googleAuth() {
  const userId = localStorage.getItem('userId');
  const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

  const options = {
    redirect_uri: window.location.origin + '/google/code',
    client_id: '63052513261-bvo8qtdlab6thar4vkjnutijtuduou55.apps.googleusercontent.com',
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    scope: [
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/calendar',
      'https://www.googleapis.com/auth/admin.directory.resource.calendar',
    ].join(' '),
    state: userId || '',
  };

  const qs = new URLSearchParams(options);
  window.location.replace(`${rootUrl}?${qs.toString()}`);
}

export default function GoogleConnector() {
  return (
    <button
      type="button"
      className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
      onClick={() => {
        googleAuth();
      }}
    >
      Connect to Google
    </button>
  );
}
