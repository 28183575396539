import { useEffect, useRef, useState } from 'react';

import { Bar, BarChart, CartesianGrid, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';
import { roundToTwo } from '../../MetricsPlayground/helpers';

/**
 * Formats qae investigation and creation data into positive and negative for impact score complexity analysis
 * @param {import('../types').InvestigationTrackedTimeAggregate[]} investigationData
 * @param {import('../types').TaskSnapshotTrackedTimeAggregate[]} creationData
 * @returns {{week: string, "Bug Failures": number, "Fixed Failures": number, "Maintenance Failures": number, "Retried Failures": number}[]}
 */
// eslint-disable-next-line no-unused-vars
const formatChartData = (investigationData, creationData) => {
  const dataArray = [];

  for (const weekData of investigationData) {
    const investigatedSuites = weekData.suites;

    const weekObj = investigatedSuites.reduce(
      (acc, suite) => {
        // handle positives
        acc['Fixed Failures'] += suite.number_of_runs_passing_on_qae_fix;
        acc['Bug Failures'] += suite.number_of_runs_triaged_as_bug;

        // handle negatives
        acc['Maintenance Failures'] -= suite.number_of_runs_triaged_as_maintenance * 2;
        acc['Retried Failures'] -= suite.number_of_runs_passing_on_qae_flake / 10;

        return acc;
      },
      {
        week: weekData.week,
        'Bug Failures': 0,
        'Fixed Failures': 0,
        'Maintenance Failures': 0,
        'Retried Failures': 0,
      },
    );

    // round all numbers to 2 decimal places
    Object.keys(weekObj).forEach((key) => {
      weekObj[key] = roundToTwo(weekObj[key]);
    });

    dataArray.push(weekObj);
  }

  return dataArray;
};

/**
 *
 * @param {object} props
 * @param {{investigation: import('../types').InvestigationTrackedTimeAggregate[], creation: import('../types').TaskSnapshotTrackedTimeAggregate[]}} props.chartData
 * @returns {import('react').JSX.Element}
 */
export default function ComplexImpactAnalysisChart({ chartData }) {
  const parentRef = useRef(null);
  const [width, setWidth] = useState(775);

  // handle chart resizing
  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setWidth(parentRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, []);

  const qaeData = formatChartData(chartData.investigation, chartData.creation);

  return (
    <div className="flex items-center justify-center" ref={parentRef}>
      <BarChart
        width={width}
        height={400}
        data={qaeData}
        margin={{
          top: 20,
          right: 10,
          left: -10,
          bottom: 5,
        }}
        barCategoryGap={20}
        stackOffset="sign"
      >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="week" />
        <YAxis domain={[0, 'dataMax']} />
        <Tooltip content={<CustomTooltip />} />
        <Bar stackId="a" dataKey="Bug Failures" fill="#4CAF50" opacity={0.65} />
        <Bar stackId="a" dataKey="Fixed Failures" fill="#388E3C" opacity={0.65} />
        <Bar stackId="a" dataKey="Maintenance Failures" fill="#F44336" opacity={0.65} />
        <Bar stackId="a" dataKey="Retried Failures" fill="#D32F2F" opacity={0.65} />
        <ReferenceLine y={0} stroke="red" strokeDasharray="7 7" label="Baseline" />
      </BarChart>
    </div>
  );
}
