import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import { formatCamelCaseToTitle } from './ChartHelpers';


const SuiteRunTimeTrendPlot = ({ suiteData, minuteMetrics, countMetrics, title }) => {
    const minuteColors = ['#70CF11', '#FFD700', '#4682B4', '#FF69B4', '#6A5ACD', '#FF5733', '#20B2AA', '#FFA500'];
    const countColors = ['#DC143C', '#DDCCFF', '#008080', '#741b47'];

    // Sort suiteData by startTime using dayjs
    const sortedSuiteData = suiteData.sort((a, b) => dayjs(a.startTime).valueOf() - dayjs(b.startTime).valueOf());

    // Calculate the number of unique days
    const uniqueDays = new Set(
        sortedSuiteData.map((suite) => dayjs(suite.startTime).format('YYYY-MM-DD')),
    ).size;

    // Generate plot data for metrics in minutes
    const minutePlotData = minuteMetrics.map((metric, index) => {
        const x = sortedSuiteData.map((suite) => dayjs(suite.startTime).toISOString());
        const y = sortedSuiteData.map((suite) => suite[metric]).filter((value) => value > 0);

        return {
            x,
            y,
            type: 'scatter',
            mode: 'lines+markers',
            line: {
                shape: 'spline',
                smoothing: 1, // Adjust for desired smoothness
                color: minuteColors[index % minuteColors.length],
            },
            marker: { color: minuteColors[index % minuteColors.length] },
            name: formatCamelCaseToTitle(metric),
            yaxis: 'y1',
        };
    });

    // Generate plot data for count metrics
    const countPlotData = countMetrics.map((metric, index) => {
        const x = sortedSuiteData.map((suite) => dayjs(suite.startTime).toISOString());
        const y = sortedSuiteData.map((suite) => suite[metric]);

        return {
            x,
            y,
            type: 'scatter',
            mode: 'lines+markers',
            line: {
                shape: 'spline',
                smoothing: 1, // Adjust for desired smoothness
                color: countColors[index % countColors.length],
            },
            marker: { color: countColors[index % countColors.length] },
            name: formatCamelCaseToTitle(metric),
            yaxis: 'y2',
            visible: 'legendonly',
        };
    });

    return (
        <Plot
            data={[...minutePlotData, ...countPlotData]}
            layout={{
                title: title,
                xaxis: {
                    title: 'Date',
                    type: 'date',
                    automargin: true,
                    tickformat: '%d-%m-%Y',
                    nticks: uniqueDays,
                },
                yaxis: {
                    title: 'Minutes',
                    automargin: true,
                    rangemode: 'tozero',
                },
                yaxis2: {
                    title: 'Workflow Count',
                    automargin: true,
                    rangemode: 'tozero',
                    overlaying: 'y',
                    side: 'right',
                },
                hovermode: 'closest',
                legend: {
                    orientation: 'h',
                    y: -0.5,
                    x: 0.5,
                    xanchor: 'center',
                },
            }}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
        />
    );
};

export default SuiteRunTimeTrendPlot;
