export default function CustomizedLabel(props) {
  const { x, y, value } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text className="text-xs" x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
        {value}
      </text>
    </g>
  );
}
