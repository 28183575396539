import { useState } from 'react';

/**
 * Custom hook to manage disabled tasks.
 * @returns {object} An object containing disabledTasks and setDisabledTasks functions.
 */
export const useDisabledTasks = () => {
  const [disabledTasks, setDisabledTasks] = useState({});

  return { disabledTasks, setDisabledTasks };
};
