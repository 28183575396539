/**
 * Retrieves team information from local storage.
 * @returns {Array} The team information retrieved from the local storage.
 * @throws {Error} If the team information is not found.
 */
export function getTeamInfo() {
  let teamLeadsFromCache;

  const teamsString = localStorage.getItem('teams');
  teamLeadsFromCache = teamsString ? JSON.parse(teamsString) : [];

  return teamLeadsFromCache;
}
