import facepalmFetch from '../../../utils/facepalm-fetch.gif';

export default function FetchPendingIndicator({ customStyles = {} }) {
  return (
    <div className="facepalm-fetch-container flex items-center gap-x-4" style={customStyles}>
      <img src={facepalmFetch} alt="Fetching" className="facepalm-fetch max-h-full rounded-full border" />
      <p className="text-gray-500  max-h-full">
        Please be patient, we're <span className="font-medium italic text-black">fetching</span> a lot of data...
      </p>
    </div>
  );
}
