import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';

export default function ExportCustomerArrButton({ customerData }) {
  const headers = ["Customer", "ARR"];

  // Helper function to escape double quotes and wrap in double quotes
  const escapeForCsv = (value) => {
    if (value === null || value === undefined) return '';
    const escapedValue = value.toString().replace(/"/g, '""');
    return `"${escapedValue}"`;
  };

  const downloadCsv = () => {
    let csvContent = headers.join(",") + "\n";
    customerData.forEach((customer) => {
      if (customer.name === null || customer.arr === null) return;
      const arr = customer.arr.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      const row = [
        escapeForCsv(customer.name),
        escapeForCsv(arr),
      ];
      csvContent += row.join(",") + "\n";
    });

    // Trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `Customer_ARR_Data.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="absolute top-0 right-0 pt-1 w-10">
        <Button
          startIcon={<FileDownload />}
          onClick={downloadCsv}
        >
        </Button>
    </div>
  );
}
