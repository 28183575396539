export default function ImportantDatesWidget({ team }) {
  return (
    <div className="col-span-2 p-2 mr-2 w-full">
      <h3 className="text-center font-medium pb-2">Important Dates</h3>
      <div className="rounded-xl border border-gray-200 p-4">
        <div className="flex justify-between pb-2">
          <div className="text-gray-500">Started At</div>
          <div className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">{team.startedAt}</div>
          </div>
        </div>
        <div className="flex justify-between pb-2 ">
          <div className="text-gray-500">Pilot End</div>
          <div className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">{team.pilotEnd}</div>
          </div>
        </div>
        <div className="flex justify-between pb-2 ">
          <div className="text-gray-500">Renews At</div>
          <div className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">{team.renewsAt}</div>
          </div>
        </div>
        <div className="flex justify-between gap-x-4">
          <div className="text-gray-500">Completeness</div>
          <div className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">{team.completeness.toFixed(0)}%</div>
          </div>
        </div>
      </div>
    </div>
  );
}
