import { Navigate, useLocation } from 'react-router-dom';

export default function ProtectedRoute(props) {
  let children = props.children;

  const token = localStorage.getItem('token');
  const location = useLocation();

  if (!token) {
    localStorage.setItem('prevLocation', location.pathname);
    return <Navigate to="/login" replace />;
  }

  return children;
}
