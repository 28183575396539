import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import BubbleChartLegend from './BubbleChartInvestigationLegend';

const renderTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: '#f0f0f0',
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          zIndex: 1000, // Ensure tooltip is on top of other elements
        }}
      >
        <p style={{ margin: 0, fontWeight: 'bold' }}>{`${data.day} ${data.date} ${data.hour}`}</p>
        <p style={{ margin: 0 }}>
          <span style={{ fontWeight: 'bold' }}>Value: </span>
          {data.value}
        </p>
        {Object.values(data.details).map((detail, index) => (
          <p key={index} style={{ margin: 0 }}>
            <span style={{ fontWeight: 'bold' }}>{detail.contribution}:</span> {`${detail.customerName} - ${detail.triggerName}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const BubbleChart = ({ data }) => {
  const dates = [...new Set(data.map((d) => d.date))].reverse();
  const domain = [0, Math.max(...data.map((d) => d.value))];
  const range = [0, 3000];

  return (
    <div>
      <BubbleChartLegend />
      {dates.map((date) => {
        const dayEntry = data.find((d) => d.date === date);
        const dayData = data.filter((d) => d.date === date).map((d) => ({ ...d, y: 0.5, bubbleSize: d.value === 0 ? 0 : d.value * 1.1 + 20 }));
        return (
          <ResponsiveContainer width="90%" height={135} key={date}>
            <ScatterChart margin={{ top: 10, right: 10, bottom: 10, left: 70 }}>
              <XAxis type="category" dataKey="hour" name="hour" interval={0} />
              <ZAxis type="number" dataKey="bubbleSize" range={range} domain={domain} />
              <Tooltip cursor={{ strokeDasharray: '3 3' }} content={renderTooltip} wrapperStyle={{ zIndex: 1000 }} />
              <Scatter data={dayData} name="A school">
                {dayData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color ?? '#8884d8'} />
                ))}
              </Scatter>
              <YAxis
                type="number"
                dataKey="y"
                domain={[0, 1]}
                tickLine={false}
                axisLine={false}
                tick={false}
                label={{
                  value: `${dayEntry.day} ${dayEntry.date.split('-')[1]}/${dayEntry.date.split('-')[2]}`,
                  position: 'insideRight',
                  textAnchor: 'middle',
                  fontSize: 12,
                  fontWeight: 'bold',
                  angle: 0,
                  dy: -20,
                }}
              />
            </ScatterChart>
          </ResponsiveContainer>
        );
      })}
    </div>
  );
};

export default BubbleChart;
