import dayjs from 'dayjs';
import LZString from 'lz-string';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Header from '../Layout/Header';
import { MetricsPlaygroundFilters } from './filters';
import { ImpactPlaygroundChart } from './charts';
import { ResponsiveContainer } from 'recharts';

/**
 * Primary metrics playground view
 * @returns {import('react').JSX.Element}
 */
export default function MetricsPlayground() {
  // get initial values from local storage
  const allUsers = /** @type {import('../MetricsExplorer/types').QAEFromLocalStorage[]} */ (JSON.parse(localStorage.getItem('qaes')));
  const allTeams = /** @type {import ('../MetricsExplorer/types').QATeamFromLocalStorage[]}  */ (JSON.parse(localStorage.getItem('teams')));

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries(searchParams.entries());

  // handle default dates from search params or subtract 1 month
  const [dates, setDates] = useState(
    /** @type {{gte: string, lte: string}} */ ({
      gte: paramsMap.gte || dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      lte: paramsMap.lte || dayjs().format('YYYY-MM-DD'),
    }),
  );

  // track state for active cards
  const [activeCards, setActiveCards] = useState(
    /** @type {import('./types').ActiveCard[]} */ (
      paramsMap.activeCards ? JSON.parse(LZString.decompressFromEncodedURIComponent(paramsMap.activeCards)) : []
    ),
  );

  // handle updating search params
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('gte', dates.gte);
    newSearchParams.set('lte', dates.lte);
    if (activeCards.length > 0) {
      newSearchParams.set('activeCards', LZString.compressToEncodedURIComponent(JSON.stringify(activeCards)));
    }
    setSearchParams(newSearchParams);
  }, [dates, activeCards]);

  return (
    <div className="min-h-full py-4">
      <Header text="Metrics Playground" />
      <MetricsPlaygroundFilters
        allUsers={allUsers}
        allTeams={allTeams}
        dates={dates}
        setDates={setDates}
        activeCards={activeCards}
        setActiveCards={setActiveCards}
      />
      <ResponsiveContainer width="100%" height="100%">
        <ImpactPlaygroundChart allUsers={allUsers} allTeams={allTeams} dates={dates} activeCards={activeCards} />
      </ResponsiveContainer>
    </div>
  );
}
