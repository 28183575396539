import { Box, Button, IconButton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CloseRounded, NavigateNextRounded } from '@mui/icons-material';
import { sendGetRequest } from '../../../../utils/network';
import { Content, Header, Inner, StyledDrawer, Title } from './subComponents';
import { MsDrawer } from './MsDrawer';
import { CreationDrawer } from './CreationDrawer';
import { useState } from 'react';
import WolfLoader from '../../../WolfLoader/WolfLoader';
import { useSearchParams } from 'react-router-dom';

/**
 * @typedef {import('../types').Milestone} Milestone
 */

export default function MainDrawer({ client }) {
  // Id for direct link to client milestones
  const [searchParams] = useSearchParams();
  const milestoneClientId = searchParams.get('milestoneClientId');
  const [isOpen, setIsOpen] = useState(client.id === milestoneClientId);

  const toggleSelf = () => {
    setIsOpen((prev) => !prev);
  };

  const closeSelf = () => {
    setIsOpen(false);
  };

  /** @type {import('@tanstack/react-query').UseQueryResult<Milestone[]>} */
  const mStones = useQuery({
    queryKey: ['clientSummary', client.id],
    queryFn: () => sendGetRequest(`/client-summaries/${client.id}`),
    select: ({ data }) => data.milestones,
    enabled: isOpen,
    placeholderData: { data: { milestones: [] }, success: true },
  });

  let activeMilestoneName = client.activeMilestone ? client.activeMilestone.name : 'N/A';
  if (activeMilestoneName.length > 14) activeMilestoneName = activeMilestoneName.slice(0, 14) + '...';

  return (
    <Box sx={{ width: '100%', display: 'flex' }}>
      <Button onClick={toggleSelf} variant="contained" endIcon={<NavigateNextRounded />} sx={{ width: '100%', maxWidth: '10rem' }}>
        {activeMilestoneName}
      </Button>
      <StyledDrawer anchor="right" open={isOpen} onClose={closeSelf} variant="temporary">
        <Inner>
          <Header>
            <IconButton onClick={closeSelf} disableRipple>
              <CloseRounded />
            </IconButton>
            <Title text={`${client.name} Milestones`} />
          </Header>
          <Content>
            {mStones.isError && <Typography variant="caption">{mStones.error.message}</Typography>}
            {mStones.isPlaceholderData && <WolfLoader customText="Fetching milestones..." customStyles={{ height: '100%' }} />}
            <Box sx={{ mt: '1em' }}>
              {mStones.data.map((milestone) => {
                return <MsDrawer milestone={milestone} closeParents={closeSelf} key={milestone.id} client={client} />;
              })}
              {!mStones.isPlaceholderData && <CreationDrawer client={client} milestones={mStones.data} closeParents={closeSelf} />}
            </Box>
          </Content>
        </Inner>
      </StyledDrawer>
    </Box>
  );
}
