import { Box, Typography, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { getSubmilestoneStyles } from './utils';

export default function SubMilestone({ subMilestone, requiredVelocity, thisWeeksTestCount }) {
  const { rowBgColor, testsBgColor, weekFontWt, weekFontClr } = getSubmilestoneStyles(subMilestone);
  const weekStr = dayjs(subMilestone.dueDate).startOf('week').format('M/D');
  const isCurrWeek = dayjs(subMilestone.dueDate).isSame(dayjs(), 'week');
  const isFutureWeek = dayjs(subMilestone.dueDate).isAfter(dayjs(), 'week');
  const hasMetGoal = requiredVelocity <= 0;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', p: '.5em', bgcolor: rowBgColor, borderRadius: '5px' }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', m: '.2em' }}>
        <Typography lineHeight={1.1} fontWeight={weekFontWt} color={weekFontClr}>
          {`Week ${subMilestone.weekNumber}`}
          {isCurrWeek && ' (This Week)'}
        </Typography>
        <Typography variant="caption" lineHeight={1} color={weekFontClr}>
          {weekStr}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
        <Paper
          sx={{
            bgcolor: testsBgColor,
            height: '100%',
            width: '10em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
          }}
        >
          <Typography color="white" fontSize={14} fontWeight="bold">
            {!isCurrWeek && !isFutureWeek
              ? `${subMilestone.actualTests} / ${subMilestone.targetTests}`
              : hasMetGoal
              ? `0 Tests`
              : isCurrWeek
              ? `${thisWeeksTestCount} / ${requiredVelocity} Created`
              : `Target: ${requiredVelocity} Tests`}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
}
